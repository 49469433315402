import { MinusOutlined } from "@ant-design/icons"
import { Checkbox, List, Row, Skeleton } from "antd"
import React, { memo } from "react"
import AppButton from "../Button"
import AppModal from "../Modal"
import send from "../../Assets/icons/sendMessage.svg"

export const DocSelection = memo(
  ({ open, onDone, onDiscard, documents, handleChecked, localLoading }) => {
    return (
      <AppModal open={open} minWidth="30%" title=" " contentClass="">
        <Checkbox.Group style={{ width: "100%" }} onChange={handleChecked}>
          <List
            itemLayout="horizontal"
            size="large"
            header={<h1>Select Attachments</h1>}
            footer={
              <Row justify="space-between">
                <AppButton
                  icon={<MinusOutlined style={{ fontSize: 18 }} />}
                  title="Discard"
                  width="120px"
                  height="max-content"
                  fontSize="16px"
                  className="btn_red"
                  onClick={!localLoading && onDiscard}
                />
                <AppButton
                  icon={
                    <img src={send} alt="img" className="mr-5" width="16px" />
                  }
                  title={localLoading ? "Sending" : "Send"}
                  width="120px"
                  height="max-content"
                  fontSize="16px"
                  className="btn_green mr-10"
                  onClick={!localLoading && onDone}
                />
              </Row>
            }
            dataSource={documents}
            renderItem={(item) => {
              return (
                <Skeleton
                  style={{ display: "flex" }}
                  avatar
                  title={false}
                  loading={item.loading}
                  active
                >
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Checkbox value={item.ID} />}
                      title={item.Link}
                    />
                  </List.Item>
                </Skeleton>
              )
            }}
          />
        </Checkbox.Group>
      </AppModal>
    )
  }
)
