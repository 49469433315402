import { Col, Form, Row, Space, Tag } from "antd"
import TextArea from "antd/lib/input/TextArea"
import clx from "classnames"
import { isEqual } from "lodash"
import React, { memo, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Print from "../../Assets/icons/Print.svg"
import send from "../../Assets/icons/sendMessage.svg"
import AppButton from "../../Components/Button"
import { SearchDate } from "../../Components/Filters/SeachDate"
import AppInput from "../../Components/Input"
import { DataTable } from "../../Components/Table"
import {
  asyncCreateDocument,
  asyncCreateInvoice,
  asyncCreateOrderLineItem,
  asyncDeleteDocument,
  asyncDeleteLineItem,
  asyncSendInvoice,
  asyncUpdateOrderLineItem,
} from "../../store/actions/account"
import { addAdditionalPropsToCell, moment } from "../../utils"
import { DocSelection } from "../DocSelection"
import { FileUploader } from "../Dragger"
import { FileViewer } from "../FileViewer"
import InvoiceModal from "../Modal/Invoice"
import { Actions, TableType } from "../RowActions"
import styles from "./style.module.scss"

function Invoice({ record, loader, localLoading }) {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const [itemForm] = Form.useForm()
  const [documentForm] = Form.useForm()

  const subTotalRef = useRef(null)
  const totalRef = useRef(null)

  const moveTypes = useSelector((s) => s.request.moveTypes, isEqual)
  const documentTypes = useSelector((s) => s.account.documentTypes, isEqual)

  const [open, setOpen] = useState(false)
  const [viewerSource, setViewerSource] = useState({ ID: null, link: "" })

  const [showFileUploader, setShowFileUploader] = useState({
    show: false,
    file: {},
  })

  const [editingKey, setEditingKey] = useState("")
  const [itemEditingKey, setItemEditingKey] = useState("")

  const [selectedDocIDs, setSelectedDocIDs] = useState([])

  const [selectedAttachmentIDs, setSelectedAttachmentIDs] = useState([])
  const [showAttachment, setShowAttachment] = useState()

  let invoiceDocuments = useMemo(
    () =>
      record?.documents?.reduce((acc, curr, ind) => {
        // 2 means invoice Tab
        if (curr.docCategory === 2) {
          return [
            ...acc,
            { ...curr, link: { uid: curr.Link + ind, name: curr.Link } },
          ]
        }

        return acc
      }, []),
    [record?.documents]
  )

  useEffect(() => {
    if (record?.ID) {
      form.setFieldsValue({
        CustomerID: record?.BillTo,
        Customer_Name: record?.customer?.name,
        Customer_Address: record?.customer?.Customer_Address,
        Billing_Email: record?.customer?.Billing_Email,

        InvoiceDate: record?.invoice?.InvoiceDate
          ? moment(record?.invoice?.InvoiceDate)
          : moment(),
        InvoiceDueDate: record?.invoice?.InvoiceDueDate
          ? moment(record?.invoice?.InvoiceDueDate)
          : moment().add(30, "d"),

        YardMoveOrderID: record?.ID,
        YardTractorMoveType: record?.YardMoveType?.Service,
        Trailer: `#${record?.trailer?.UnitNo}`,
        AssignedOrderRef: record?.AssignedOrderRef,
        Door: record?.Door,
      })
    }
  }, [form, record])

  useEffect(() => {
    itemForm.setFieldsValue({
      ...(record?.yardLineItems?.length
        ? {
            lineItems: [...record.yardLineItems, {}].map((item) => ({
              ...item,
              ...(item.ID ? { TotalAmount: item?.Rate * +item?.Quantity } : {}),
            })),
          }
        : null),
    })
  }, [itemForm, record?.yardLineItems])

  useEffect(() => {
    documentForm.setFieldsValue({
      documents: invoiceDocuments?.length ? [{}, ...invoiceDocuments] : [{}],
    })
  }, [documentForm, invoiceDocuments])

  const handleLineItemSave = async (key) => {
    if (localLoading || loader) return

    try {
      let { errorFields } = await itemForm.validateFields([
        ["lineItems", key, "ServiceID"],
        ["lineItems", key, "Description"],
        ["lineItems", key, "Quantity"],
      ])

      if (errorFields) return

      const row = itemForm.getFieldValue(["lineItems", key])

      if (row?.ID) {
        await dispatch(asyncUpdateOrderLineItem(row)).unwrap()
      } else {
        await dispatch(
          asyncCreateOrderLineItem({ yardMoveID: record?.ID, ...row })
        ).unwrap()
      }
      setItemEditingKey("")
    } catch (error) {
      console.log(error)
    }
  }

  const handleLineItemResetting = (key, record) => {
    if (localLoading || loader) return

    itemForm.setFields([{ name: ["lineItems", key], value: record }])
  }

  const handleLineItemDelete = async (remove, record) => {
    if (localLoading || loader) return
    let res = await dispatch(asyncDeleteLineItem(record?.ID)).unwrap()
    if (res.success) {
      remove()
    }
  }

  const handleDocumentDelete = async (remove, record) => {
    if (localLoading || loader) return
    let res = await dispatch(asyncDeleteDocument(record?.ID)).unwrap()
    if (res.success) {
      remove()
    }
  }

  const handleFileSelection = async () => {
    let documentsData = [...documentForm.getFieldValue("documents")]

    let fileRecord = { ...documentsData?.shift(), link: showFileUploader.file }

    documentsData.push(fileRecord)

    documentForm.setFieldsValue({
      documents: documentsData,
    })

    setShowFileUploader({
      show: false,
      file: {},
    })
  }

  const handleDocumentSave = async (key) => {
    if (localLoading || loader) return

    try {
      let { errorFields } = await documentForm.validateFields([
        ["documents", key, "link"],
        ["documents", key, "DocumentType"],
      ])

      if (errorFields) return

      const row = documentForm.getFieldValue(["documents", key])

      let documentFormData = new FormData()

      documentFormData.append("link", row.link.originFileObj)
      documentFormData.append("DocumentType", row.DocumentType)
      documentFormData.append("docCategory", 2)

      if (row?.ID) {
        // await dispatch(asyncUpdateDocument(row)).unwrap();
      } else {
        await dispatch(
          asyncCreateDocument({
            yardMoveID: record?.ID,
            body: documentFormData,
          })
        ).unwrap()
      }
      setEditingKey("")
    } catch (error) {
      console.log(error)
    }
  }

  const handleDocumentResetting = (key, record) => {
    if (localLoading || loader) return

    documentForm.setFields([
      { name: ["documents", key, "link"], value: record.link },
    ])
  }

  const handleSend = async (ID = null) => {
    if (localLoading || loader) return

    let res = await dispatch(
      asyncSendInvoice({
        invoiceID: record?.invoice?.ID,
        docIds: ID
          ? [ID, ...selectedAttachmentIDs]
          : viewerSource.ID
          ? [viewerSource.ID]
          : selectedDocIDs,
      })
    ).unwrap()

    if (res.success) {
      setSelectedAttachmentIDs([])
      setShowAttachment(false)
      setSelectedDocIDs([])
    }
  }

  const handlePrint = (source) => {
    if (localLoading || loader) return
    setOpen(false)
    setViewerSource((v) => ({ ...v, ...source }))
  }

  const handleViewInvoice = () => {
    if (loader || localLoading) return
    setOpen((open) => !open)
  }

  const onFinish = async (values) => {
    if (localLoading || loader) return

    await dispatch(
      asyncCreateInvoice({ yardMoveID: record?.ID, ...values })
    ).unwrap()

    setEditingKey("")
    setItemEditingKey("")
  }

  const onFinishFailed = (values) => {
    console.log(values)
  }

  let calculateAmount = () => {
    let amount = itemForm.getFieldValue("lineItems")?.reduce((acc, curr) => {
      if (
        !isNaN(curr?.ServiceID) &&
        !isNaN(curr?.Quantity) &&
        !isNaN(curr?.Rate)
      ) {
        return acc + curr.Quantity * curr.Rate
      }
      return acc
    }, 0)

    if (subTotalRef?.current) {
      subTotalRef.current.innerText = `$${amount}`
    }
    if (totalRef?.current) {
      totalRef.current.innerText = `$${amount}`
    }
  }

  const lineItemColumns = ({ remove }) => [
    {
      title: "",
      dataIndex: "ID",
      editable: true,
      isRequired: false,
      hidden: true,
      width: 0,
      // className: "lineItemID",
      render: () => null,
    },
    {
      title: "Sr#",
      dataIndex: "id",
      render: (_, __, ind) => {
        return ind + 1
      },
      width: 50,
    },
    {
      title: "Service",
      type: "DropDown",
      keySet: ["ID", "Service"],
      dropDownData: moveTypes,
      isRequired: true,
      editable: true,
      dataIndex: "ServiceID",
      onChange: ({ name, value }) => {
        let service = moveTypes.find((service) => service.ID === value)

        itemForm.setFields([
          {
            name: ["lineItems", name[0], "Rate"],
            value: service?.Price ?? 0,
          },
          {
            name: ["lineItems", name[0], "TotalAmount"],
            value:
              (itemForm.getFieldValue(["lineItems", name[0], "Quantity"]) ??
                0) * (service?.Price ?? 0),
          },
        ])

        calculateAmount()
      },
      width: 150,
    },
    {
      title: "Description",
      editable: true,
      isRequired: true,
      whitespace: true,
      type: "text",
      dataIndex: "Description",
    },
    {
      title: "Quantity",
      isRequired: true,
      editable: true,
      dataIndex: "Quantity",
      onChange: ({ name, value: { target } }) => {
        if (isNaN(target.value)) {
          target.value = 0
        }
        let serviceRate = itemForm.getFieldValue(["lineItems", name[0], "Rate"])
        itemForm.setFields([
          {
            name: ["lineItems", name[0], "TotalAmount"],
            value: +target.value * +(serviceRate ?? 0),
          },
        ])

        calculateAmount()
      },
      width: "15%",
    },
    {
      title: "Rate",
      isRequired: false,
      editable: true,
      disabled: true,
      dataIndex: "Rate",
      width: "15%",
    },
    {
      title: "Amount",
      isRequired: false,
      editable: true,
      disabled: true,
      dataIndex: "TotalAmount",
      width: "15%",
    },
    {
      dataIndex: "Action",
      align: "center",
      width: "3%",
      render: (_, itemRecord, ind) => {
        let itemsLength = itemForm.getFieldValue("lineItems").length

        return (
          <Actions
            ind={ind}
            record={itemRecord}
            invoiceID={record?.invoice?.ID}
            type={TableType.LINEITEMS}
            remove={(ind) =>
              handleLineItemDelete(() => remove(ind), itemRecord)
            }
            dataLength={itemsLength}
            handleSave={handleLineItemSave}
            handleRowResetting={handleLineItemResetting}
            editingKey={itemEditingKey}
            setEditingKey={setItemEditingKey}
          />
        )
      },
    },
  ]

  const documentColumns = ({ remove }) => [
    {
      title: "",
      dataIndex: "ID",
      editable: true,
      isRequired: false,
      hidden: true,
      width: 0,
      // className: "documentID",
      render: () => null,
    },
    {
      title: "Attachment",
      dataIndex: "link",
      type: "file",
      isRequired: true,
      editable: true,
      width: "40%",
    },
    {
      title: "Type",
      type: "DropDown",
      keySet: ["ID", "DocumentType"],
      dropDownData: documentTypes,
      isRequired: true,
      editable: true,
      dataIndex: "DocumentType",
      width: "40%",
    },
    {
      dataIndex: "action",
      align: "center",
      width: "20%",
      render: (_, documentRecord, ind) => {
        let documentsLength = documentForm.getFieldValue("documents").length

        return (
          <Actions
            ind={ind}
            record={documentRecord}
            remove={(ind) =>
              handleDocumentDelete(() => remove(ind), documentRecord)
            }
            dataLength={documentsLength}
            handleSave={handleDocumentSave}
            handleRowResetting={handleDocumentResetting}
            editingKey={editingKey}
            setEditingKey={setEditingKey}
          />
        )
      },
    },
  ]

  return (
    <div
      className={styles.account}
      onDragOver={(e) => {
        e.preventDefault()
        setShowFileUploader((s) => ({ ...s, show: true }))
      }}
      onDragExit={(e) => {
        e.preventDefault()
      }}
      onDragLeave={(e) => {
        e.preventDefault()
      }}
      onDrop={(e) => {
        e.preventDefault()
        setShowFileUploader((s) => ({ show: false, file: {} }))
      }}
    >
      <div>
        <Form
          form={form}
          name="publish"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div>
            <Space className="mb-15">
              <h3 className="text-2xl font-600">Bill to</h3>
              <Tag color="red">
                {record?.invoice
                  ? record?.invoice?.PaymentStatus?.toUpperCase()
                  : "PENDING"}
              </Tag>
            </Space>
            <Row gutter={8}>
              <Col lg={5} xl={4}>
                <Form.Item name="Customer_Name">
                  <AppInput
                    disabled={true}
                    title="Customer Name"
                    placeholder="Name"
                  />
                </Form.Item>
              </Col>
              <Col lg={6} xl={5}>
                <p className="text-xs dark-80 font-500">Billing Address</p>
                <Form.Item name="Customer_Address">
                  <TextArea
                    disabled={true}
                    placeholder="Address"
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={5} xl={4}>
                <Form.Item name="Billing_Email">
                  <AppInput
                    disabled={true}
                    title="Customer Email"
                    placeholder="Email"
                  />
                </Form.Item>
              </Col>
              <Col lg={4} xl={4}>
                <Form.Item
                  name="InvoiceDate"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <SearchDate
                    disabled={!!record?.invoice?.ID}
                    title="Invoice Date"
                    dataIndex="invoiceDate"
                    onChange={({ value }) => {
                      form.setFieldValue("InvoiceDate", value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} xl={4}>
                <Form.Item
                  name="InvoiceDueDate"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <SearchDate
                    disabled={!!record?.invoice?.ID}
                    title="Invoice Due Date"
                    dataIndex="invoiceDueDate"
                    onChange={({ value }) => {
                      form.setFieldValue("InvoiceDueDate", value)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8} className="mb-15">
              <Col lg={5} xl={4}>
                <Form.Item name="YardMoveOrderID">
                  <AppInput
                    disabled={true}
                    title="Move ID"
                    placeholder="Move Id"
                  />
                </Form.Item>
              </Col>
              <Col lg={5} xl={4}>
                <Form.Item name="YardTractorMoveType">
                  <AppInput
                    disabled={true}
                    title="Move Type"
                    placeholder="Move Type"
                  />
                </Form.Item>
              </Col>
              <Col lg={5} xl={4}>
                <Form.Item name="Trailer">
                  <AppInput
                    disabled={true}
                    title="Trailer"
                    placeholder="Trailer"
                  />
                </Form.Item>
              </Col>
              <Col lg={5} xl={4}>
                <Form.Item name="AssignedOrderRef">
                  <AppInput disabled={true} title="Trip" placeholder="Trip" />
                </Form.Item>
              </Col>
              <Col lg={4} xl={4}>
                <Form.Item name="Door">
                  <AppInput disabled={true} title="Door" placeholder="Door" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
        <div>
          <Form
            form={itemForm}
            name="publish"
            layout="vertical"
            autoComplete="off"
          >
            <Form.List name="lineItems" initialValue={[{}]}>
              {(_, { remove }) => {
                calculateAmount()
                return (
                  <DataTable
                    form={itemForm}
                    columns={addAdditionalPropsToCell(lineItemColumns, {
                      remove,
                      listName: "lineItems",
                      editingKey: itemEditingKey,
                    })}
                    dataSource={itemForm.getFieldValue("lineItems")}
                    // loader={lineItemLoader}
                    loader={localLoading}
                    pagination={false}
                    isEditable={true}
                  />
                )
              }}
            </Form.List>
          </Form>
          <Space
            direction="vertical"
            className={styles.billHeighlight}
            style={{ marginBottom: 15 }}
          >
            <Space className={styles.space}>
              <h5 className="text-xs font-600 info">Sub Total</h5>
              <h3 ref={subTotalRef} className="text-sm font-700">
                0
              </h3>
            </Space>
            <Space className={clx(styles.space, styles.spaceDark)}>
              <h5 className="text-xs font-600 white">Total</h5>
              <h3 ref={totalRef} className="text-base font-700 white">
                0
              </h3>
            </Space>
          </Space>

          <Form
            form={documentForm}
            name="publish"
            layout="vertical"
            autoComplete="off"
          >
            <Form.List name="documents" initialValue={[{}]}>
              {(_, { remove }) => {
                return (
                  <>
                    <Row justify="space-between" className="mb-5 mt-15">
                      <h3 className="text-2xl font-600">Invoice Documents</h3>
                      {selectedDocIDs.length > 0 && (
                        <AppButton
                          icon={
                            <img
                              src={send}
                              alt="img"
                              className="mr-5"
                              width="16px"
                            />
                          }
                          title="Send Email"
                          onClick={() => handleSend()}
                          className="btn_dark"
                        />
                      )}
                    </Row>
                    <Row>
                      <Col span={24}>
                        <DataTable
                          form={documentForm}
                          columns={addAdditionalPropsToCell(documentColumns, {
                            remove,
                            listName: "documents",
                            editingKey,
                          })}
                          dataSource={documentForm.getFieldValue("documents")}
                          onRow={({ ID, link } = {}) => ({
                            onDoubleClick: () => {
                              if (ID) return setViewerSource({ ID, link })
                            },
                          })}
                          rowSelection={{
                            type: "checkbox",
                            getCheckboxProps: (record) => {
                              return {
                                disabled: !record.ID,
                                style: record.ID ? null : { display: "none" },
                              }
                            },
                            onSelectAll: (_, selectedRows) => {
                              setSelectedDocIDs((_) => {
                                return !selectedRows.length
                                  ? []
                                  : selectedRows?.map((doc) => doc.ID)
                              })
                            },
                            onChange: (_, selectedRows) => {
                              setSelectedDocIDs((_) => {
                                return !selectedRows.length
                                  ? []
                                  : selectedRows?.map((doc) => doc.ID)
                              })
                            },
                          }}
                          loader={loader || localLoading}
                          pagination={false}
                          isEditable={true}
                        />
                      </Col>
                    </Row>
                  </>
                )
              }}
            </Form.List>
          </Form>
        </div>
      </div>
      <Space className={styles.invoiceTopBtns}>
        {record?.invoice?.ID && (
          <AppButton
            disabled={!record?.invoice?.ID}
            title="View Invoice"
            className="btn_dark"
            onClick={handleViewInvoice}
          />
        )}
        {/* <AppButton
          disabled={!record?.invoice?.ID}
          icon={<img src={send} alt="img" className="mr-5" width="16px" />}
          title="Send Invoice"
          className="btn_dark"
          onClick={handleSend}
        /> */}
        {!record?.invoice?.ID && (
          <AppButton
            icon={<img src={Print} alt="img" className="mr-5" width="20px" />}
            title="Post Invoice"
            onClick={() => {
              form.submit()
            }}
            className="btn_dark"
          />
        )}
      </Space>

      {showAttachment && (
        <DocSelection
          localLoading={localLoading}
          documents={invoiceDocuments}
          open={showAttachment}
          handleChecked={(checkedValues) => {
            setSelectedAttachmentIDs(checkedValues)
          }}
          onDiscard={() => {
            setShowAttachment(false)
            setSelectedAttachmentIDs([])
          }}
          onDone={() => {
            handleSend(viewerSource.ID)
          }}
        />
      )}

      {!!viewerSource.link && (
        <FileViewer
          src={viewerSource.link}
          open={!!viewerSource.link}
          onClickSend={() => {
            setShowAttachment(true)
          }}
          onCancel={() => setViewerSource({ ID: null, link: "" })}
        />
      )}

      {showFileUploader.show && (
        <FileUploader
          handleFileSelection={handleFileSelection}
          showFileUploader={showFileUploader}
          setShowFileUploader={setShowFileUploader}
        />
      )}

      {!!record?.invoice?.ID && open && !viewerSource.link && (
        <InvoiceModal
          record={record}
          documents={invoiceDocuments}
          open={open}
          onClick={handleViewInvoice}
          handlePrint={handlePrint}
        />
      )}
    </div>
  )
}

export default memo(Invoice)
