import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { resetAuth, setAuth } from "../reducers/auth";
import { setLoading } from "../reducers/common";
import { setUser } from "../reducers/user";
import { asyncAlertError } from "./common";

export const asyncLoginUserProfile = createAsyncThunk(
  "profile",
  async (_, { dispatch, getState }) => {
    try {
      setLoading(true);

      const { token } = getState().auth;

      const res = await callApi({
        path: "user/profile",
        token,
      });

      if (res.success) {
        setLoading(false);
        dispatch(setUser(res.data));
        return { success: true };
      }

      setLoading(false);
      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      setLoading(false);
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

export const asyncRegisterUser = createAsyncThunk(
  "register",
  async (body, { dispatch }) => {
    try {
      setLoading(true);

      const { firstName, lastName, ...values } = body;
      const res = await callApi({
        path: "user",
        method: "POST",
        body: { name: `${firstName} ${lastName}`, ...values },
      });

      if (res.success) {
        setLoading(false);
        // dispatch(asyncAlertSuccess("Created Successfully"));
        return { success: true };
      }

      setLoading(false);
      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      setLoading(false);
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

export const asyncLoginUser = createAsyncThunk(
  "login",
  async (body, { dispatch }) => {
    try {
      setLoading(true);

      const res = await callApi({
        path: "dashboard/login",
        method: "POST",
        body,
      });

      if (res.success) {
        setLoading(false);
        dispatch(setAuth(res.token));
        dispatch(setUser(res.data));
        // dispatch(asyncAlertSuccess("User Logged In Successfully"));
        return { success: true };
      }

      setLoading(false);
      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      setLoading(false);
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

export const asyncLogOutUser = createAsyncThunk(
  "logout",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "user/logout",
        method: "PUT",
        token,
      });

      if (res.success) {
        dispatch(resetAuth());
        // dispatch(asyncAlertSuccess("User Logged Out Successfully"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);
