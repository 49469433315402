import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import React from "react";
import AppModal from ".";
import { phoneValidator } from "../../utils";
import AppButton from "../Button";
import AppInput from "../Input";
import AppSelect from "../Select";

export const SmsUser = ({
  open,
  title,
  rules,
  users,
  isEdit,
  btnTitle,
  onCancel,
  onClick,
}) => {
  return (
    <AppModal open={open} title={title} maxWidth="23%">
      {!isEdit && (
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="UserId"
              rules={[
                {
                  required: true,
                  message: `User is required!`,
                },
              ]}
            >
              <AppSelect
                title="User"
                size="large"
                data={users.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))}
                disabled={!users.length}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="Phone"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: `Phone is required!`,
              },
              phoneValidator,
            ]}
          >
            <AppInput
              className="user-modal-input"
              title="Phone"
              placeholder="phone"
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="Rule"
            rules={[
              {
                required: true,
                message: `Rule is required!`,
              },
            ]}
          >
            <AppSelect
              mode="multiple"
              title="Rule"
              size="large"
              data={rules.map((rule) => ({
                value: rule.value,
                label: rule.label,
              }))}
              disabled={!rules.length}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <AppButton
          icon={<MinusOutlined style={{ fontSize: 18 }} />}
          title="Discard"
          width="120px"
          height="max-content"
          fontSize="16px"
          className="btn_red"
          onClick={onCancel}
        />
        <AppButton
          icon={<PlusOutlined style={{ fontSize: 18 }} />}
          title={btnTitle}
          width="120px"
          height="max-content"
          fontSize="16px"
          className="btn_green mr-10"
          onClick={onClick}
        />
      </Row>
    </AppModal>
  );
};
