import React from "react";
import { Divider, Tag } from "antd";
import AppButton from "../Button";
import styles from "./style.module.scss";
import clsx from "classnames";
import { chatTypes } from "../Messages";

export const AppTag = ({
  title,
  margin,
  padding,
  height,
  className,
  ...props
}) => {
  return (
    <Tag
      className={clsx(styles.tag, className)}
      style={{ margin, padding, height }}
      {...props}
    >
      {title}
    </Tag>
  );
};

export const ChatHeader = ({
  backIcon,
  // heading,
  // headingIcon,
  onBack,
  selectedChat,
  setSelectedChat,
}) => {
  return (
    <>
      <div className={styles.chatHeader}>
        {backIcon ? (
          <AppButton onClick={onBack} className={styles.backButtonIcon}>
            {backIcon}
            {/* <img src={backIcon} alt="" /> */}
          </AppButton>
        ) : (
          ""
        )}
        {/* <div className={styles.heading}>
          {headingIcon}
          <p>{heading}</p>
        </div> */}
        <AppButton
          title="Messages"
          className={clsx(
            styles.chatHeadBtn,
            chatTypes.globalChat.key === selectedChat ? styles.active : ""
          )}
          onClick={() => {
            setSelectedChat(chatTypes.globalChat.key);
          }}
        />
        {/* <Divider
          type="vertical"
          style={{ height: 32, margin: 0, backgroundColor: "#fff" }}
        /> */}
        <AppButton
          title="Notification"
          className={clsx(
            styles.chatHeadBtn,
            chatTypes.globalChat.key !== selectedChat ? styles.active : ""
          )}
          onClick={() => {
            setSelectedChat(chatTypes.completedNotifications.key);
          }}
        />
        {/* <Switch
          size="small"
          onChange={(val) => {
            setSelectedChat(
              val
                ? chatTypes.completedNotifications.key
                : chatTypes.globalChat.key
            );
          }}
          className={styles.chatSwitch}
        /> */}
      </div>
    </>
  );
};

export const ChatChannel = ({
  heading,
  headingIcon,
  messageCount,
  setSelectedChat,
}) => {
  return (
    <div className={styles.chatChanel} onClick={setSelectedChat}>
      <div className={styles.heading}>
        {headingIcon}
        {/* <img src={headingIcon} /> */}
        <p>{heading}</p>
      </div>
      {messageCount ? (
        <AppButton className={styles.messageCountBox} padding="0px">
          {messageCount}
        </AppButton>
      ) : (
        ""
      )}
    </div>
  );
};
