import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

const SmsUserSlice = createSlice({
  name: "smsUser",
  initialState,
  reducers: {
    setUsers: (state, { payload }) => {
      state.users = payload;
    },
  },
});

export const { setUsers } = SmsUserSlice.actions;
export default SmsUserSlice.reducer;
