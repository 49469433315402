import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Messages from "../../../Components/Messages";
import { asyncGetAllUsers } from "../../../store/actions/user";
import { asyncGetAllVehicles } from "../../../store/actions/vehicle";

export const AdminChat = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const loadUsers = async () => {
      await dispatch(asyncGetAllUsers()).unwrap();
      await dispatch(asyncGetAllVehicles()).unwrap();
    };

    loadUsers();
  }, [dispatch]);

  return <Messages />;
};

export default AdminChat;
