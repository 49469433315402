import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { setUsers } from "../reducers/user";
import { asyncAlertError } from "./common";
import { setLocalAdminLoading } from "../reducers/common";

export const asyncGetAllUsers = createAsyncThunk(
  "user/list",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "admin/userlist",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setUsers(res.data));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncCreateUser = createAsyncThunk(
  "user/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const { token } = getState().auth;

      const res = await callApi({
        path: `admin/user`,
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        // dispatch(asyncAlertSuccess("User Successfully Created!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncUpdateUser = createAsyncThunk(
  "user/update",
  async ({ id, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const { token } = getState().auth;

      const res = await callApi({
        path: `admin/update/${id}`,
        method: "PUT",
        body,
        token,
      });

      if (res.success) {
        // dispatch(asyncAlertSuccess("User Successfully Updated!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncBanUser = createAsyncThunk(
  "user/ban",
  async ({ id }, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const { token } = getState().auth;
      const res = await callApi({
        path: `admin/ban/${id}`,
        method: "PUT",
        token,
      });

      if (res.success) {
        // dispatch(
        //   asyncAlertSuccess(
        //     `User Successfully ${res.data.ban ? "Banned" : "UnBanned"}!`
        //   )
        // );
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncDeleteUser = createAsyncThunk(
  "user/delete",
  async ({ id, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const { token } = getState().auth;

      const res = await callApi({
        path: `admin/delete/${id}`,
        method: "DELETE",
        body,
        token,
      });

      if (res.success) {
        // dispatch(asyncAlertSuccess("User Successfully Deleted!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);
