import { MenuOutlined, MoreOutlined } from "@ant-design/icons";
import { Col, Divider, Layout, Menu, Row, Tooltip } from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
// import bell from "../../Assets/icons/bell.svg";
// import trailer from "../../Assets/icons/trailer.png";
// import file from "../../Assets/icons/file.svg";
// import yardMove from "../../Assets/icons/yardmovetruck.svg";
// import home from "../../Assets/icons/home.svg";
// import logout from "../../Assets/icons/logout.svg";
// import users from "../../Assets/icons/users.svg";
// import customers from "../../Assets/icons/customers.svg";
// import carrier from "../../Assets/icons/supplyChainManagement.png";
// import logo from "../../Assets/images/logo.svg";
import logo from "../../Assets/images/OsceolaLogoBGBlack.png";
// import truck from "../../Assets/icons/trailer.png";
// import timesheet from "../../Assets/icons/timesheet.svg";
import { asyncLogOutUser } from "../../store/actions/auth";
// import customer from "../../store/reducers/customer";
import { formatDashboardDate } from "../../utils";
import AppButton from "../Button";
import { PATHS } from "../Navigation";

import {
  BiHome,
  BiTimer,
  BiUserCircle,
  BiUserPlus,
  BiMessageAltDetail,
} from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { TbBellRinging } from "react-icons/tb";

const { Content, Sider } = Layout;

function getItem(label, key, icon, onClick, children = []) {
  return {
    key,
    icon,
    // children: !children.length ? null : children,
    label,
    onClick,
  };
}

const AppLayout = ({ siteLayoutRef, title, navigate }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((s) => s.user);
  // const { notificationsCount } = useSelector((state) => state.notification);

  const [collapsed, setCollapsed] = useState(false);
  const [date, setDate] = useState(formatDashboardDate());

  useEffect(() => {
    let interval = setInterval(() => {
      return setDate(formatDashboardDate());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const items = [
    getItem(
      "Home",
      PATHS.ROOT,
      <BiHome />,
      // <img className="icon-img" src={home} alt="" style={{ width: 18 }} />,
      () => navigate(PATHS.ROOT)
    ),
  ];

  if (+user?.role !== 15) {
    items.push(
      getItem(
        "Yard Move",
        PATHS.YARDMOVEREQUESTCLONE,
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="18px"
          height="18px"
          viewBox="0 0 203.000000 203.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,203.000000) scale(0.100000,-0.100000)"
            fill="#d8d8d8"
            stroke="none"
          >
            <path d="M266 1944 c-3 -26 -6 -70 -6 -99 0 -53 0 -53 -36 -60 -24 -4 -38 -13 -41 -26 -3 -12 -14 -19 -29 -19 l-24 0 0 -135 0 -135 25 0 c14 0 25 -6 25 -13 0 -20 33 -47 58 -47 22 0 22 -1 22 -225 0 -143 -4 -225 -10 -225 -6 0 -10 -38 -10 -95 l0 -95 763 2 762 3 3 328 2 327 24 0 c24 0 56 23 56 41 0 5 11 9 25 9 l25 0 0 125 0 125 -24 0 c-14 0 -30 10 -40 25 -10 15 -26 25 -41 25 -17 0 -25 6 -27 23 -2 16 -11 23 -30 25 l-28 3 0 -435 c0 -381 -2 -436 -15 -436 -13 0 -15 61 -15 488 0 366 -3 491 -12 500 -18 18 -46 14 -58 -7 -6 -13 -11 -193 -12 -503 -2 -326 -4 -389 -5 -195 -3 279 -4 287 -23 287 -19 0 -20 -7 -20 -285 l0 -285 -29 0 c-27 0 -28 2 -34 61 -3 34 -11 67 -19 74 -7 7 -71 49 -142 94 -124 77 -132 81 -188 81 l-58 0 0 360 0 360 -404 0 -403 0 -7 -46z m764 -44 c0 -6 -128 -10 -365 -10 -237 0 -365 4 -365 10 0 6 128 10 365 10 237 0 365 -4 365 -10z m1 -89 c17 -21 19 -42 19 -251 0 -209 -2 -230 -19 -251 l-19 -24 -323 -3 c-344 -3 -367 0 -388 44 -15 34 -15 434 0 468 6 13 21 29 33 35 14 6 135 9 349 8 l329 -2 19 -24z m-771 -211 c0 -171 -1 -180 -19 -180 -20 0 -51 25 -51 41 0 5 9 9 20 9 19 0 20 7 20 135 0 128 -1 135 -20 135 -11 0 -20 4 -20 10 0 12 30 29 53 30 16 0 17 -15 17 -180z m1558 158 c18 -18 15 -28 -8 -28 -19 0 -20 -7 -20 -125 l0 -125 25 0 c14 0 25 -4 25 -8 0 -15 -24 -32 -47 -32 l-23 0 0 165 c0 152 1 165 18 165 10 0 23 -5 30 -12z m-1353 -883 l0 -50 -65 0 -65 0 -3 39 c-5 63 0 67 70 64 l63 -3 0 -50z m1093 -2 l-3 -48 -65 0 -65 0 -3 48 -3 47 71 0 71 0 -3 -47z" />
            <path d="M230 590 l0 -150 30 0 30 0 0 -200 0 -200 95 0 95 0 0 200 0 200 540 0 540 0 0 -200 0 -200 95 0 95 0 0 200 c0 127 4 200 10 200 6 0 10 57 10 150 l0 150 -770 0 -770 0 0 -150z m224 114 c9 -3 16 -15 16 -25 0 -17 -8 -19 -59 -19 -63 0 -81 11 -61 35 13 15 75 20 104 9z m1080 0 c9 -3 16 -15 16 -25 0 -17 -8 -19 -60 -19 -52 0 -60 2 -60 19 0 11 6 22 13 24 18 8 73 8 91 1z" />
          </g>
        </svg>,
        () => navigate(PATHS.YARDMOVEREQUESTCLONE)
      )
      // getItem(
      //   "Yard Move Drawer",
      //   PATHS.YARDMOVENEW,
      //   <img
      //     className="icon-img"
      //     src={timesheet}
      //     alt=""
      //     style={{ width: 20 }}
      //   />,
      //   () => navigate(PATHS.YARDMOVENEW)
      // )
    );
  } else {
    items.push(
      getItem(
        "Users",
        PATHS.USERS,
        <BiUserCircle />,
        // <img className="icon-img" src={users} alt="" style={{ width: 20 }} />,
        () => navigate(PATHS.USERS)
      ),
      getItem(
        "Carrier",
        PATHS.CARRIER,
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="24px"
          height="18px"
          viewBox="0 0 256.000000 256.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
            fill="#d9d9d9"
            stroke="none"
          >
            <path d="M644 2506 c-52 -23 -55 -42 -52 -375 l3 -303 28 -24 c24 -21 38 -24 102 -24 73 0 75 -1 90 -30 47 -92 191 -91 243 0 l17 30 227 0 c211 0 227 -1 232 -18 4 -10 19 -31 36 -47 59 -57 174 -39 210 33 13 27 20 32 53 32 27 0 45 7 62 25 25 24 25 27 25 196 l0 173 -56 92 c-86 142 -80 138 -245 142 -131 3 -139 5 -139 23 0 33 -20 60 -55 75 -49 20 -735 20 -781 0z m754 -358 l2 -288 -162 0 -163 0 -22 34 c-23 36 -76 66 -118 66 -38 0 -98 -36 -119 -71 -16 -27 -21 -29 -82 -29 l-64 0 0 283 c0 156 3 287 7 290 4 4 167 6 362 5 l356 -3 3 -287z m388 92 c52 -82 54 -88 54 -155 0 -70 0 -71 -31 -82 -25 -10 -30 -16 -27 -40 2 -22 9 -29 31 -31 24 -3 27 -8 27 -38 0 -29 -4 -34 -22 -34 -15 0 -29 11 -40 29 -50 89 -186 89 -236 0 -11 -18 -25 -29 -39 -29 l-23 0 0 235 0 236 127 -3 126 -3 53 -85z m-820 -368 c13 -8 34 -46 34 -61 0 -19 -39 -51 -62 -51 -50 0 -76 63 -41 102 16 18 49 23 69 10z m732 -8 c44 -31 17 -104 -39 -104 -30 0 -59 31 -59 62 0 23 35 58 58 58 10 0 28 -7 40 -16z" />
            <path d="M1522 2288 c-8 -8 -12 -46 -12 -103 0 -57 4 -95 12 -103 8 -8 53 -12 135 -12 155 0 159 3 112 117 -46 113 -46 113 -149 113 -53 0 -91 -5 -98 -12z m161 -103 l17 -35 -55 0 -55 0 0 35 c0 34 1 35 38 35 34 0 40 -4 55 -35z" />
            <path d="M395 2194 c-9 -10 -14 -25 -12 -35 4 -16 -1 -19 -32 -19 -72 0 -71 4 -71 -363 0 -315 1 -328 20 -347 16 -16 25 -18 40 -10 19 10 20 24 22 322 2 251 6 312 17 316 9 3 11 -2 6 -17 -8 -26 10 -51 37 -51 10 0 46 19 79 42 78 56 77 76 -6 135 -67 47 -77 50 -100 27z" />
            <path d="M1952 2098 c-36 -36 2 -68 84 -68 l64 0 0 -409 c0 -393 -1 -409 -19 -404 -17 4 -51 -19 -51 -36 0 -12 75 -124 92 -139 9 -7 23 -9 32 -6 22 9 96 118 96 142 0 27 -25 45 -49 37 -21 -6 -21 -4 -23 442 l-3 448 -105 3 c-73 2 -109 -1 -118 -10z" />
            <path d="M295 1306 c-66 -30 -95 -87 -95 -187 0 -62 14 -83 52 -77 21 3 23 9 29 81 7 110 9 111 176 117 115 4 138 8 147 23 8 12 8 22 0 35 -10 15 -30 17 -145 19 -95 2 -143 -1 -164 -11z" />
            <path d="M355 1165 c-27 -27 -35 -91 -13 -113 30 -30 68 -8 68 39 0 18 8 19 106 19 110 0 144 9 144 38 0 36 -20 42 -152 42 -124 0 -130 -1 -153 -25z" />
            <path d="M1019 1001 c-91 -26 -171 -51 -177 -55 -8 -5 -12 -41 -12 -105 l0 -98 -128 -37 c-70 -20 -131 -36 -135 -36 -4 0 -7 15 -7 34 0 43 -29 66 -81 66 l-36 0 -12 88 c-6 49 -11 98 -11 109 0 42 -25 53 -119 53 -54 0 -93 -5 -104 -12 -12 -10 -19 -43 -28 -124 l-12 -112 -50 -5 c-27 -2 -53 -11 -58 -18 -5 -8 -9 -166 -9 -351 0 -323 1 -338 19 -348 13 -6 217 -10 594 -10 543 0 575 1 590 18 15 17 17 66 17 493 0 356 -3 478 -12 487 -19 19 -52 14 -229 -37z m159 -458 l-3 -418 -132 -3 -133 -3 0 384 0 383 123 36 c67 20 128 36 135 37 10 1 12 -85 10 -416z m-834 370 c2 -16 8 -54 11 -85 l6 -58 -61 0 -62 0 7 78 c4 42 9 80 12 85 2 4 22 7 43 7 34 0 39 -3 44 -27z m131 -508 c0 -273 0 -280 -20 -280 -19 0 -20 8 -23 178 l-2 178 -26 9 c-14 6 -66 10 -115 10 -131 0 -129 3 -129 -210 0 -163 -1 -170 -20 -170 -20 0 -20 7 -20 285 l0 285 178 -2 177 -3 0 -280z m353 -12 l-3 -268 -132 -3 -133 -3 0 233 0 233 128 36 c70 20 130 37 135 38 4 0 6 -119 5 -266z m-478 -124 l0 -150 -52 3 -53 3 -3 134 c-1 74 0 141 2 148 4 8 24 13 56 13 l50 0 0 -151z" />
            <path d="M1000 715 l0 -45 45 0 45 0 0 45 0 45 -45 0 -45 0 0 -45z" />
            <path d="M1000 410 l0 -50 45 0 45 0 0 50 0 50 -45 0 -45 0 0 -50z" />
            <path d="M644 447 c-3 -9 -4 -30 -2 -49 3 -32 5 -33 51 -36 l48 -3 -3 48 -3 48 -43 3 c-30 2 -44 -1 -48 -11z" />
            <path d="M1974 950 c-94 -29 -177 -126 -190 -222 -9 -64 18 -149 63 -199 20 -22 34 -41 32 -42 -2 -1 -29 -15 -60 -30 -61 -31 -148 -112 -186 -173 -28 -46 -63 -155 -63 -195 0 -18 7 -32 19 -39 13 -6 175 -10 460 -10 520 0 490 -8 457 116 -33 125 -131 249 -241 304 -28 14 -52 26 -54 27 -2 1 10 18 28 38 88 101 85 260 -9 353 -65 66 -176 97 -256 72z m134 -85 c74 -31 114 -90 114 -165 0 -60 -25 -114 -70 -148 -31 -24 -45 -27 -108 -27 -58 0 -78 4 -101 21 -96 71 -107 197 -25 279 54 54 122 68 190 40z m112 -472 c95 -46 183 -151 205 -245 l7 -28 -386 0 c-442 0 -409 -9 -356 97 95 190 337 270 530 176z" />
            <path d="M1367 849 c-65 -45 -76 -62 -56 -85 17 -21 125 -94 138 -94 19 0 42 32 36 51 -6 18 0 19 117 19 141 0 166 9 154 53 l-6 27 -136 0 c-132 0 -135 1 -129 20 8 26 -11 50 -39 49 -11 0 -47 -18 -79 -40z" />
          </g>
        </svg>,
        // <img className="icon-img" src={carrier} alt="" style={{ width: 20 }} />,
        () => navigate(PATHS.CARRIER)
      ),
      getItem(
        "Customer",
        PATHS.CUSTOMER,
        <BiUserPlus />,
        // <img
        //   className="icon-img"
        //   src={customers}
        //   alt=""
        //   style={{ width: 20 }}
        // />,
        () => navigate(PATHS.CUSTOMER)
      ),
      getItem(
        "Trailer",
        PATHS.TRAILER,
        <span className="trailerIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="24px"
            height="22px"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#d8d8d8"
              stroke="none"
            >
              <path d="M84 4160 c-25 -13 -49 -35 -62 -59 l-22 -39 0 -898 c0 -1003 -4 -942 69 -991 30 -20 47 -23 132 -23 l98 0 3 -179 c3 -164 5 -182 24 -208 48 -64 147 -87 201 -47 64 48 68 60 73 252 l5 177 733 3 c402 1 732 -1 732 -4 0 -4 -42 -79 -92 -168 -81 -140 -93 -167 -93 -208 0 -105 110 -176 209 -134 44 18 62 44 207 299 l123 217 1041 -2 1040 -3 130 -226 c72 -125 139 -237 150 -249 92 -102 255 -37 255 100 0 39 -13 69 -90 204 -49 88 -90 163 -90 167 0 5 35 9 79 9 65 0 84 4 112 23 73 49 69 -12 69 991 l0 898 -22 39 c-14 25 -37 46 -63 59 -41 20 -62 20 -2476 20 -2421 0 -2434 0 -2475 -20z m4736 -995 l0 -715 -2260 0 -2260 0 0 715 0 715 2260 0 2260 0 0 -715z" />
              <path d="M2795 1989 c-153 -18 -306 -116 -380 -243 -55 -95 -77 -172 -77 -276 1 -212 109 -385 297 -475 321 -154 696 49 748 406 23 158 -34 321 -152 438 -117 117 -271 170 -436 150z m161 -318 c92 -42 148 -154 125 -249 -42 -169 -232 -235 -361 -125 -55 48 -80 99 -80 168 0 95 45 169 125 206 52 24 138 24 191 0z" />
              <path d="M4005 1989 c-125 -12 -241 -72 -328 -169 -188 -209 -180 -524 16 -721 86 -86 182 -133 308 -152 154 -22 318 34 433 148 205 202 210 521 13 731 -118 126 -265 180 -442 163z m187 -334 c81 -57 121 -182 85 -268 -37 -88 -117 -141 -212 -141 -227 0 -309 294 -115 412 51 31 60 33 127 30 60 -3 79 -8 115 -33z" />
            </g>
          </svg>
        </span>,
        // <img className="icon-img" src={truck} alt="" style={{ width: 20 }} />,
        () => navigate(PATHS.TRAILER)
      ),
      getItem(
        "Time Sheet",
        PATHS.EmployeeManagment,
        <BiTimer />,
        // <img
        //   className="icon-img"
        //   src={timesheet}
        //   alt=""
        //   style={{ width: 20 }}
        // />,
        () => navigate(PATHS.EmployeeManagment)
      ),
      getItem(
        "Chat",
        PATHS.CHAT,
        <BiMessageAltDetail />,
        // <img
        //   className="icon-img"
        //   src={timesheet}
        //   alt=""
        //   style={{ width: 20 }}
        // />,
        () => navigate(PATHS.CHAT)
      )
    );
  }

  // const NotificationLabel = () => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //       }}
  //     >
  //       <p>Notification</p>
  //       {!!notificationsCount && (
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             background: "#000",
  //             color: "#fff",
  //             width: 25,
  //             height: 25,
  //             borderRadius: "50%",
  //             fontSize: 14,
  //           }}
  //         >
  //           {notificationsCount}
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  const itemsBottom = [
    getItem(
      "Log out",
      "logout",
      <span style={{ transform: "rotateY(180deg)" }}>
        <FiLogOut />
      </span>,
      // <img className="icon-img" src={logout} alt="" />,
      () => dispatch(asyncLogOutUser())
    ),
  ];
  // if (+user?.role !== 15) {
  // itemsBottom.splice(
  // 0,
  // 0,
  // getItem(
  //   <NotificationLabel />,
  //   PATHS.NOTIFICATION,
  //   <img className="icon-img" src={bell} alt="" />,
  //   () => navigate(PATHS.NOTIFICATION)
  // ),
  // getItem("Settings", PATHS.SETTING, <SettingOutlined />, () =>
  //   navigate(PATHS.SETTING)
  // )
  // );
  // } else {
  if (+user?.role === 15) {
    itemsBottom.unshift(
      getItem(
        "SMS Notifications",
        PATHS.SMS,
        <TbBellRinging />,
        // <img className="icon-img" src={bell} alt="" style={{ width: 20 }} />,
        () => navigate(PATHS.SMS)
      )
    );
  }
  // }

  let [first = "", second = ""] = user?.name?.split(" ");

  let avatarName =
    first?.slice(0, 1)?.toUpperCase() + second?.slice(0, 1)?.toUpperCase();
  return (
    <Layout
    // style={{
    //   minHeight: "100vh",
    // }}
    >
      <Sider
        width="250px"
        collapsedWidth="80px"
        // collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="side-menu-section">
          <img alt="" src={logo} className="logo" />
          {/* <AppButton
            title="+ New Request"
            type="primary"
            borderRadius="4px"
            height="44px"
            margin="0 0 24px"
          /> */}
          <AppButton
            title="Main Menu"
            // type="primary"
            borderRadius="4px"
            backgroundColor="var(--color-gray)"
            color="var(--color-white)"
            height="40px"
            margin="2.2rem 0"
            className="sider-logout-btn"
            onClick={() => setCollapsed(!collapsed)}
            icon={
              collapsed ? (
                <Tooltip
                  className="btn-tooltip"
                  title="Toggle Menu"
                  placement="right"
                >
                  <MenuOutlined className="menu-icon" />
                </Tooltip>
              ) : (
                <MoreOutlined className="more-icon" />
              )
            }
          />
          {/* <label className="text-base">Menu</label> */}
          {/* <Tooltip  placement="right" trigger={["hover"]}> */}
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            items={items}
          />
          {/* </Tooltip> */}
        </div>
        <div>
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            items={itemsBottom}
          />
          <Divider
            style={{ marginTop: 20, borderColor: "var(--color-slate-3)" }}
          />
          <Row gutter={8} align="middle" className="userRow px-10">
            <div className="custom-avatar">{avatarName}</div>
            <Col className="userGrid ml-5 nameSection">
              <h5 className="text-sm font-600">{user?.name}</h5>
              <p className="text-xs font-500">{user?.email}</p>
            </Col>
          </Row>
          {/* <AppButton
            icon={<LogoutOutlined />}
            title="Log out"
            type="primary"
            borderRadius="4px"
            backgroundColor="var(--color-info)"
            color="var(--color-dark)"
            margin="1.5rem 0 0"
            className="sider-logout-btn"
            onClick={() => {
              dispatch(asyncLogOutUser());
            }}
          /> */}
        </div>
      </Sider>
      <Layout className="site-layout">
        <Content>
          <div className="sticky-layout-header">
            {/* <Row justify="space-between" align="middle"> */}
            <h1 className="text-4xxl font-600">{title}</h1>
            <h4 className="text-lg font-500">{date}</h4>
            {/* </Row> */}
            {/* {location.pathname === PATHS.ROOT && (
              <p
                style={{ marginTop: "0.7rem" }}
                className="text-lg info font-600"
              >
                Welcome back!
              </p>
            )} */}
            {/* {location.pathname === PATHS.ROOT ? (
              <p
                style={{ marginTop: "0.7rem" }}
                className="text-lg info font-600"
              >
                Welcome back!
              </p>
            ) : (
              <Breadcrumb className="text-xl font-600">
                <Breadcrumb.Item>Osceola</Breadcrumb.Item>
                <Breadcrumb.Item>
                  {location.pathname.slice(1).split("-").join(" ")}
                </Breadcrumb.Item>
              </Breadcrumb>
            )} */}
          </div>
          <div className="site-layout-background" ref={siteLayoutRef}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
