import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  carriers: [],
};

const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomers: (state, { payload }) => {
      state.customers = payload;
    },
    setCarriers: (state, { payload }) => {
      state.carriers = payload;
    },
  },
});

export const { setCustomers, setCarriers } = CustomerSlice.actions;
export default CustomerSlice.reducer;
