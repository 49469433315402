import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { setActiveDrivers, setDrivers } from "../reducers/driver";

export const asyncGetAllActiveDrivers = createAsyncThunk(
  "driver/active/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "dispatcher/Drivers",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setActiveDrivers(res.data));
        // dispatch(asyncAlertSuccess("Drivers fetched successfully"));
        return { success: true };
      }

      return { success: false };
    } catch (error) {
      // dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

export const asyncGetAllDrivers = createAsyncThunk(
  "driver/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "dispatcher/allDrivers",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setDrivers(res.data));
        // dispatch(asyncAlertSuccess("Drivers fetched successfully"));
        return { success: true };
      }

      return { success: false };
    } catch (error) {
      // dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);
