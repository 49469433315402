import React, { memo, useState } from "react";
import { Form, Input, Table, Upload } from "antd";
import AppSelect from "../Select";
import { numberShouldNotBeZero, numberValidator } from "../../utils";
import AppButton from "../Button";
import { FiUpload } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";

const EditableCell = ({
  name,
  fieldReference,
  editing,
  dataIndex,
  title,
  record,
  form,
  children,
  type,
  dropDownData,
  keySet = ["key", "value"],
  isRequired = true,
  disabled = false,
  whitespace = false,
  hidden = false,
  editable,
  render,
  onChange,
  ...restProps
}) => {
  const [renderCell, setRenderCell] = useState(true);

  let isNumberField = type !== "DropDown" && type !== "text" && type !== "file";

  let customRules = [];

  isRequired &&
    customRules.push({
      required: true,
      message: "",
      ...(whitespace ? { whitespace: true } : {}),
    });

  isNumberField && customRules.push(numberValidator());
  isNumberField && customRules.push(numberShouldNotBeZero());

  let file = {};

  if (type === "file") {
    file = form.getFieldValue(fieldReference) || {};
  }

  return (
    <td {...restProps}>
      {editing ? (
        editable ? (
          <Form.Item
            name={name}
            validateFirst={true}
            style={{
              margin: 0,
            }}
            rules={customRules}
            {...(type === "file"
              ? {
                  valuePropName: "file",
                  getValueFromEvent: (e) => {
                    return e.file;
                  },
                }
              : {})}
          >
            {type === "DropDown" ? (
              <AppSelect
                hidden={hidden}
                placeholder=""
                overflow="hidden"
                maxWidth="100%"
                disabled={disabled}
                data={(dropDownData || []).map((item) => {
                  return {
                    value: item[keySet[0]],
                    label: `${item[keySet[1]]}`,
                  };
                })}
                onChange={onChange}
                margin="0 20px 0 0"
              />
            ) : type === "file" ? (
              <Upload
                disabled={file.hasOwnProperty("uid")}
                key={renderCell}
                maxCount={1}
                showUploadList={false}
                customRequest={({ onSuccess }) => {
                  onSuccess("ok");
                  setRenderCell((s) => !s);
                }}
              >
                {file.hasOwnProperty("uid") ? (
                  <p style={{ display: "flex", alignItems: "center" }}>
                    {!disabled && (
                      <RiCloseFill
                        style={{
                          cursor: "pointer",
                          fontSize: 18,
                          marginRight: 10,
                          color: "red",
                        }}
                        onClick={() => {
                          form.setFields([
                            { name: fieldReference, value: undefined },
                          ]);
                          setRenderCell((s) => !s);
                        }}
                      />
                    )}
                    {file?.name}
                  </p>
                ) : (
                  <AppButton
                    icon={<FiUpload color="#fff" className="mr-5" />}
                    title={title}
                    className="btn_dark"
                  />
                )}
              </Upload>
            ) : (
              <Input
                hidden={hidden}
                onChange={onChange}
                disabled={disabled}
                type={"text"}
                min={1}
              />
            )}
          </Form.Item>
        ) : (
          <p>{record[dataIndex]}</p>
        )
      ) : (
        children
      )}
    </td>
  );
};

export const DataTable = memo(
  ({
    form,
    dataSource,
    columns,
    loader = false,
    pageSizeCount,
    sizeChanger = true,
    paginationPosition = ["bottomRight"],
    paginationSize = "default",
    customClassName,
    paginationClass,
    showQuickJumper = false,
    isEditable = false,
    rowSelection = null,
    ...rest
  }) => {
    return (
      <Table
        className={customClassName}
        loading={loader}
        sticky
        rowSelection={rowSelection}
        // rowSelection={!Object.keys(rowSelection)?.length ? null : rowSelection}
        scroll={{ x: "max-content" }}
        components={
          isEditable
            ? {
                body: {
                  cell: (record) => {
                    return EditableCell({
                      ...record,
                      form,
                    });
                  },
                },
              }
            : null
        }
        rowClassName={() => "editable-row"}
        dataSource={dataSource}
        columns={columns}
        rowKey={(data) => {
          return (
            data?.[columns?.[0]?.dataIndex] ||
            data?.[columns?.[1]?.dataIndex]?.uid
          );
        }}
        pagination={{
          pageSize: pageSizeCount,
          position: paginationPosition,
          showSizeChanger: sizeChanger,
          size: paginationSize,
          className: paginationClass,
          showQuickJumper,
        }}
        {...rest}
      />
    );
  }
);
