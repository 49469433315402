import { Space } from "antd";
import React from "react";
import {
  RiCloseLine,
  RiDeleteBin6Line,
  RiEdit2Line,
  RiSaveLine,
} from "react-icons/ri";

export const TableType = {
  DOCUMENTS: "documents",
  LINEITEMS: "lineItems",
};

// CREATED DOCS DECS AND LINE ITEMS ASC DATA ROWS
export const Actions = ({
  type = TableType.DOCUMENTS,
  ind,
  record,
  invoiceID,
  dataLength,
  handleSave,
  remove,
  editingKey,
  setEditingKey,
  handleRowResetting = () => {},
}) => {
  const editable = editingKey === record?.ID;
  const isDisabled = editingKey && !editable;
  const hideDelete =
    invoiceID && type === TableType.LINEITEMS && ind > dataLength - 2;

  return (
    <Space
      align="flex-end"
      style={{
        justifyContent: "space-between",
        opacity: isDisabled ? "60%" : "100%",
      }}
    >
      {record?.ID && !editable ? (
        <RiEdit2Line
          disabled={isDisabled}
          style={{
            cursor: isDisabled ? "not-allowed" : "pointer",
            fontSize: 18,
          }}
          onClick={() => {
            !isDisabled && setEditingKey(record?.ID);
          }}
        />
      ) : (
        <RiSaveLine
          style={{ cursor: "pointer", fontSize: 18 }}
          onClick={() => {
            !isDisabled && handleSave(ind);
          }}
        />
      )}

      {!editable &&
        !hideDelete &&
        ind !== (type === TableType.DOCUMENTS ? 0 : dataLength - 1) && (
          <RiDeleteBin6Line
            style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              fontSize: 18,
            }}
            onClick={() => {
              !isDisabled && remove(ind);
            }}
          />
        )}

      {editable && (
        <RiCloseLine
          style={{ cursor: "pointer", fontSize: 18 }}
          onClick={() => {
            handleRowResetting?.(ind, record);
            setEditingKey("");
          }}
        />
      )}
    </Space>
  );
};
