import { Checkbox, Form, Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../Components/Button";
import { PATHS } from "../../Components/Navigation";
import { asyncRegisterUser } from "../../store/actions/auth";
import styles from "./style.module.scss";

const SignUp = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const res = await dispatch(asyncRegisterUser(values)).unwrap();
    if (res.success) {
      form.resetFields();
      navigate(PATHS.LOGIN);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      style={{ marginTop: 50 }}
      name="basic"
      layout="vertical"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please input your first name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please input your last name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            required: true,
            whitespace: true,
            message: "Please input your email!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>
      <Form.Item
        label="Select your type"
        name="role"
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please check mark the box!",
          },
        ]}
      >
        <Checkbox.Group
          onChange={(e) => form.setFieldsValue({ role: e.pop() })}
        >
          <Checkbox value="13">Warehouse</Checkbox>
          <br />
          <Checkbox value="14">Dispatcher</Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          span: 24,
        }}
      >
        <AppButton
          type="primary"
          size="large"
          htmlType="submit"
          style={{ marginTop: 50 }}
          title="Sign up your account"
        />
      </Form.Item>
      <Form.Item>
        <p onClick={() => navigate(PATHS.LOGIN)} className={styles.link}>
          Already have an account, <span> Sign in now</span>
        </p>
      </Form.Item>
    </Form>
  );
};

export default SignUp;
