import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import authReducer from "./reducers/auth";
import commonReducer from "./reducers/common";
import customerReducer from "./reducers/customer";
import driverReducer from "./reducers/driver";
import notificationReducer from "./reducers/notification";
import requestReducer from "./reducers/request";
import trailerReducer from "./reducers/trailer";
import employeeReducer from "./reducers/employee";
import userReducer from "./reducers/user";
import smsUserReducer from "./reducers/sms";
import vehicleReducer from "./reducers/vehicle";
import accountReducer from "./reducers/account";

import { persistReducer, persistStore } from "redux-persist";

const allreducers = combineReducers({
  common: commonReducer,
  auth: authReducer,
  user: userReducer,
  smsUser: smsUserReducer,
  request: requestReducer,
  customer: customerReducer,
  driver: driverReducer,
  trailer: trailerReducer,
  employee: employeeReducer,
  notification: notificationReducer,
  vehicle: vehicleReducer,
  account: accountReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth"],
  // whitelist: ["auth", "notification"],
};

const persistedReducer = persistReducer(persistConfig, allreducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      //  {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
});

export const persistor = persistStore(store);
