import { MinusOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../../Components/Button";
import { SearchInput } from "../../../Components/Filters/SearchInput";
import { DataTable } from "../../../Components/Table";

import { SearchDate } from "../../../Components/Filters/SeachDate";
import AppModal from "../../../Components/Modal";
import Timesheet from "../../../Components/TimeSheet/index3";
import {
  asyncGetTimeSheet,
  asyncGetTimeSheetByUser,
} from "../../../store/actions/employee";
import {
  checkValue,
  dateFormats,
  endOfLastMonth,
  endOfLastWeek,
  endOfMonth,
  endOfWeek,
  formatDate,
  formatTableDuration,
  moment,
  startOfLastMonth,
  startOfLastWeek,
  startOfMonth,
  startOfWeek,
} from "../../../utils";
import "./style.scss";

const EmployeeManagment = () => {
  const dispatch = useDispatch();
  const [userWiseTimeSheet, setUserWiseTimeSheet] = useState([]);
  const { timeSheet } = useSelector((s) => s.employee);
  const [filters, setFilters] = useState({});
  // const [loader, setLoader] = useState(false);
  const [dataRow, setDataRow] = useState({});
  const [range, setRange] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [open, setOpen] = useState(false);

  const localAdminLoading = useSelector((s) => s.common.localAdminLoading);

  useEffect(() => {
    const loadTimeSheet = async () => {
      // setLoader(true);
      await dispatch(asyncGetTimeSheet()).unwrap();
      // setLoader(false);
    };

    loadTimeSheet();
  }, [dispatch]);

  const loadDataByID = async (ID, props) => {
    if (localAdminLoading) return;
    // setLoader(true);

    let modifiedRange = {
      from: props?.from ?? startOfWeek,
      to: props?.to ?? endOfWeek,
      range: props?.range,
    };

    let res = await dispatch(
      asyncGetTimeSheetByUser({
        ID,
        range: {
          from: modifiedRange.from.format(),
          to: modifiedRange.to.format(),
        },
      })
    ).unwrap();

    if (res.success) {
      setUserWiseTimeSheet(res.data);

      if (!modifiedRange?.range) {
        let dateDiff = modifiedRange.to.diff(modifiedRange.from);
        modifiedRange["range"] = Math.floor(moment.duration(dateDiff).asDays());
      }

      setRange(modifiedRange?.range);
      setStartDate(modifiedRange.from);
    }
    // setLoader(false);
  };

  let tableData =
    timeSheet?.filter(({ date, driver }) => {
      let SF = filters;
      let row = [];

      let militaryDate = !date
        ? null
        : formatDate(date, dateFormats["MM/DD/YY"]);

      let militaryDate1 = !SF?.date
        ? formatDate(moment(), dateFormats["MM/DD/YY"])
        : formatDate(SF?.date, dateFormats["MM/DD/YY"]);

      row.push(checkValue(SF?.driver, driver?.name));
      row.push(checkValue(militaryDate1, militaryDate, true));

      if (row.every(Boolean)) {
        return true;
      } else {
        return false;
      }
    }) || [];

  const defaultColumns = [
    {
      title: "ID #",
      dataIndex: "id",
      width: 100,
    },
    {
      title: "Driver ID",
      dataIndex: "driverId",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "DriverName",
      render: (_, { driver }) => {
        return driver?.name;
      },
      width: "25%",
    },
    {
      title: "Clock in",
      dataIndex: "date",
      render: (_, { start }) => (
        <p style={{ color: "var(--color-primary)" }}>
          {start ? formatDate(start) : "N/A"}
        </p>
      ),
    },
    {
      title: "Clock out",
      dataIndex: "date",
      render: (_, { end }) => (
        <p style={{ color: "var(--color-success-dark)" }}>
          {end ? formatDate(end) : "N/A"}
        </p>
      ),
    },
    {
      title: "Total time",
      dataIndex: "date",
      color: "#000",
      render: (_, { totalTime }) => {
        return (
          <p style={{ color: "var(--color-dark)" }}>
            {formatTableDuration(totalTime * 1000) ?? "N/A"}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (_, action) => {
        return (
          <Row
            className="users-tbl-action-row"
            align="middle"
            justify="space-evenly"
          >
            <AppButton
              onClick={async () => {
                await loadDataByID(action.driver.userId, {
                  from: filters?.date,
                  to: filters?.date,
                });
                setOpen(true);
                setDataRow(action);
              }}
              type="primary"
              size="small"
              width="max-content"
              backgroundColor="var(--color-primary-light)"
              className="mr-10"
              title={"Detail"}
            />
          </Row>
        );
      },
    },
  ];

  const debounceSearch = useRef(
    debounce(({ key, value }) => {
      setFilters((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    }, 300)
  ).current;

  const handleFilterChange = (props) => {
    debounceSearch(props);
  };

  const resetFormModal = () => {
    setOpen(false);
    setDataRow({});
    setRange(null);
    setStartDate(null);
    setUserWiseTimeSheet([]);
  };

  let currentWeek = `${startOfWeek.format(
    dateFormats["MM/DD/YY"]
  )} - ${endOfWeek.format(dateFormats["MM/DD/YY"])}`;
  let lastWeek = `${startOfLastWeek.format(
    dateFormats["MM/DD/YY"]
  )} - ${endOfLastWeek.format(dateFormats["MM/DD/YY"])}`;
  let currentMonth = `${startOfMonth.format(
    dateFormats["MM/DD/YY"]
  )} - ${endOfMonth.format(dateFormats["MM/DD/YY"])}`;
  let lastMonth = `${startOfLastMonth.format(
    dateFormats["MM/DD/YY"]
  )} - ${endOfLastMonth.format(dateFormats["MM/DD/YY"])}`;

  const timeData = [
    { label: `Current week (${currentWeek})`, value: currentWeek },
    { label: `Last week (${lastWeek})`, value: lastWeek },
    { label: `Current month (${currentMonth})`, value: currentMonth },
    { label: `Last month (${lastMonth})`, value: lastMonth },
    { label: `Custom date range`, value: "custom" },
  ];

  const timeObj = {
    [currentWeek]: {
      from: startOfWeek,
      to: endOfWeek,
    },
    [lastWeek]: {
      from: startOfLastWeek,
      to: endOfLastWeek,
    },
    [currentMonth]: {
      from: startOfMonth,
      to: endOfMonth,
    },
    [lastMonth]: {
      from: startOfLastMonth,
      to: endOfLastMonth,
    },
  };

  return (
    <>
      <div className="head">
        <Row justify="space-between" align="bottom" className="my-15">
          <Col span={16}>
            <Row gutter={16}>
              <Col lg={12} xl={10} xxl={6}>
                <SearchInput
                  title="Name"
                  dataIndex="driver"
                  placeholder="Search by name"
                  onChange={handleFilterChange}
                />
              </Col>
              <Col lg={12} xl={10} xxl={6}>
                <SearchDate
                  title="Date"
                  dataIndex="date"
                  value={filters?.date ?? moment()}
                  onChange={handleFilterChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <DataTable
        loader={localAdminLoading}
        dataSource={tableData}
        columns={defaultColumns}
      />

      {open && (
        <AppModal
          title="Time Sheet"
          minWidth={"90%"}
          open={open}
          onCancel={resetFormModal}
        >
          <Timesheet
            range={range}
            timeObj={timeObj}
            dataRow={dataRow}
            timeData={timeData}
            startDate={startDate}
            loadDataByID={loadDataByID}
            timeSheet={userWiseTimeSheet}
            parentDate={filters?.date}
          />

          <Row justify="end">
            <AppButton
              icon={<MinusOutlined style={{ fontSize: 18 }} />}
              title="Close"
              width="120px"
              margin={"20px 0px 0px"}
              height="max-content"
              fontSize="16px"
              className="btn_red"
              onClick={resetFormModal}
            />
          </Row>
        </AppModal>
      )}
    </>
  );
};

export default EmployeeManagment;
