import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected: {},
  // onGoing: [],
  // completed: [],
  dataSource: {
    PrePlan: [],
    onGoing: [],
    Requested: [],
    Dispatched: [],
    InProgress: [],
    Completed: [],
  },
  moveTypes: [],
};

const RequestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    // setOnGoing: (state, { payload }) => {
    //   state.onGoing = payload;
    // },
    // setCompleted: (state, { payload }) => {
    //   state.completed = payload;
    // },
    setDataSource: (state, { payload }) => {
      state.dataSource.onGoing = payload.onGoing || [];
      state.dataSource.PrePlan = payload.PrePlan || [];
      state.dataSource.Requested = payload.Requested || [];
      state.dataSource.Completed = payload.Completed || [];
      state.dataSource.Dispatched = payload.Dispatched || [];
      state.dataSource.InProgress = payload.InProgress || [];
    },
    setMoveTypes: (state, { payload }) => {
      state.moveTypes = payload;
    },
  },
});

export const {
  setDataSource,
  // setCompleted,
  // setOnGoing,
  setSelected,
  setMoveTypes,
} = RequestSlice.actions;
export default RequestSlice.reducer;
