import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  documentTypes: [],
  chartOfAccounts: [],
};

const AccountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setServices: (state, { payload }) => {
      state.services = payload;
    },
    setDocumentTypes: (state, { payload }) => {
      state.documentTypes = payload;
    },
    setChartOfAccounts: (state, { payload }) => {
      state.chartOfAccounts = payload;
    },
  },
});

export const { setServices, setDocumentTypes, setChartOfAccounts } =
  AccountSlice.actions;
export default AccountSlice.reducer;
