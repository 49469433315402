import { Row } from "antd";
import React from "react";
import AppModal from ".";
import AppButton from "../Button";

export const Delete = ({ open, onCancel, onClick }) => {
  return (
    <AppModal open={open}>
      <p className="text-xl font-500 primary-dark mb-15 text-center">
        Are you sure you want to delete!
      </p>
      <Row justify="center" align="middle">
        <AppButton
          title="Yes"
          width="100px"
          fontSize="16px"
          margin="10px 10px 0"
          className="btn_green"
          onClick={onClick}
        />
        <AppButton
          title="No"
          width="100px"
          fontSize="16px"
          margin="10px 10px 0"
          className="btn_red"
          onClick={onCancel}
        />
      </Row>
    </AppModal>
  );
};
