import React from "react";
import { Input } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
// import styles from "./style.module.scss"

// const AppInput = ({label, placeholder, ...props}) => {
//   return (
//     <div className={styles.appInput}>
//       <p>{label}</p>
//       <Input placeholder={placeholder} {...props} />
//     </div>
//   );
// }

export const AppInput = ({
  title = "",
  placeholder = "Search",
  prefix = false,
  onChange = () => {},
  value = "",
  margin,
  width = "100%",
  size = "large",
  allowClear,
  className,
  type = "text",
  style,
  ...props
}) => {
  return (
    <div style={{ margin, width }}>
      <p className="text-xs dark-80 font-500">{title}</p>
      {type !== "password" ? (
        <Input
        style={style}
          {...props}
          size={size}
          value={value}
          className={className}
          allowClear={
            allowClear
              ? {
                  clearIcon: (
                    <CloseOutlined
                      style={{
                        color: "#0055CC",
                      }}
                    />
                  ),
                }
              : ""
          }
          placeholder={placeholder}
          onChange={onChange}
          prefix={
            prefix ? <SearchOutlined style={{ color: " #0055CC" }} /> : false
          }
        />
      ) : (
        <Input.Password
          {...props}
          size={size}
          value={value}
          className={className}
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default AppInput;
