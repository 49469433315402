import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { getUtc } from "../../utils";
import { setTimeSheet } from "../reducers/employee";
import { setLocalAdminLoading } from "../reducers/common";

export const asyncGetTimeSheet = createAsyncThunk(
  "employee/timeSheet",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "employeeTimeSheet",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setTimeSheet(res.data));
        // dispatch(asyncAlertSuccess("Drivers fetched successfully"));
        return { success: true };
      }

      return { success: false };
    } catch (error) {
      // dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncGetTimeSheetByUser = createAsyncThunk(
  "employee/timeSheet/id",
  async ({ ID, range: { from, to } }, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const { token } = getState().auth;
      const res = await callApi({
        path: `employeeTimeSheet/${ID}?from=${getUtc(
          from
        ).format()}&to=${getUtc(to).format()}`,
        method: "GET",
        token,
      });

      if (res.success) {
        return { success: true, data: res.data };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);
