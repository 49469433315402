import { message } from "antd";
import AppButton from "../Button";

let globalMsgKey = "";
let globalNotificationKey = "";

export const Success = (msg) => {
  if (globalMsgKey) {
    message.destroy(globalMsgKey);
  }

  globalMsgKey = msg;

  message
    .success({
      key: msg,
      duration: 3,
      content: (
        <div style={{ textAlign: "left" }}>
          <p className="text-base dark">Success</p>
          <p className="text-base success-dark">{msg}</p>
        </div>
      ),
      className: "msg-success",
      style: {
        marginTop: "2vh",
      },
    })
    .then(() => {
      globalMsgKey = "";
    });
};

export const Error = (msg) => {
  if (globalMsgKey) {
    message.destroy(globalMsgKey);
  }

  globalMsgKey = msg;

  message
    .error({
      key: msg,
      duration: 3,
      content: (
        <div style={{ textAlign: "left" }}>
          <p className="text-base dark">Error</p>
          <p className="text-base danger">{msg}</p>
        </div>
      ),
      className: "msg-error",
      style: {
        marginTop: "2vh",
      },
    })
    .then(() => {
      globalMsgKey = "";
    });
};

export const Notification = ({ msg, record, onTrack }) => {
  if (globalNotificationKey) {
    message.destroy(globalNotificationKey);
  }

  const onReject = () => {
    if (globalNotificationKey) {
      message.destroy(globalNotificationKey);
    }
  };

  globalNotificationKey = msg;

  message
    .open({
      key: msg,
      duration: 6,
      content: (
        <div>
          <div style={{ textAlign: "left" }}>
            <p className="text-base dark">Notification</p>
            <p className="text-base success-dark">{msg}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: 300,
              marginTop: 20,
            }}
          >
            <AppButton
              type="primary"
              margin="0 10px 0 0"
              height="30px"
              title="Disregard"
              onClick={onReject}
            />
            <AppButton
              title="Track"
              type="primary"
              height="30px"
              margin="0 0 0 10px"
              onClick={() => {
                message.destroy(globalNotificationKey);
                onTrack(record);
              }}
            />
          </div>
        </div>
      ),
      className: "msg-success",
      style: {
        position: "absolute",
        right: 10,
        bottom: "-97vh",
      },
    })
    .then(() => {
      globalNotificationKey = "";
    });
};
