import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationsCount: 0,
  notifications: [],
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
    setNotificationsCount: (state, { payload }) => {
      state.notificationsCount = payload;
    },
  },
});

export const { setNotifications, setNotificationsCount } =
  NotificationSlice.actions;
export default NotificationSlice.reducer;
