import { DatePicker } from "antd";
import { dateFormats } from "../../utils";

export const SearchDate = ({
  title = "",
  value = "",
  tableName = "",
  dataIndex = "",
  onChange = () => {},
  ...rest
}) => {
  // let key = title.replace(" ", "").toLowerCase();

  return (
    <>
      <p className="text-xs dark-80 font-500">{title}</p>
      <DatePicker
        {...rest}
        placeholder="Select"
        key={tableName + "_" + dataIndex} // + "_" + value}
        format={dateFormats["MM/DD/YY"]}
        size="large"
        value={value}
        defaultValue={!value ? null : value}
        onChange={(value) => {
          onChange({
            tableName,
            key: dataIndex,
            value: !value ? "" : value,
          });
        }}
        style={{ width: "100%", borderRadius: 8 }}
      />
    </>
  );
};
