import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timeSheet: [],
};

const EmployeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setTimeSheet: (state, { payload }) => {
      state.timeSheet = payload;
    },
  },
});

export const { setTimeSheet } = EmployeeSlice.actions;
export default EmployeeSlice.reducer;
