import React from "react";
import { Col, Row } from "antd";
import { UpcomingCard } from "../../Components/Card";
import styles from "./style.module.scss"


const Dashboard = () => {
  return (
    <div className={styles.dashboard}>
      <UpcomingCard/>
    </div>
  );
};

export default Dashboard;
