import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "../../Pages/Dashboard";
import Login from "../../Pages/Login";
// import Notification from "../../Pages/Notification";
// import Settings from "../../Pages/Settings";
import SignUp from "../../Pages/SignUp";
import YardMoveRequestClone from "../../Pages/YardMoveClone";
import AuthLayout from "../Layout/AuthLayout";
import AppLayout from "../Layout/Layout";

// import AddCustomer from "../../Pages/Customer";
// import AddTrailer from "../../Pages/Trailer";

import Carrier from "../../Pages/Admin/Carrier";
import Customer from "../../Pages/Admin/Customer";
import Trailer from "../../Pages/Admin/Trailer";
import Users from "../../Pages/Admin/Users";
import Sms from "../../Pages/Admin/Sms";
import EmployeeManagment from "../../Pages/Admin/EmployeeManagment";
import YardMoveNew from "../../Pages/YardMoveClone/yardMoveNew";
import AdminChat from "../../Pages/Admin/Chat";
// import AppTabs from "../Tabs";
import AccountingBook from "../../Pages/AccountingBook";
// import Landing from "../../Pages/Landing";

export let PATHS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  ROOT: "/",
  YARDMOVEREQUESTCLONE: "/yard-move",
  YARDMOVENEW: "/yard-move-drawer",
  NOTIFICATION: "/notification",
  SETTING: "/setting",
  ADMIN: "/admin",
  USERS: "/admin/users",
  CARRIER: "/admin/carrier",
  CUSTOMER: "/admin/customer",
  TRAILER: "/admin/trailer",
  SMS: "/admin/sms",
  CHAT: "/admin/chat",
  EmployeeManagment: "/admin/time-sheet",
  EmployeeManagmentNew: "/admin/time-sheet-new",
  DETAILS: "/yard-move/:id/*",
};

export let Title = {
  [PATHS.LOGIN]: "Login",
  [PATHS.SIGNUP]: "Sign Up",
  [PATHS.ROOT]: "Hello",
  [PATHS.ADMIN]: "Hello",
  [PATHS.YARDMOVEREQUESTCLONE]: "Yard Move",
  [PATHS.YARDMOVENEW]: "Yard Move Drawer",
  [PATHS.NOTIFICATION]: "Notifications",
  [PATHS.SETTING]: "Settings",
  [PATHS.USERS]: "Users",
  [PATHS.CARRIER]: "Carrier",
  [PATHS.CUSTOMER]: "Customer",
  [PATHS.TRAILER]: "Trailer",
  [PATHS.SMS]: "Sms",
  [PATHS.CHAT]: "Chat",
  [PATHS.EmployeeManagment]: "Time Sheet",
  [PATHS.EmployeeManagmentNew]: "Time Sheet New",
};

const AppRouting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((s) => s.auth);
  const { user } = useSelector((s) => s.user);

  const siteLayoutRef = useRef(null);
  const tblGridRef = useRef(null);

  useEffect(() => {
    if (location?.state?.yardMoveId) {
      PATHS = {
        ...PATHS,
        INVOICE: `/yard-move/${location?.state?.yardMoveId}/invoice`,
        PAYMENT: `/yard-move/${location?.state?.yardMoveId}/payment`,
        TRANSACTION: `/yard-move/${location?.state?.yardMoveId}/transactions`,
        DOCUMENT: `/yard-move/${location?.state?.yardMoveId}/documents`,
      };

      Title = {
        ...Title,
        [`/yard-move/${location?.state?.yardMoveId}`]: "Yard Move Detail",
        [PATHS.INVOICE]: "Yard Move Invoice",
        [PATHS.PAYMENT]: "Yard Move Payment",
        [PATHS.TRANSACTION]: "Yard Move Transactions",
        [PATHS.DOCUMENT]: "Yard Move Documents",
      };
    }
  }, [location?.state?.yardMoveId]);

  let title = Title[location.pathname];

  if (+user?.role === 15) {
    PATHS.ROOT = PATHS.ADMIN;
  }

  if (location.pathname === PATHS.ROOT) {
    title += ` ${user.name}`;
  }

  return (
    <Routes>
      {/* <Route path="/" element={<Landing />} /> */}
      {!token ? (
        <Route path="/" element={<AuthLayout />}>
          <Route path={PATHS.LOGIN} element={<Login />} />
          <Route path={PATHS.SIGNUP} element={<SignUp />} />
          <Route path="/" element={<Navigate to={PATHS.LOGIN} />} />
          {location.pathname !== "/companyPage" && (
            <Route path="*" element={<Navigate to={PATHS.LOGIN} />} />
          )}
        </Route>
      ) : (
        <>
          <Route
            path="/"
            element={
              <AppLayout
                siteLayoutRef={siteLayoutRef}
                title={title}
                navigate={navigate}
              />
            }
          >
            {+user?.role !== 15 ? (
              <>
                <Route index element={<Dashboard />} />
                {/* <Route path={PATHS.NOTIFICATION} element={<Notification />} /> */}
                {/* <Route path={PATHS.SETTING} element={<Settings />} /> */}
                {/* <Route path={PATHS.ADDCUSTOMER} element={<AddCustomer />} /> */}
                {/* <Route path={PATHS.ADDTRAILER} element={<AddTrailer />} /> */}
                <Route
                  path={PATHS.YARDMOVEREQUESTCLONE}
                  element={<YardMoveRequestClone tblGridRef={tblGridRef} />}
                />
                <Route path={PATHS.YARDMOVENEW} element={<YardMoveNew />} />
                {/* <Route path={"/tabs"} element={<AccountingBook />} /> */}
              </>
            ) : (
              <>
                <Route path={PATHS.ROOT} index element={<Dashboard />} />
                <Route path={PATHS.USERS} element={<Users />} />
                <Route path={PATHS.CARRIER} element={<Carrier />} />
                <Route path={PATHS.CUSTOMER} element={<Customer />} />
                <Route path={PATHS.TRAILER} element={<Trailer />} />
                <Route
                  path={PATHS.EmployeeManagment}
                  element={<EmployeeManagment />}
                />
                <Route
                  path={PATHS.EmployeeManagmentNew}
                  element={<EmployeeManagment />}
                />
                <Route path={PATHS.SMS} element={<Sms />} />
                <Route path={PATHS.CHAT} element={<AdminChat />} />

                <Route path="/" element={<Navigate to={PATHS.ROOT} />} />
              </>
            )}
            <Route path={PATHS.DETAILS} element={<AccountingBook />} />
            <Route path="*" element={<Navigate to={PATHS.ROOT} />} />
          </Route>
        </>
      )}
    </Routes>
  );
};
export default AppRouting;
