import { Row, Space } from "antd";
import clx from "classnames";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
// import { HiMinusSm } from "react-icons/hi";
// import { useSelector } from "react-redux";
import arhiveLoad from "../../Assets/icons/arhiveLoad.svg";
// import Print from "../../Assets/icons/Print.svg";
import { dateFormats, moment, toCurreny } from "../../utils";
import AppButton from "../Button";
import { NotFound } from "../NotFound";
import { DataTable } from "../Table";
import styles from "./style.module.scss";
// import { OverLay } from "../NotFound";
// import { CSVLink } from "react-csv";
// import { utils, writeFile } from "xlsx";
import { CSVLink } from "react-csv";
// import account from "../../store/reducers/account";
// import { Overlay } from "antd/lib/popconfirm/PurePanel";

function Transaction({ record, loader, localLoading }) {
  // const { selected: record } = useSelector((s) => s.request);

  const tableRef = useRef(null);

  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const [iGeneralJournalData, setIGeneralJournalData] = useState([]);
  const [pGeneralJournalData, setPGeneralJournalData] = useState([]);

  const [totalPaymentDebit, setTotalPaymentDebit] = useState(0);
  const [totalPaymentCredit, setTotalPaymentCredit] = useState(0);

  // const [iGeneralLoader, setIGeneralLoader] = useState(false);
  // const [pGeneralLoader, setPGeneralLoader] = useState(false);

  const generalJournalColumns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "TransactionID",
      },
      {
        title: "Date",
        dataIndex: "Date",
        width: "7%",
      },
      {
        title: "Name",
        dataIndex: "customer",
        render: (customer) => {
          return customer?.Customer_Name ?? customer?.name;
        },
        width: "15%",
      },
      {
        title: "Transaction Type",
        dataIndex: "JournalEntryType",
        width: "16%",
        render: (JournalEntryType) => {
          return <p className="primary font-600">{JournalEntryType}</p>;
        },
      },
      {
        title: "Account",
        dataIndex: "AccountID",
        width: "18%",
        render: (_, { account }) => {
          return <p className="dark-80 font-600">{account?.AccountName}</p>;
        },
      },
      {
        title: "Description",
        dataIndex: "Description",
        width: "18%",
        render: (Description) => {
          return <p className="info font-600">{Description}</p>;
        },
      },
      {
        title: "Debit",
        dataIndex: "DebitAmount",
        width: "12%",
        render: (DebitAmount) => {
          return <p className="danger font-600">${DebitAmount}</p>;
        },
      },
      {
        title: "Credit",
        dataIndex: "CreditAmount",
        width: "12%",
        render: (CreditAmount) => {
          return <p className="success-dark font-600">${CreditAmount}</p>;
        },
      },
    ],
    []
  );

  // let iGeneralJournalData = [];
  // let pGeneralJournalData = [];

  // let totalDebit = 0;
  // let totalCredit = 0;

  // let totalPaymentDebit = 0;
  // let totalPaymentCredit = 0;

  useEffect(() => {
    setTimeout(() => {
      // setIGeneralLoader(true);
      let totalDebitLocal = 0;
      let totalCreditLocal = 0;
      let iGeneralJournalDataLocal = [];

      record?.invoice?.generalJournal?.forEach(
        ({ generalJournalLineItems, ...generalJournal }) => {
          if (generalJournalLineItems) {
            generalJournalLineItems.forEach((item, ind) => {
              let resetItems = generalJournal;
              resetItems.customer = {
                ...record?.customer,
                name: record?.customer?.name ?? "N/A",
              };

              if (ind % 2 !== 0) {
                let {
                  TransactionID,
                  Date,
                  Customer_Name,
                  JournalEntryType,
                  customer,
                  ...rest
                } = generalJournal;
                resetItems = rest;
              }

              totalCreditLocal += +item.CreditAmount;
              totalDebitLocal += +item.DebitAmount;

              iGeneralJournalDataLocal.push({ ...resetItems, ...item });
            });
          }
        }
      );

      setIGeneralJournalData((_) => iGeneralJournalDataLocal);
      setTotalDebit((_) => totalDebitLocal);
      setTotalCredit((_) => totalCreditLocal);
      // setIGeneralLoader(false);
    }, 1000);
  }, [record?.invoice?.generalJournal]);

  useEffect(() => {
    setTimeout(() => {
      // setPGeneralLoader(true);
      let totalPaymentDebitLocal = 0;
      let totalPaymentCreditLocal = 0;
      let pGeneralJournalDataLocal = [];

      record?.invoice?.paymentReceipts?.forEach(
        ({ customer, generalJournal }) => {
          if (generalJournal?.generalJournalLineItems?.length) {
            generalJournal?.generalJournalLineItems.forEach((item, ind) => {
              let restGeneralJournal = {};

              if (ind % 2 === 0) {
                restGeneralJournal = {
                  Date: generalJournal.Date,
                  JournalEntryType: generalJournal.JournalEntryType,
                  TransactionID: generalJournal.TransactionID,
                  customer: {
                    ...customer,
                    Customer_Name: customer?.Customer_Name ?? "N/A",
                  },
                };
              }

              totalPaymentCreditLocal += +item.CreditAmount;
              totalPaymentDebitLocal += +item.DebitAmount;

              pGeneralJournalDataLocal.push({
                ...item,
                ...restGeneralJournal,
              });
            });
          }
        }
      );

      setPGeneralJournalData((_) => pGeneralJournalDataLocal);
      setTotalPaymentDebit((_) => totalPaymentDebitLocal);
      setTotalPaymentCredit((_) => totalPaymentCreditLocal);
      // setPGeneralLoader(false);
    }, 1000);
  }, [record?.invoice?.paymentReceipts]);

  // const csvData1 = [
  //   generalJournalColumns.map((column) => column.title), // Add header row
  //   ...iGeneralJournalData.map((row) =>
  //     generalJournalColumns.map((column) => {
  //       if (column.dataIndex === "AccountID") {
  //         return row.account.AccountName ?? "";
  //       } else if (column.dataIndex === "customer") {
  //         return row?.customer?.Customer_Name ?? "";
  //       } else if (
  //         column.dataIndex === "DebitAmount" ||
  //         column.dataIndex === "CreditAmount"
  //       ) {
  //         return `$${row[column.dataIndex] || 0}`;
  //       } else {
  //         return row[column.dataIndex] || "";
  //       }
  //     })
  //   ),
  // ];

  // const handleExport = () => {
  //   if (localLoading || loader) return;

  //   const workbook = utils.book_new();
  //   const worksheet = utils.json_to_sheet(pGeneralJournalData, {
  //     header: generalJournalColumns.map((column) => column.title),
  //   });

  //   // Add styling to the worksheet
  //   worksheet["!cols"] = generalJournalColumns.map(() => ({ width: 20 }));
  //   // worksheet["!rows"] = [
  //   //   { hpx: 40 },
  //   //   ...pGeneralJournalData.map(() => ({ hpx: 30 })),
  //   // ];

  //   utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   writeFile(workbook, "table-data.xlsx");
  // };

  const csvData = [
    generalJournalColumns.map((column) => column.title), // Add header row
    ...pGeneralJournalData.map((row) =>
      generalJournalColumns.map((column) => {
        if (column.dataIndex === "AccountID") {
          return row.account.AccountName ?? "";
        } else if (column.dataIndex === "customer") {
          return row?.customer?.Customer_Name ?? row?.customer?.name ?? "";
        } else if (
          column.dataIndex === "DebitAmount" ||
          column.dataIndex === "CreditAmount"
        ) {
          return `$${row[column.dataIndex] || 0}`;
        } else {
          return row[column.dataIndex] || "";
        }
      })
    ),
  ];

  if (!record?.invoice?.paymentReceipts?.length && !loader) {
    return (
      <NotFound
        title="Oh, Transactions Not Found"
        desc="It seems like transactions are not created yet"
      />
    );
  }

  return (
    <div className={styles.account}>
      <div>
        <Space className="mb-15">
          <h3 className="text-3xl font-600 mr-10">Sales Journal Entry</h3>
          {/* <Tag color="#047857" style={{ borderRadius: 12 }}>
            PAID
          </Tag> */}
        </Space>
        <div>
          <DataTable
            loader={loader || localLoading}
            // loader={!iGeneralJournalData?.length ? true : false}
            columns={generalJournalColumns}
            dataSource={iGeneralJournalData ?? []}
            pagination={false}
          />
          <Row justify="end">
            <div className={clx(styles.billHeighlight, styles.invoiceHeigh)}>
              <Row className={styles.space}>
                <div
                  style={{
                    width: "42%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "auto",
                  }}
                >
                  <h3 className="text-sm font-600 danger">
                    {toCurreny(totalDebit)}
                  </h3>
                  <h3 className="text-sm font-600 success-dark">
                    {toCurreny(totalCredit)}
                  </h3>
                </div>
              </Row>
              <Space className={clx(styles.space, styles.spaceDark)}>
                <h5 className="text-xs font-600 white">Balance</h5>
                <h3 className="text-base font-700 white">
                  {toCurreny(Math.abs(totalDebit - totalCredit))}
                </h3>
              </Space>
            </div>
          </Row>
        </div>
        <div>
          <h3 className="text-3xl font-600 mt-30 mb-10">
            Payment Receipt Journal Entry
          </h3>
          <DataTable
            ref={tableRef}
            loader={loader || localLoading}
            // loader={!pGeneralJournalData?.length ? true : false}
            columns={generalJournalColumns}
            dataSource={pGeneralJournalData ?? []}
            pagination={true}
          />
          <Row justify="end">
            <div className={clx(styles.billHeighlight, styles.invoiceHeigh)}>
              <Row className={styles.space}>
                <div
                  style={{
                    width: "42%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "auto",
                  }}
                >
                  <h3 className="text-sm font-600 danger">
                    {toCurreny(totalPaymentDebit)}
                  </h3>
                  <h3 className="text-sm font-600 success-dark">
                    {toCurreny(totalPaymentCredit)}
                  </h3>
                </div>
              </Row>
              <Space className={clx(styles.space, styles.spaceDark)}>
                <h5 className="text-xs font-600 white">Balance</h5>
                <h3 className="text-base font-700 white">
                  {toCurreny(Math.abs(totalPaymentDebit - totalPaymentCredit))}
                </h3>
              </Space>
            </div>
          </Row>
        </div>
      </div>
      {/* <Row justify="space-between" className="mt-25">
        <AppButton
          icon={<HiMinusSm className="mr-5" />}
          title="Discard"
          className="btn_outlined"
        />
        <Space>
          <AppButton
            icon={
              <img src={arhiveLoad} alt="img" className="mr-5" width="18px" />
            }
            title="Export CSV"
            className="btn_dark"
          />
          <AppButton
            icon={<img src={Print} alt="img" className="mr-5" width="20px" />}
            title="Print Histiry"
            className="btn_dark"
          />
        </Space>
      </Row> */}
      <Space className={styles.invoiceTopBtns}>
        <CSVLink
          disabled={localLoading || loader}
          data={csvData}
          filename={"Journal Entry " + moment().format(dateFormats["MM/DD/YY"])}
        >
          <AppButton
            // onClick={handleExport}
            icon={
              <img src={arhiveLoad} alt="img" className="mr-5" width="18px" />
            }
            title="Export CSV"
            className="btn_dark"
          />
        </CSVLink>
        {/* <AppButton
          icon={<img src={Print} alt="img" className="mr-5" width="20px" />}
          title="Print Histiry"
          className="btn_dark"
        /> */}
      </Space>

      {/* {(iGeneralLoader || pGeneralLoader) && <OverLay />} */}
    </div>
  );
}

export default memo(Transaction);
