import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { statuses } from "../../utils";
import {
  // setCompleted,
  setDataSource,
  setMoveTypes,
  // setOnGoing,
} from "../reducers/request";
import { asyncAlertError } from "./common";
import { setLocalLoading } from "../reducers/common";

export const asyncCreateRequest = createAsyncThunk(
  "request/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));
      const token = getState().auth.token;

      const res = await callApi({
        path: "coordinator/YardMoveRequest",
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncGetCompletedRequest = createAsyncThunk(
  "request/requestList",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "coordinator/YardMoveRequest",
        method: "GET",
        token,
      });

      if (res.success) {
        // dispatch(setCompleted(res.data));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

export const asyncGetAllRequests = createAsyncThunk(
  "request/list",
  async (_, { dispatch, getState }) => {
    try {
      const { token } = getState().auth;

      let path = "coordinator/AllRecords";

      const res = await callApi({
        path,
        method: "GET",
        token,
      });

      let dataSource = [];
      let onGoingData = [];

      if (res.success) {
        dataSource = res.data.reduce((acc, curr) => {
          if (curr.YardMoveStatus !== statuses.PREPLAN) {
            onGoingData.push(curr);
          }
          return {
            ...acc,
            [curr.YardMoveStatus]: [...(acc[curr.YardMoveStatus] || []), curr],
          };
        }, {});

        dataSource["onGoing"] = onGoingData;
        dispatch(setDataSource(dataSource));

        // dispatch(setOnGoing(res.data));

        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

export const asyncGetRequestById = createAsyncThunk(
  "request/Id",
  async (ID, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const { token } = getState().auth;

      let path = `dashboard/YardMoveRequest/${ID}`;

      const res = await callApi({
        path,
        method: "GET",
        token,
      });

      if (res.success) {
        return res;
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncUpdateRequest = createAsyncThunk(
  "request/update",
  async ({ ID, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));
      const { token } = getState().auth;

      const res = await callApi({
        path: `dispatcher/update/${ID}`,
        method: "PUT",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncUpdateCoordRequest = createAsyncThunk(
  "request/update",
  async ({ ID, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));
      const { token } = getState().auth;

      const res = await callApi({
        path: `coordinator/update/${ID}`,
        method: "PUT",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncPrePlanToRequest = createAsyncThunk(
  "request/preplan",
  async (ID, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const { token } = getState().auth;

      const res = await callApi({
        path: `dashboard/PrePlanToRequest/${ID}`,
        method: "PUT",
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncDeleteRequest = createAsyncThunk(
  "request/delete",
  async (ID, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const { token } = getState().auth;

      const res = await callApi({
        path: `dashboard/YardMoveRequest/${ID}`,
        method: "DELETE",
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncGetAllMoveTypes = createAsyncThunk(
  "request/moveTypes",
  async (_, { dispatch, getState }) => {
    try {
      const { token } = getState().auth;

      let path = "yardMoveRecord/moveType";

      const res = await callApi({
        path,
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setMoveTypes(res.data));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);
