import { Col, Row } from "antd";
import classNames from "classnames";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { BiSend } from "react-icons/bi";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineMore } from "react-icons/ai";
import { useSelector } from "react-redux";
import doubleDownArrow from "../../Assets/icons/doubleDownArrow.svg";
import driver from "../../Assets/icons/driving.svg";
import AppButton from "../Button/index";
import { LoadingOutlined } from "@ant-design/icons";

import {
  batch,
  collection,
  doc,
  fireCollection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  deleteDoc,
} from "../../services/firebase";
import { formatDate } from "../../utils";
import { ChatHeader } from "../Common/index";
import MapContainer from "../GoogleMap";
import AppInput from "../Input";

import styles from "./style.module.scss";
import { Delete } from "../Modal/Delete";
import { useLocation } from "react-router-dom";

// const ShagCard = () => {
//   return (
//     <div className={styles.shag}>
//       <div className={styles.head}>
//         <div className={styles.titleSec}>
//           <h5>SHAG 7</h5>
//           <AppTag title="1" color="#BE2929" className={styles.tag} />
//           <p>2 mnt ago</p>
//         </div>
//         <p className={styles.date}>01/10 - 0653</p>
//       </div>
//       <div className={styles.item}>
//         <div className="fabIcon">
//           <RiSendPlaneFill className="icon" />
//         </div>
//         <h6>Foods door 5 needs DMTC put bill in back foods door 4 pull</h6>
//       </div>
//       <Divider className={styles.divider} />
//       <div className={styles.footer}>
//         <h5 className={styles.user}>
//           Driver
//           <span>James Harbeson</span>
//         </h5>
//         <p className={styles.user}>
//           Total <span>28</span>
//         </p>
//       </div>
//     </div>
//   );
// };

// const UserList = () => {
//   return (
//     <div className={styles.user}>
//       <div className={styles.items}>
//         <h6>
//           Faisal sultan <span>(Shag7)</span>
//         </h6>
//         <div className={styles.caption}>
//           <AppTag title="3" color="#0162EA" className={styles.badge} />
//           <p className={styles.time}>01/10 - 0653</p>
//         </div>
//       </div>
//       <p className={styles.msg}>How are you?</p>
//     </div>
//   );
// };

export let chatTypes = {
  globalChat: {
    key: "globalChat",
    title: "Global Chat",
    icon: <HiOutlineGlobeAlt />,
  },
  // coordinatorsDispatchers: {
  //   key: "coordinatorsDispatchers",
  //   title: "Coordinator - Dispatcher",
  //   icon: <BiMessageSquareDetail />,
  // },
  // DispatchersDrivers: {
  //   key: "DispatchersDrivers",
  //   title: "Dispatcher - Drivers",
  //   icon: <BiMessageSquareDetail />,
  // },
  completedNotifications: {
    key: "completedNotifications",
    title: "Notifications",
    icon: <MdOutlineNotificationsNone />,
  },
};

let roleName = {
  12: "Driver",
  13: "Coordinator",
  14: "Dispatcher",
  15: "Admin",
};

const Sender = memo(({ msg, setMsgId, canDelete }) => {
  return (
    <div className={classNames(styles.msg, styles.sender)}>
      {/* <div className="fabIcon mr-3">
        <RiSendPlaneFill className="icon" />
      </div> */}
      {canDelete && (
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            padding: "0px 5px",
            cursor: "pointer",
          }}
          onClick={setMsgId}
        >
          <AiOutlineMore />
        </div>
      )}
      <div className={styles.items}>
        <div className={styles.text}>
          <p>{msg.message}</p>
        </div>
        <div className={styles.foot}>
          <p></p>
          <p>{formatDate((msg?.timestamp?.seconds || 0) * 1000)}</p>
        </div>
      </div>
    </div>
  );
});

const NotificationReceiver = memo(({ msg }) => {
  return (
    <div
      className={classNames(styles.msg, styles.receiver)}
      style={{ width: "100%" }}
    >
      <div className="fabIcon mr-3" style={{ backgroundColor: "#DADADA" }}>
        <RiSendPlaneFill className="icon" />
      </div>
      <div className={styles.items}>
        <div className={styles.text} style={{ backgroundColor: "#DADADA" }}>
          <p>
            <strong style={{ letterSpacing: "0.4px", fontSize: "12px" }}>
              {msg.message}:
            </strong>
          </p>
          <p>Carrier: {msg.carrier}</p>
          <p>Move Type: {msg.moveType}</p>
          <p>Trip: {msg.trip}</p>
          <p>Trailer: {msg.trailer}</p>
          <p>Door: {msg.door}</p>
          <p>Temp: {msg.setPointTemp}</p>
          <p>Total Time: {msg.totalTime}</p>
        </div>
      </div>
    </div>
  );
});

const Reciever = memo(({ msg, onClick, setMsgId, senderData, canDelete }) => {
  if (!senderData?.role) {
    return;
  }

  return (
    <div className={classNames(styles.msg, styles.receiver)}>
      <div className="fabIcon mr-3" style={{ backgroundColor: "#DADADA" }}>
        {+senderData?.role === 12 ? (
          <img src={driver} alt="" className="icon" />
        ) : (
          <RiSendPlaneFill className="icon" />
        )}
      </div>
      <div className={styles.items}>
        <div
          className={msg.type === "image" ? styles.image : styles.text}
          style={{ backgroundColor: "#DADADA" }}
        >
          {msg.type === "image" ? (
            <img
              style={{ width: "100%", cursor: "pointer" }}
              src={msg.file}
              alt=""
              onClick={onClick}
            />
          ) : (
            <p>{msg.message}</p>
          )}
        </div>
        <div className={styles.foot}>
          <p>{formatDate((msg?.timestamp?.seconds || 0) * 1000)} </p>
          <p>
            {senderData?.name?.toUpperCase()}{" "}
            {roleName[+senderData?.role]
              ? `[${roleName[[+senderData?.role]]}]`
              : ""}
          </p>
        </div>
      </div>
      {canDelete && (
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            padding: "0px 5px",
            cursor: "pointer",
          }}
          onClick={setMsgId}
        >
          <AiOutlineMore />
        </div>
      )}
    </div>
  );
});

const Chat = memo(
  ({
    chatId = "",
    isNotification = false,
    showMore,
    setShowMore,
    setPreviewImage,
  }) => {
    const { user } = useSelector((s) => s.user);
    const [message, setMessage] = useState("");
    const [stateMessages, setStateMessages] = useState([]);
    const [firstLoad, setFirstLoad] = useState(false);
    const ShowLastMSG = useRef(null);
    const messagBottomRef = useRef(null);

    const [deleteModal, setDeleteModal] = useState(false);
    const [msgId, setMsgId] = useState(false);
    const [loader, setLoader] = useState(false);

    const initialMessagesLength =
      chatId === chatTypes.completedNotifications.key ? 10 : 25;
    const chatRef = collection("chat");
    const chatDoc = doc(chatRef, chatId);

    const { usersPair } = useSelector((s) => s.user);

    let location = useLocation();

    useEffect(() => {
      setFirstLoad(false);
      getLastMessage();
      handleScroll();

      // scroll to the bottom of the div after updating content
      ShowLastMSG.current.scrollTop = ShowLastMSG.current.scrollHeight;
    }, [chatId]);

    const handleDeleteMsgsByID = async () => {
      setDeleteModal(false);
      setMsgId(null);
      let document = doc(fireCollection(chatDoc, "messages"), msgId);
      await deleteDoc(document);
    };

    const handleScroll = () => {
      if (messagBottomRef.current) {
        if (showMore > 0) {
          setShowMore(0);
        }
        messagBottomRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    const getLoadMore = async () => {
      setLoader(true);
      let myQuery = query(
        fireCollection(doc(chatRef, chatId), "messages"),
        orderBy("createdAt", "desc")
      );
      let lastMessage = stateMessages.length > 0 ? stateMessages[0] : null;
      if (lastMessage) {
        myQuery = query(myQuery, startAfter(lastMessage.document));
      }
      myQuery = query(myQuery, limit(initialMessagesLength));

      await getDocs(myQuery).then((snap) => {
        let m = messageSnapshot(snap);
        setStateMessages([...m, ...stateMessages]);
        if (!firstLoad) setFirstLoad(true);
      });
      setLoader(false);
    };

    const getLastMessage = async () => {
      setLoader(true);
      let myQuery = query(
        fireCollection(doc(chatRef, chatId), "messages"),
        orderBy("createdAt", "desc")
      );
      myQuery = query(myQuery, limit(initialMessagesLength));

      await getDocs(myQuery).then((snap) => {
        let m = messageSnapshot(snap);
        setStateMessages([...m]);
        setTimeout(() => {
          setFirstLoad(true);
        }, 100);
      });
      setLoader(false);
    };

    const messageSnapshot = (snapshot) => {
      let m = [];
      const batchI = batch();
      snapshot.forEach((document) => {
        /// append message obj and assign message id in messages array
        let msg = { document, ...document.data(), messageId: document.id };

        m.push(msg);
        if (msg.readers < 2 && !msg.readBy.includes(user?.id)) {
          batchI.set(
            doc(
              fireCollection(doc(chatRef, chatId), "messages"),
              msg.messageId
            ),
            {
              readers: msg.readers + 1,
              readBy: [...msg.readBy, user?.id],
            },
            { merge: true }
          );
        }
      });
      batchI.commit();
      m = m.sort((a, b) => a.createdAt - b.createdAt);
      return m;
    };

    const loadMore = useCallback(
      (e) => {
        if (e.target.scrollTop === 0 && firstLoad) {
          getLoadMore();
        }
      },
      [stateMessages, firstLoad]
    );

    let isReadyToShowMore =
      Math?.ceil(messagBottomRef?.current?.getBoundingClientRect()?.bottom) >
      ShowLastMSG?.current?.getBoundingClientRect()?.bottom;

    useEffect(() => {
      let myQuery = query(
        fireCollection(doc(chatRef, chatId), "messages"),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      const docsChangeQuery = query(
        fireCollection(doc(chatRef, chatId), "messages")
      );

      if (firstLoad) {
        const lastMsgSubscriber = onSnapshot(myQuery, (snapshot) => {
          if (snapshot.empty) {
            return;
          }

          let m = messageSnapshot(snapshot);

          m = m[0];
          let has = stateMessages.findIndex(
            (msg) => msg?.messageId === m?.messageId
          );
          let mges = stateMessages.filter(
            (msg) => msg.messageId !== m.messageId
          );

          let neArr = [...mges, m];
          if (has === -1) {
            if (m.sender !== +user?.id && isReadyToShowMore) {
              setShowMore((prev) => ++prev);
            }
            setStateMessages(neArr);
          }
        });
        const docChangesSubscriber = onSnapshot(docsChangeQuery, (snapshot) => {
          const ids = [];
          snapshot.docChanges().forEach(({ type, doc }) => {
            if (type === "removed") {
              ids.push(doc.id);
            }
          });
          if (ids.length > 0) {
            setStateMessages((msgs) =>
              msgs.filter((msg) => !ids.includes(msg.messageId))
            );
          }
        });
        return () => {
          lastMsgSubscriber?.();
          docChangesSubscriber?.();
        };
      }
      // scroll to the bottom of the div after updating content
      ShowLastMSG.current.scrollTop = ShowLastMSG.current.scrollHeight;
    }, [firstLoad, stateMessages]);

    var handleMessage = async (message, type = "text") => {
      const date = new Date();
      const batchI = batch();
      batchI.set(
        chatDoc,
        {
          lastMessage: message,
          timestamp: serverTimestamp(),
          lastMsgDate: `${date.toLocaleDateString(
            "en"
          )} ${date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}`,
        },
        { merge: true }
      );
      batchI.set(doc(fireCollection(chatDoc, "messages")), {
        type,
        message,
        sender: user?.id,
        senderName: user?.name,
        userType: roleName[+user?.role],
        senderRole: +user?.role,
        createdAt: date.getTime(),
        timestamp: serverTimestamp(),
        time: date.toLocaleString("en", {
          hour: "2-digit",
          minute: "numeric",
        }),
      });
      await batchI.commit();
      handleScroll();
    };

    const sendMessage = () => {
      if (!message.trim()) return;
      handleMessage(message.trim());
      setMessage("");
    };

    return (
      <div className={styles.chaContent}>
        {loader && (
          <div
            key={"loader"}
            style={{
              position: "absolute",
              left: "50%",
              zIndex: 999,
            }}
          >
            <LoadingOutlined style={{ fontSize: 24, color: "#000000" }} spin />
          </div>
        )}
        <div
          onScroll={(e) => {
            loadMore(e);
          }}
          ref={ShowLastMSG}
          style={{ position: "relative" }}
          className={classNames(
            styles.conversation,
            location.pathname.indexOf("admin") > -1 ? styles.admin : "",
            isNotification ? styles.notificationConversation : ""
          )}
        >
          {stateMessages.length > 0 ? (
            stateMessages.map((msg, ind) => {
              return msg.sender === +user?.id ? (
                <Sender
                  key={ind + "sender"}
                  msg={msg}
                  canDelete={+user?.role === 15}
                  setMsgId={() => {
                    setMsgId(msg.messageId);
                    setDeleteModal(true);
                  }}
                />
              ) : chatId === chatTypes.completedNotifications.key ? (
                <NotificationReceiver key={ind + "notification"} msg={msg} />
              ) : (
                <Reciever
                  key={ind + "reciever"}
                  onClick={() => setPreviewImage(msg.file)}
                  senderData={usersPair[msg.sender]}
                  canDelete={+user?.role === 15}
                  msg={msg}
                  setMsgId={() => {
                    setMsgId(msg.messageId);
                    setDeleteModal(true);
                  }}
                />
              );
            })
          ) : (
            <p className={styles.notFound}>No Messages!</p>
          )}
          <div
            key={"last"}
            ref={messagBottomRef}
            style={{ float: "left", clear: "both" }}
          ></div>
        </div>
        {showMore > 0 && (
          <div
            onClick={handleScroll}
            className={classNames(
              styles.showNew,
              location.pathname.indexOf("admin") > -1 ? styles.admin : ""
            )}
          >
            <img src={doubleDownArrow} alt="" />
          </div>
        )}
        {!isNotification && (
          <div className={styles.typeMsg}>
            <AppInput
              value={message}
              onChange={({ target: { value } }) => setMessage(value)}
              placeholder="Type message"
              size="middle"
              margin="0 5px 0 0"
              style={{ borderRadius: 6 }}
              onKeyPress={({ key }) => {
                if (key === "Enter") {
                  sendMessage();
                }
              }}
            />
            <AppButton className={styles.sendBtn} onClick={sendMessage}>
              <BiSend />
            </AppButton>
          </div>
        )}

        {setDeleteModal && (
          <Delete
            open={deleteModal}
            onClick={() => {
              handleDeleteMsgsByID();
            }}
            onCancel={() => {
              setMsgId(null);
              setDeleteModal(false);
            }}
          />
        )}
      </div>
    );
  }
);

// const List = ({ children }) => {
//   return (
//     <div className={styles.chaContent}>
//       <div className={styles.chatHeader}>
//         <h5>Active Drivers</h5>
//       </div>
//       <div className={styles.list}>{children}</div>
//     </div>
//   );
// };

const Messages = memo(({ onRequestMove }) => {
  const [selectedChat, setSelectedChat] = useState(chatTypes.globalChat.key);
  const [showMore, setShowMore] = useState(0);
  const [previewImage, setPreviewImage] = useState("");

  return (
    <div className={styles.grid}>
      {previewImage && (
        <div className={styles.imagePreview}>
          <span className={styles.cross} onClick={() => setPreviewImage("")}>
            X
          </span>
          <img
            src={previewImage}
            style={{ width: "100%", objectFit: "contain" }}
            alt="preview"
          ></img>
        </div>
      )}
      <Row align="middle">
        {/* <h1 className="text-4xxl font-600 mr-10">Messages</h1> */}
        {/* <AppTag
          title="3 new messages"
          color="#BE2929"
          padding="0 10px"
          height="max-content"
        /> */}
      </Row>
      <Row className={styles.layout}>
        <div className={styles.contentSec}>
          <Row>
            <Col span={24}>
              <div className={styles.chatHeaderBox}>
                {onRequestMove && (
                  <div
                    className={classNames(styles.setBtnWithExpand, "pt-5 px-5")}
                  >
                    <AppButton
                      onClick={onRequestMove}
                      title="Request Move"
                      height="36px"
                      backgroundColor="#005c42"
                      color="#fff"
                    />
                    {/* <div className={classNames(styles.expandBtn,'expandButton')}>
                    <span onClick={()=>{callClickExpand('bottomPanel')}}> {showIcon} </span>
                  </div> */}
                  </div>
                )}
                <ChatHeader
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  // backIcon={selectedChat && <RiArrowLeftSLine />}
                  // heading={chatTypes?.[selectedChat]?.title ?? "Chat room"}
                  // onBack={() => {
                  //   setSelectedChat("");
                  // }}
                />{" "}
              </div>
            </Col>
          </Row>
          <div className={styles.chatLayout}>
            <Row className="h-100">
              <Col span={24} className="h-100">
                {/* {!selectedChat &&
                  Object.keys(chatTypes).map((key) => {
                    return (
                      <ChatChannel
                        heading={chatTypes?.[key]?.title}
                        headingIcon={chatTypes?.[key]?.icon}
                        setSelectedChat={() => {
                          setSelectedChat(key);
                        }}
                      />
                    );
                  })} */}
                {selectedChat === chatTypes.globalChat.key && (
                  <Chat
                    chatId={chatTypes.globalChat.key}
                    key="globalChat"
                    showMore={showMore}
                    setShowMore={setShowMore}
                    setPreviewImage={setPreviewImage}
                  />
                )}
                {/* {selectedChat === chatTypes.coordinatorsDispatchers.key && (
                  <Chat
                    chatId={chatTypes.coordinatorsDispatchers.key}
                    showMore={showMore}
                    setShowMore={setShowMore}
                  />
                )}
                {selectedChat === chatTypes.DispatchersDrivers.key && (
                  <Chat
                    chatId={chatTypes.DispatchersDrivers.key}
                    showMore={showMore}
                    setShowMore={setShowMore}
                  />
                )} */}
                {selectedChat === chatTypes.completedNotifications.key && (
                  <Chat
                    key="completedNotifications"
                    chatId={chatTypes.completedNotifications.key}
                    showMore={showMore}
                    setShowMore={setShowMore}
                    isNotification={true}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>

        <div className={styles.imgSec}>
          <div className={styles.banner}>
            <MapContainer />
          </div>
        </div>
      </Row>
    </div>
  );
});

export default Messages;
