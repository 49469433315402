import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { setVehicles } from "../reducers/vehicle";
import { asyncAlertError } from "./common";

export const asyncGetAllVehicles = createAsyncThunk(
  "vehicle/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "twilio/vehicles",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setVehicles(res.data?.vehicles));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

// export const asyncGetAllVehiclesss = createAsyncThunk(
//   "vehicle/listt",
//   async (_, { dispatch }) => {
//     try {
//       const res = await axios.get(
//         "https://api.keeptruckin.com/v2/vehicle_locations",
//         {
//           headers: {
//             "X-Api-Key": "595c41f2-769c-4936-a055-f70f8220d2cd",
//           },
//         }
//       );

//       if (res.status === 200) {
//         dispatch(setVehicles(res.data?.vehicles));
//         return { success: true };
//       }
//       return { success: false };
//     } catch (error) {
//       return { success: false };
//     }
//   }
// );
