import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      state.token = payload;
    },
    resetAuth: () => {
      return {
        token: null,
      };
    },
  },
});

export const { setAuth, resetAuth } = AuthSlice.actions;
export default AuthSlice.reducer;
