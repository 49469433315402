import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { Col, Form, Row, Switch } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../../Components/Button";
import { SearchInput } from "../../../Components/Filters/SearchInput";
import { Delete } from "../../../Components/Modal/Delete";
import { User } from "../../../Components/Modal/User";
import AppSelect from "../../../Components/Select";
import { DataTable } from "../../../Components/Table";
import {
  asyncBanUser,
  asyncCreateUser,
  asyncDeleteUser,
  asyncGetAllUsers,
  asyncUpdateUser,
} from "../../../store/actions/user";
import { checkValue, roles } from "../../../utils";

const Users = () => {
  let [form] = Form.useForm();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [dataRow, setDataRow] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  // const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState(false);

  const { users } = useSelector((s) => s.user);
  const localAdminLoading = useSelector((s) => s.common.localAdminLoading);

  let isDriver = +Form.useWatch("role", form) === 12;

  useEffect(() => {
    const loadUsers = async () => {
      // setLoader(true);
      await dispatch(asyncGetAllUsers()).unwrap();
      // setLoader(false);
    };

    loadUsers();
  }, [dispatch]);

  useEffect(() => {
    if (isEdit) {
      let [firstname, ...lastname] = dataRow?.name?.split(" ") || ["", []];

      form.setFieldsValue({
        role: dataRow.role,
        firstname,
        lastname: lastname.join(" "),
        email: dataRow.email,
        phone: dataRow.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  let tableData =
    users?.filter(({ name, email, role }) => {
      let SF = filters;
      let row = [];

      let isName = checkValue(SF?.nameEmail, name);
      if (!isName) {
        row.push(checkValue(SF?.nameEmail, email));
      } else {
        row.push(isName);
      }
      row.push(checkValue(SF?.role, role, true));

      if (row.every(Boolean)) {
        return true;
      } else {
        return false;
      }
    }) || [];

  const defaultColumns = [
    {
      title: "ID #",
      dataIndex: "id",
      width: 90,
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      render: (_, { name }) => name || "N/A",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, { email }) => email || "N/A",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => {
        let color = "";
        switch (role) {
          case "12":
            color = "#0162EA";
            break;
          case "13":
            color = "#EE7A27";
            break;
          case "14":
            color = "#047857";
            break;
          default:
            color = "#D9D9D9";
        }

        return (
          <p className="font-600" style={{ color }}>
            {role === "15"
              ? "Admin"
              : roles.find((r) => r.value === role)?.label || "N/A"}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 200,
      render: (_, action) => {
        return (
          <Row
            className="users-tbl-action-row"
            align="middle"
            justify="space-evenly"
          >
            <Switch
              defaultChecked={action.ban}
              size="small"
              onChange={() => handleBan(action.id)}
              className="users-tbl-switch"
            />
            <FormOutlined
              className="users-tbl-icons"
              style={{ fontSize: 20, color: "#000" }}
              onClick={() => {
                setDataRow(action);
                setIsEdit(true);
                setOpen(true);
              }}
            />
            {!action.key && (
              <DeleteOutlined
                className="users-tbl-icons"
                style={{ fontSize: 20, color: "red" }}
                onClick={() => {
                  setDataRow(action);
                  setDeleteModal(true);
                }}
              />
            )}
          </Row>
        );
      },
    },
  ];

  const handleSave = async ({ firstname, lastname, ...values }) => {
    if (localAdminLoading) return;

    let res = await dispatch(
      asyncCreateUser({
        ...values,
        name: `${firstname} ${lastname}`,
      })
    ).unwrap();
    if (res.success) {
      resetFormModal();
    }
  };

  const handleEdit = async ({ firstname, lastname, password, ...values }) => {
    if (localAdminLoading) return;

    let credential = password || dataRow?.password;
    let res = await dispatch(
      asyncUpdateUser({
        ...values,
        id: dataRow?.id,
        password: credential,
        name: `${firstname} ${lastname}`,
      })
    ).unwrap();
    if (res.success) {
      resetFormModal();
    }
  };

  const handleDelete = async () => {
    if (localAdminLoading) return;

    await dispatch(asyncDeleteUser({ id: dataRow?.id })).unwrap();
    resetDeleteModal();
  };

  const handleBan = async (id) => {
    if (localAdminLoading) return;

    await dispatch(asyncBanUser({ id })).unwrap();
  };

  const debounceSearch = useRef(
    debounce(({ key, value }) => {
      setFilters((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    }, 300)
  ).current;

  const handleFilterChange = (props) => {
    debounceSearch(props);
  };

  const resetFormModal = () => {
    setOpen(false);
    setIsEdit(false);
    setDataRow({});
    setChecked(false);
    form.resetFields();
  };

  const resetDeleteModal = () => {
    setDeleteModal(false);
    setDataRow({});
  };

  return (
    <>
      <div className="head">
        <Row justify="space-between" align="bottom" className="my-15">
          <Col span={16}>
            <Row gutter={16}>
              <Col lg={12} xl={10} xxl={6}>
                <SearchInput
                  autoComplete="email"
                  title="Search"
                  dataIndex="nameEmail"
                  placeholder="Search by name, email"
                  onChange={handleFilterChange}
                />
              </Col>
              <Col lg={12} xl={10} xxl={6}>
                <AppSelect
                  title="Role"
                  size="large"
                  className="users-filter-select"
                  placeholder="Select role"
                  onChange={(value) =>
                    handleFilterChange({
                      key: "role",
                      value,
                    })
                  }
                  data={roles.map((role) => ({
                    value: role.value,
                    label: role.label,
                  }))}
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="end">
              <AppButton
                type="primary"
                size="large"
                width="max-content"
                backgroundColor="var(--color-success-dark)"
                className="mr-10"
                title={"+ New User"}
                onClick={() => setOpen(true)}
              />
            </Row>
          </Col>
        </Row>
      </div>

      <DataTable
        loader={localAdminLoading}
        dataSource={tableData}
        columns={defaultColumns}
      />

      <Form
        initialValues={{
          isSms: false,
        }}
        form={form}
        name="publish"
        layout="vertical"
        onFinish={isEdit ? handleEdit : handleSave}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <User
          form={form}
          open={open}
          roles={roles}
          isEdit={isEdit}
          isDriver={isDriver}
          checked={checked}
          setChecked={setChecked}
          onCancel={resetFormModal}
          onClick={() => form.submit()}
          title={isEdit ? "Update User" : "Add User"}
          btnTitle={isEdit ? "Update" : "Save"}
        />
      </Form>

      <Delete
        open={deleteModal}
        onClick={handleDelete}
        onCancel={resetDeleteModal}
      />
    </>
  );
};

export default Users;
