import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Row } from "antd";
import React from "react";
import AppModal from ".";
import { phoneValidator } from "../../utils";
import AppButton from "../Button";
import AppInput from "../Input";
import AppSelect from "../Select";

export const User = ({
  open,
  form,
  title,
  roles,
  isEdit,
  isDriver,
  checked,
  setChecked,
  btnTitle,
  onCancel,
  onClick,
}) => {
  return (
    <AppModal open={open} minWidth="20%" title={title}>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="role"
            rules={[
              {
                required: true,
                message: `Role is required!`,
              },
            ]}
          >
            <AppSelect
              title="Role"
              size="large"
              data={roles.map((role) => ({
                value: role.value,
                label: role.label,
              }))}
              onChange={(val) => {
                if (val === "12") {
                  form.setFieldValue("isSms", true);
                  setChecked(true);
                } else {
                  form.setFieldValue("isSms", false);
                  setChecked(false);
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} className="mb-8">
          <Form.Item
            name="firstname"
            rules={[
              {
                required: true,
                whitespace: true,
                message: `First Name is required!`,
              },
            ]}
          >
            <AppInput
              className="user-modal-input"
              title="First Name"
              placeholder="John"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={24} className="mb-8">
          <Form.Item
            name="lastname"
            rules={[
              {
                required: true,
                whitespace: true,
                message: `Last Name is required!`,
              },
            ]}
          >
            <AppInput
              className="user-modal-input"
              title="Last Name"
              placeholder="Doe"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={24} className="mb-8">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                whitespace: true,
                message: `Email is required!`,
              },
            ]}
          >
            <AppInput
              className="user-modal-input"
              title="Email"
              placeholder="johndoe@gmail.com"
              size="large"
            />
          </Form.Item>
        </Col>

        {/* {isDriver && ( */}
        <Col span={24} className="mb-8">
          <Form.Item
            name="phone"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: `Phone is required!`,
              },
              phoneValidator,
            ]}
          >
            <AppInput
              className="user-modal-input"
              title="Phone"
              placeholder="phone"
              size="large"
            />
          </Form.Item>
        </Col>
        {/* )} */}
        <Col span={24} className="mb-8">
          <Form.Item
            name="password"
            rules={[
              {
                required: isEdit ? false : true,
                message: `Password is required!`,
              },
            ]}
          >
            <AppInput
              autoComplete="new-password"
              className="user-modal-input"
              title={`Password ${isEdit ? "(optional)" : ""}`}
              placeholder="password"
              size="large"
            />
          </Form.Item>
        </Col>
        {!isEdit && (
          <Col span={24} className="mb-8">
            <Form.Item name="isSms">
              <Checkbox
                checked={checked}
                disabled={isDriver}
                onChange={({ target: { checked } }) => {
                  form.setFieldsValue({ isSms: checked });
                  setChecked(checked);
                }}
                style={{ marginRight: 12 }}
                className="mr-3"
              >
                {isDriver
                  ? "This User will be added to sms notifications"
                  : "Add this user into sms notification list as well"}
              </Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row justify="space-between">
        <AppButton
          icon={<MinusOutlined style={{ fontSize: 18 }} />}
          title="Discard"
          width="120px"
          height="max-content"
          fontSize="16px"
          className="btn_red"
          onClick={onCancel}
        />
        <AppButton
          icon={<PlusOutlined style={{ fontSize: 18 }} />}
          title={btnTitle}
          width="120px"
          height="max-content"
          fontSize="16px"
          className="btn_green mr-10"
          onClick={onClick}
        />
      </Row>
    </AppModal>
  );
};
