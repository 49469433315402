import { getApps, initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import {
  collection as fireCollection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  where,
  writeBatch,
  deleteDoc,
} from "firebase/firestore"

// Client Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDjTTWSS73WUPrpbLuoR5Ga1VfjEfky68o",
  authDomain: "osceola-truck-wash.firebaseapp.com",
  projectId: "osceola-truck-wash",
  storageBucket: "osceola-truck-wash.appspot.com",
  messagingSenderId: "782155996735",
  appId: "1:782155996735:web:c4eb614bee0dac3947628a",
  measurementId: "G-E8S69Q27PK",
}

// Zaid Firebase Configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCWNYesFA9eJon-IOSMPal4I3EjK_aTjPw",
//   authDomain: "nextchat-fcdc9.firebaseapp.com",
//   projectId: "nextchat-fcdc9",
//   storageBucket: "nextchat-fcdc9.appspot.com",
//   messagingSenderId: "362884672079",
//   appId: "1:362884672079:web:c97518e17bf2774e26d83a",
// };

const allApps = getApps()
const app = !allApps.length ? initializeApp(firebaseConfig) : allApps[0]
export default app

export const collection = (path) => fireCollection(getFirestore(), path)
export const batch = () => writeBatch(getFirestore())
export {
  onSnapshot,
  getDocs,
  query,
  fireCollection,
  serverTimestamp,
  doc,
  orderBy,
  limit,
  startAfter,
  where,
  getDoc,
  deleteDoc,
}
export const auth = getAuth()
