import { Col, Row } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
// import logo from "../../Assets/images/logo.svg";
import logo from "../../Assets/images/OsceolaLogo.png";
import styles from "./style.module.scss";

const AuthLayout = ({ title, desc }) => {
  return (
    <Row className={styles.authLayout}>
      <Col md={12} lg={14} xl={14} xxl={16} className={styles.banner}></Col>
      <Col md={12} lg={10} xl={10} xxl={8}>
        <div className={styles.content}>
          <img src={logo} alt="logo" title="Logo" className={styles.logo} />
          <div className={styles.items}>
            <h1 className="text-4xl primary font-700">
              {title} <br /> <span className="dark">{desc}</span>
            </h1>
            <Outlet />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AuthLayout;
