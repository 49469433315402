import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { setCarriers, setCustomers } from "../reducers/customer";
import { asyncAlertError } from "./common";
import { setLocalAdminLoading } from "../reducers/common";

export const asyncCreateCustomer = createAsyncThunk(
  "customer/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "customer",
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        // dispatch(asyncAlertSuccess("Customer Successfully Created!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncUpdateCustomer = createAsyncThunk(
  "customer/update",
  async ({ CustomerID, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const { token } = getState().auth;

      const res = await callApi({
        path: `admin/customer/update/${CustomerID}`,
        method: "PUT",
        body,
        token,
      });

      if (res.success) {
        // dispatch(asyncAlertSuccess("Customer Successfully updated!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncGetAllCustomers = createAsyncThunk(
  "customer/list",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "customer",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setCustomers(res.data));
        // dispatch(asyncAlertSuccess("Customers fetched successfully"));
        return { success: true };
      }

      // dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      // dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncGetAllCustomersAdmin = createAsyncThunk(
  "customer/list",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "customer/list",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setCustomers(res.data));
        // dispatch(asyncAlertSuccess("Customers fetched successfully"));
        return { success: true };
      }

      // dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      // dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncGetAllCarriers = createAsyncThunk(
  "customer/carrier/list",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "customer/carrier",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setCarriers(res.data));
        // dispatch(asyncAlertSuccess("Customers fetched successfully"));
        return { success: true };
      }

      // dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      // dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);
