import React from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { ImCheckmark, ImCross } from "react-icons/im";
import styles from "./style.module.scss";

const { Dragger } = Upload;

export const FileUploader = ({
  showFileUploader,
  setShowFileUploader,
  handleFileSelection,
}) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "move";
  };

  const handleFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let file = e.dataTransfer.files[0];
    let uid = `rc-upload-${file.name}-${file.lastModified - file.size}`;
    Object.defineProperty(file, "uid", {
      get: () => uid,
    });
    Object.defineProperty(file, "originFileObj", {
      get: () => file,
    });
    setShowFileUploader((s) => ({ ...s, file }));
  };

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    customRequest: () => {},
    onChange: (e) => {
      handleFile(e);
    },
    onDrop: (e) => {
      handleFile(e);
    },
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.overlayInner}>
        <Dragger
          className={styles.dragger}
          disabled={true}
          showUploadList={false}
          customRequest={({ onSuccess }) => {
            onSuccess("ok");
          }}
          onDragOver={handleDragOver}
          {...props}
        >
          {!showFileUploader.file?.uid ? (
            <div className={styles.draggerTitle}>
              <p>Drop your file here</p>
              <InboxOutlined style={{ fontSize: "48px", color: "#fff" }} />
            </div>
          ) : (
            <div className={styles.fileSection}>
              <p style={{ color: "#fff", fontSize: "42px" }}>
                {showFileUploader.file?.name}
              </p>

              <div className={styles.fileSectionIcons}>
                <ImCheckmark
                  style={{ margin: 20 }}
                  onClick={handleFileSelection}
                />
                <ImCross
                  style={{ margin: 20 }}
                  onClick={() => setShowFileUploader({ show: false, file: {} })}
                />
              </div>
            </div>
          )}
        </Dragger>
      </div>
    </div>
  );
};
