import { Space, Tabs } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Documents from "../../Components/Accounts/Documents";
import Invoice from "../../Components/Accounts/Invoice";
import OrderDetails from "../../Components/Accounts/OrderDetails";
import PaymentReceipt from "../../Components/Accounts/Payment";
import Transaction from "../../Components/Accounts/Transaction";
import AppButton from "../../Components/Button";
import {
  asyncGetAllChartOfAccounts,
  asyncGetAllDocumentTypes,
} from "../../store/actions/account";
import { asyncGetAllCarriers } from "../../store/actions/customer";
import {
  asyncGetAllActiveDrivers,
  asyncGetAllDrivers,
} from "../../store/actions/driver";
import {
  asyncGetAllMoveTypes,
  asyncGetRequestById,
} from "../../store/actions/request";
import { asyncGetAllTrailers } from "../../store/actions/trailer";
import { setSelected } from "../../store/reducers/request";
import styles from "./style.module.scss";
import { statuses } from "../../utils";
import { isEqual } from "lodash";
import { OverLay } from "../../Components/NotFound";

const AccountingBook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id: yardMoveId } = useParams();
  const paramRecord = location?.state?.record;

  const user = useSelector((s) => s.user.user, isEqual);
  const selectedRecord = useSelector((s) => s.request.selected, isEqual);
  const localLoading = useSelector((s) => s.common.localLoading);

  const [loader, setLoader] = useState();
  // const [activeKey, setActiveKey] = useState(1);

  let baseRoute = `/yard-move/${yardMoveId}`;

  let tabRoutesByKey = {
    1: baseRoute,
    2: "invoice",
    3: "payment",
    4: "transactions",
    5: "documents",
  };

  let tabRoutesByValue = useMemo(() => {
    return {
      [yardMoveId]: 1,
      invoice: 2,
      payment: 3,
      transactions: 4,
      documents: 5,
    };
  }, [yardMoveId]);

  let record = useMemo(() => {
    return selectedRecord?.ID ? selectedRecord : paramRecord;
  }, [selectedRecord, paramRecord]);

  // useEffect(() => {
  // let newKey = tabRoutesByValue[location.pathname.split("/").at(-1)];
  // setActiveKey((activeKey) => {
  //   return activeKey !== newKey ? newKey : activeKey;
  // });
  // }, [location, tabRoutesByValue]);

  const getNewKey = () => {
    return tabRoutesByValue[location.pathname.split("/").at(-1)];
  };

  const loadRequest = useCallback(async () => {
    setLoader(true);
    let res = await dispatch(asyncGetRequestById(yardMoveId)).unwrap();
    if (res.success) {
      dispatch(setSelected(res.data));
    } else {
      navigate("/yard-move");
    }
    setLoader(false);
  }, [dispatch, navigate, yardMoveId]);

  useEffect(() => {
    const loadData = async () => {
      dispatch(asyncGetAllCarriers());
      dispatch(asyncGetAllTrailers());
      dispatch(asyncGetAllMoveTypes());
      dispatch(asyncGetAllDocumentTypes());

      if (+user.role === 14) {
        dispatch(asyncGetAllChartOfAccounts());
        dispatch(asyncGetAllActiveDrivers());
        dispatch(asyncGetAllDrivers());
      }
    };

    loadRequest();
    loadData();
  }, [dispatch, user?.role]);

  const items = [
    {
      key: 1,
      label: `Order Details`,
      children: (
        <OrderDetails
          record={record}
          loader={loader}
          localLoading={localLoading}
        />
      ),
    },
  ];

  +user?.role === 14 &&
    record?.ID &&
    record?.YardMoveStatus === statuses.COMPLETED &&
    items.push(
      {
        key: 2,
        label: `Invoice`,
        children: (
          <Invoice
            record={record}
            loader={loader}
            localLoading={localLoading}
          />
        ),
      },
      {
        key: 3,
        label: `Payment Receive`,
        children: (
          <PaymentReceipt
            record={record}
            loader={loader}
            localLoading={localLoading}
          />
        ),
      },
      {
        key: 4,
        label: `Transactions`,
        children: (
          <Transaction
            record={record}
            loader={loader}
            localLoading={localLoading}
          />
        ),
      },
      {
        key: 5,
        label: `Documents`,
        children: (
          <Documents
            record={record}
            loader={loader}
            localLoading={localLoading}
          />
        ),
      }
    );

  return (
    <div className={styles.accounting}>
      <Space>
        <AppButton
          onClick={() => navigate("/yard-move")}
          title="Yard Move"
          icon={<BiArrowBack size={20} color="#0162EA" className="mr-5" />}
          width="max-content"
          color="#0162EA"
          padding="0"
          backgroundColor="transparent"
          fontWeight="600"
        />
        <Space className="ml-20">
          <p className="text-base font-600 info">Order #{yardMoveId}</p>
          <p className="text-base font-600 info ml-10">
            Invoice #{record?.invoice?.ID ?? "N/A"}
          </p>
        </Space>
      </Space>

      <Routes>
        <Route path={tabRoutesByKey[1]} element={<span></span>} />
        <Route path={tabRoutesByKey[2]} element={<span></span>} />
        <Route path={tabRoutesByKey[3]} element={<span></span>} />
        <Route path={tabRoutesByKey[4]} element={<span></span>} />
        <Route path={tabRoutesByKey[5]} element={<span></span>} />
        <Route path="*/*" element={<Navigate to={baseRoute} />} />
      </Routes>

      {loader && <OverLay />}

      <Tabs
        key={"tabs"}
        onTabClick={(tab) => {
          navigate(tabRoutesByKey[tab.toString()], {
            state: { yardMoveId, record },
          });
        }}
        defaultActiveKey={1}
        activeKey={getNewKey()}
        // destroyInactiveTabPane={true}
        animated={true}
        items={items}
      />
    </div>
  );
};

export default AccountingBook;
