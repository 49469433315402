import { Col, DatePicker, Row } from "antd";
import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  dateFormats,
  formatDate,
  formatTableDuration,
  moment,
} from "../../utils";
import AppSelect from "../Select";
import "./timeSheet.scss";

const TimeSheet = ({
  range,
  timeObj,
  dataRow,
  timeData,
  startDate,
  loadDataByID,
  timeSheet,
  parentDate,
}) => {
  const timesheetRef = React.createRef();
  const [height, setHeight] = useState();
  const [showCustom, setShowCustom] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dateBracket, setDateBracket] = useState();
  const [dateRange, setDateRange] = useState([parentDate, parentDate]);

  const settings = {
    height: window.innerHeight * 0.4,
    locale: "en-us",
    onBeforeRowHeaderRender: (args) => {
      args.row.columns[1].html = args.row.events
        .totalDuration()
        .toString("h:mm");
    },
    rowHeaderColumns: [{ title: "Date" }, { title: "Total" }],
    crosshairType: "Header",
    timeHeaders: [
      { groupBy: "Hour" },
      {
        groupBy: "Cell",
        format: "mm",
        // scale: "Minute",
        // cellDuration: 15,
        // interval: 15,
      },
    ],
    cellDuration: 1,
    viewType: "Days",
    eventHeight: 50,
    allowEventOverlap: true,
    sortDirections: ["desc"],
    eventMoveHandling: "Disabled",
    eventResizeHandling: "Disabled",
    eventDeleteHandling: "Disabled",
    eventClickHandling: "Disabled",
    eventHoverHandling: "Bubble",
    snapToGrid: false,
    rowClickHandling: "Select",
  };

  const { RangePicker } = DatePicker;

  //   const updateHeight = () => {
  //     setHeight(window.innerHeight * 0.8);
  //   };

  const onRowClick = ({ resource: { start }, row }) => {
    const date = new DayPilot.Date(start).toString("yyyy-MM-dd");
    const selectedDate = timeSheet.find((t) => t.date === date);

    if (!selectedDate) {
      return timesheetRef.current.control.message("Selected Row is Empty!");
    }

    setSelectedRow?.(selectedDate);
  };

  const addDataToState = useCallback(() => {
    if (!startDate) return;
    let timeSheetData = timeSheet.reduce((acc, curr) => {
      return [...acc, ...curr.timeSheet];
    }, []);

    let dataEvents = timeSheetData.reduce((acc, data) => {
      let [backColor, borderColor] =
        data.resource === "YardMove"
          ? ["#2DC197", "#047857"]
          : ["#99c0f5", "#3384f1"];
      if (data.end) {
        let newStartDate = moment(data.start).format(
          "YYYY-MM-DD[T]HH:mm:ss[Z]"
        );
        let newEndDate = moment(data.end).format("YYYY-MM-DD[T]HH:mm:ss[Z]");

        let total = formatTableDuration(
          moment(data.end).diff(moment(data.start))
        );

        return [
          ...acc,
          {
            id: data.id,
            text: data.resource,
            start: new DayPilot.Date(newStartDate),
            end: new DayPilot.Date(newEndDate),
            backColor,
            borderColor,
            sort: [],
            data,
            toolTip: `${total}`,
          },
        ];
      } else {
        return acc;
      }
    }, []);

    setEvents(dataEvents);
  }, [startDate, timeSheet]);

  useEffect(() => {
    setShowCustom(true);
    setDateRange([parentDate, parentDate]);
  }, [parentDate]);

  useEffect(() => {
    setHeight(window.innerHeight * 0.4);
  }, []);

  useEffect(() => {
    addDataToState();
    // return () => {
    //   window.removeEventListener("resize", updateHeight);
    // };
  }, [addDataToState]);

  let dayPilotStartDate = useMemo(
    () =>
      new DayPilot.Date(moment(startDate).format("YYYY-MM-DD[T]HH:mm:ss[Z]")),
    [startDate]
  );

  const rowData = selectedRow ?? dataRow;

  return (
    <div>
      <div className="timeSheetBox">
        <Row className="CompleteInfo" gutter={24}>
          <Col lg={4}>
            <p className="text-sm font-600 info">Employee</p>
            <p className="font-500">{rowData.driver?.name}</p>
          </Col>
          <Col lg={3}>
            <p className="text-sm font-600 info">Clock in</p>
            <p className="font-500">
              {rowData.start ? formatDate(rowData.start) : "N/A"}
            </p>
          </Col>
          <Col lg={3}>
            <p className="text-sm font-600 info">Clock out</p>
            <p className="font-500">
              {rowData.end ? formatDate(rowData.end) : "N/A"}
            </p>
          </Col>
          <Col lg={3}>
            <p className="text-sm font-600 info">Total time</p>
            <p className="font-500">
              {formatTableDuration(rowData.totalTime * 1000) ?? "N/A"}
            </p>
          </Col>
          <Col>
            <AppSelect
              overFlow="hidden"
              maxWidth="100%"
              defaultValue={timeData.at(-1).value}
              value={dateBracket}
              data={timeData.map((data) => ({
                value: data.value,
                label: data.label,
              }))}
              onChange={async (value) => {
                setDateBracket(value ?? timeData.at(-1).value);

                if (value === "custom" || !value) {
                  setDateRange([parentDate, parentDate]);
                  setShowCustom(true);
                  await loadDataByID(dataRow?.driver?.userId, {
                    from: parentDate,
                    to: parentDate,
                  });
                } else {
                  setShowCustom(false);
                  const { from, to } = timeObj[value];
                  await loadDataByID(dataRow?.driver?.userId, {
                    from,
                    to,
                  });
                }

                // if (value && value !== "custom") {
                //   const { from, to } = timeObj[value];
                //   await loadDataByID(dataRow?.driver?.userId, {
                //     from,
                //     to,
                //   });
                // }
              }}
            />
          </Col>
          {showCustom && (
            <Col lg={4}>
              <RangePicker
                format={dateFormats["MM/DD/YY"]}
                defaultValue={[parentDate, parentDate]}
                value={dateRange}
                size="large"
                onChange={async (value) => {
                  value = value ?? [parentDate, parentDate];
                  setDateRange(value);

                  let [from, to] = value;
                  let difference = to.diff(from);

                  await loadDataByID(dataRow?.driver?.userId, {
                    from: moment(from),
                    to: moment(to),
                    range: Math.ceil(moment.duration(difference).asDays()),
                  });
                }}
                style={{ width: "100%", borderRadius: 8 }}
              />
            </Col>
          )}
        </Row>
      </div>

      <DayPilotScheduler
        {...settings}
        cellWidth={50}
        headerHeight={45}
        height={height}
        ref={timesheetRef}
        days={range + 1}
        startDate={dayPilotStartDate}
        events={events}
        onRowClick={onRowClick}
        bubble={null}
        showToolTip={true}
        scrollDelayEvents={1}
      />
    </div>
  );
};

export default TimeSheet;
