// import { CloseCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Divider, Row, Tag } from "antd";
import styles from "./style.module.scss";
import banner from "../../Assets/images/under-construction.svg";
import invoice from "../../Assets/images/invoice.png";

const NotificationCard = ({ margin, notification }) => {
  let color = "";
  switch (notification?.Type) {
    case "Requested":
      color = "#D9D9D9";
      break;
    case "Dispatched":
      color = "#0055CC";
      break;
    case "InProgress":
      color = "#9B57F2";
      break;
    case "Completed":
      color = "#047857";
      break;
    default:
      color = "#D9D9D9";
  }

  const record = notification?.Request || {};

  return (
    <div className={styles.notifyCard} style={{ margin }}>
      <Row justify="space-between" align="middle">
        <div className={styles.content}>
          <Tag
            color={color}
            style={{
              cursor: "pointer",
              fontSize: 12,
              margin: "0 1.125rem 0 0",
              padding: "3px 10px",
              width: "max-content",
              height: "30px",
              borderRadius: 4,
            }}
          >
            {notification?.Type?.toUpperCase()}
          </Tag>
          {/* <h5 className="text-xl font-500">Yard move request</h5> */}
          <p className="text-base font-500 info">
            Move ID{" "}
            <span className="text-base font-700">
              #{notification?.RequestID}
            </span>
          </p>
          <Divider type="vertical" className={styles.divider} />
          <Row className={styles.itemRow} align="middle">
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="dark text-base font-600 mr-10">Customer : </p>
              <p className="text-base font-500 info">{`${
                record?.carrier?.name || "N/A"
              }`}</p>
            </div>
            <Divider type="vertical" className={styles.divider} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="dark text-base font-600 mr-10">MoveType : </p>
              <p className="text-base font-500 info">{`${
                record?.YardMoveType?.Service || "N/A"
              }`}</p>
            </div>
            <Divider type="vertical" className={styles.divider} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="dark text-base font-600 mr-10">Door : </p>
              <p className="text-base font-500 info">{`${
                record?.Door || "N/A"
              }`}</p>
            </div>
            <Divider type="vertical" className={styles.divider} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="dark text-base font-600 mr-10">Trailer : </p>
              <p className="text-base font-500 info">{`${
                record?.trailer?.UnitNo || "N/A"
              }`}</p>
            </div>
            <Divider type="vertical" className={styles.divider} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="dark text-base font-600 mr-10">Trip : </p>
              <p className="text-base font-500 info">{`${
                record?.AssignedOrderRef || "N/A"
              }`}</p>
            </div>
          </Row>
        </div>
        {/* <CloseCircleOutlined style={{ fontSize: 22, color: "#D0D5DD" }} /> */}
      </Row>
    </div>
  );
};

export default NotificationCard;

export const UpcomingCard = () => {
  return (
    <div className={styles.upcomingCard}>
      <img alt="" src={banner} />
      <h4 className="text-3xl font-700 dark">Page Under Construction</h4>
      <p className="text-xl font-600 info-light w-50">
        This page is under construction, but you can use other pages.
      </p>
    </div>
  );
};

export const DocumentFile = ({ title, img, desc, src, ...rest }) => {
  return (
    <div className={styles.documentFile} {...rest}>
      <h6
        className="text-xs font-600 dark mb-5"
        style={{ wordWrap: "break-word", textTransform: "uppercase" }}
      >
        {title}
      </h6>
      <div className={styles.file}>
        <img src={invoice} alt="" />
      </div>
      <p
        className="text-2xs font-500 dark-80 text-center mt-5"
        style={{ wordWrap: "break-word" }}
      >
        {desc}
      </p>
    </div>
  );
};

export const FormLayout = ({ label, children }) => {
  return (
    <div className={styles.formLayout}>
      <p className={styles.title}>{label}</p>
      {children}
    </div>
  );
};
