import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

export const SearchInput = ({
  title = "",
  placeholder = "Search",
  prefix = false,
  tableName = "",
  dataIndex = "",
  onChange = () => {},
  value = "",
  className,
  size = "large",
  ...props
}) => {
  // let key = title.replace(" ", "").toLowerCase();

  return (
    <div className={className}>
      <p className="text-xs dark-80 font-500">{title}</p>
      <Input
        className="search-input"
        key={tableName + "_" + dataIndex} // + "_" + value}
        defaultValue={value}
        size={size}
        {...props}
        allowClear={{
          clearIcon: (
            <CloseOutlined
              style={{
                color: "#0055CC",
              }}
            />
          ),
        }}
        placeholder={placeholder}
        onChange={({ target: { value } }) =>
          onChange({
            tableName,
            key: dataIndex,
            value,
          })
        }
        prefix={
          prefix ? <SearchOutlined style={{ color: " #0055CC" }} /> : false
        }
      />
    </div>
  );
};
