import { FormOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../../Components/Button";
import { SearchInput } from "../../../Components/Filters/SearchInput";
import { Customer } from "../../../Components/Modal/Customer";
import { DataTable } from "../../../Components/Table";
import {
  asyncCreateCustomer,
  asyncGetAllCarriers,
  asyncUpdateCustomer,
} from "../../../store/actions/customer";
import { checkValue } from "../../../utils";

const AddCarrier = () => {
  let [form] = Form.useForm();

  const dispatch = useDispatch();

  const { carriers } = useSelector((s) => s.customer);
  const localAdminLoading = useSelector((s) => s.common.localLoading);

  const [open, setOpen] = useState(false);
  // const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState({});

  const [dataRow, setDataRow] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const loadCarriers = async () => {
      // setLoader(true);
      await dispatch(asyncGetAllCarriers()).unwrap();
      // setLoader(false);
    };

    loadCarriers();
  }, [dispatch]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        Customer_Name: dataRow.Name,
        Billing_Email: dataRow.Billing_Email,
        Customer_Address: dataRow.Customer_Address,
        Customer_State: dataRow.Customer_State,
        Customer_Zip: dataRow.Customer_Zip,
        Ops_Phone1: dataRow.Ops_Phone1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  let tableData =
    carriers?.filter(({ Name, Billing_Email }) => {
      let SF = filters;
      let row = [];

      row.push(checkValue(SF?.name, Name));
      row.push(checkValue(SF?.email, Billing_Email));

      if (row.every(Boolean)) {
        return true;
      } else {
        return false;
      }
    }) || [];

  const defaultColumns = [
    {
      title: "ID #",
      dataIndex: "ID",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "Name",
      render: (_, { Name }) => Name || "N/A",
    },
    {
      title: "Address",
      dataIndex: "Customer_Address",
      render: (_, { Customer_Address }) => Customer_Address || "N/A",
    },
    {
      title: "State",
      dataIndex: "Customer_State",
      width: 60,
      render: (_, { Customer_State }) => Customer_State || "N/A",
    },
    {
      title: "Zip",
      dataIndex: "Customer_Zip",
      render: (_, { Customer_Zip }) => Customer_Zip || "N/A",
    },
    {
      title: "Phone",
      dataIndex: "Ops_Phone1",
      render: (_, { Ops_Phone1 }) => Ops_Phone1 || "N/A",
    },
    {
      title: "Email",
      dataIndex: "Billing_Email",
      render: (_, { Billing_Email }) => Billing_Email || "N/A",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (_, action) => {
        return (
          <Row
            className="users-tbl-action-row"
            align="middle"
            justify="space-evenly"
          >
            <FormOutlined
              className="users-tbl-icons"
              style={{ fontSize: 20, color: "#000" }}
              onClick={() => {
                setDataRow(action);
                setIsEdit(true);
                setOpen(true);
              }}
            />
          </Row>
        );
      },
    },
  ];

  const handleSave = async (values) => {
    if (localAdminLoading) return;

    let res = await dispatch(
      asyncCreateCustomer({
        ...values,
        Table: "carrier",
      })
    ).unwrap();

    if (res.success) {
      form.resetFields();
      resetFormModal();
    }
  };

  const handleEdit = async (values) => {
    if (localAdminLoading) return;

    let res = await dispatch(
      asyncUpdateCustomer({
        ...values,
        CustomerID: dataRow?.ID,
        Table: "carrier",
      })
    ).unwrap();
    if (res.success) {
      resetFormModal();
    }
  };

  const debounceSearch = useRef(
    debounce(({ key, value }) => {
      setFilters((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    }, 300)
  ).current;

  const handleFilterChange = (props) => {
    debounceSearch(props);
  };

  const resetFormModal = () => {
    setOpen(false);
    setIsEdit(false);
    setDataRow({});
    form.resetFields();
  };

  return (
    <>
      <div className="head">
        <Row justify="space-between" align="bottom" className="my-15">
          <Col span={16}>
            <Row gutter={16}>
              <Col lg={12} xl={10} xxl={6}>
                <SearchInput
                  title="Name"
                  dataIndex="name"
                  placeholder="Search"
                  onChange={handleFilterChange}
                />
              </Col>
              <Col lg={12} xl={10} xxl={6}>
                <SearchInput
                  autoComplete="email"
                  title="Email"
                  dataIndex="email"
                  placeholder="Search"
                  onChange={handleFilterChange}
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="end">
              <AppButton
                type="primary"
                size="large"
                width="max-content"
                backgroundColor="var(--color-success-dark)"
                className="mr-10"
                title={"+ New Carrier"}
                onClick={() => setOpen(true)}
              />
            </Row>
          </Col>
        </Row>
      </div>

      <DataTable
        loader={localAdminLoading}
        dataSource={tableData}
        columns={defaultColumns}
      />

      <Form
        form={form}
        name="publish"
        layout="vertical"
        onFinish={isEdit ? handleEdit : handleSave}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Customer
          entity="Carrier"
          isCustomer={false}
          open={open}
          onCancel={resetFormModal}
          onClick={() => form.submit()}
          title={isEdit ? "Update Carrier" : "Add Carrier"}
          btnTitle={isEdit ? "Update" : "Save"}
        />
      </Form>
    </>
  );
};

export default AddCarrier;
