import { Checkbox, Form, Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../Components/Button";
import { asyncLoginUser } from "../../store/actions/auth";
import styles from "./style.module.scss";
import { PATHS } from "../../Components/Navigation";

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const res = await dispatch(asyncLoginUser(values)).unwrap();
    if (res.success) {
      navigate("/");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      style={{ marginTop: 50 }}
      name="basic"
      layout="vertical"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            required: true,
            whitespace: true,
            message: "Please input your email!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          span: 24,
        }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          span: 24,
        }}
      >
        <AppButton
          type="primary"
          size="large"
          htmlType="submit"
          style={{ marginTop: 50 }}
          title="Sign in your account"
        />
      </Form.Item>
      <Form.Item>
        <p onClick={() => navigate(PATHS.SIGNUP)} className={styles.link}>
          Don’t have an account, <span> Sign up now</span>
        </p>
      </Form.Item>
    </Form>
  );
};

export default Login;
