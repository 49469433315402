import { Modal } from "antd";
import React from "react";
// import close from "../../Assets/icons/close-fill.svg";

const AppModal = ({
  open,
  onCancel,
  title,
  width,
  minWidth,
  maxWidth,
  minHeight,
  children,
  footer = null,
  contentClass = "mb-25",
  ...props
}) => {
  return (
    <Modal
      {...props}
      open={open}
      maskClosable={false}
      centered
      closable={false}
      onCancel={onCancel}
      footer={footer}
      // closeIcon={<img src={close} style={{ width: 30 }} />}
      style={{ minWidth: minWidth, maxWidth: maxWidth, minHeight: minHeight }}
    >
      <div
        className={contentClass}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title && <h1 className="text-2xl font-600">{title}</h1>}
        {props?.headerIcon}
      </div>
      {children}
    </Modal>
  );
};
export default AppModal;
