import { FormOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../../Components/Button";
import { SearchInput } from "../../../Components/Filters/SearchInput";
import { Trailer } from "../../../Components/Modal/Trailer";
import { DataTable } from "../../../Components/Table";
import {
  asyncGetAllCarriers,
  asyncGetAllCustomers,
} from "../../../store/actions/customer";
import {
  asyncCreateTrailer,
  asyncGetAllTrailers,
  asyncUpdateTrailer,
} from "../../../store/actions/trailer";
import { checkValue } from "../../../utils";

const AddTrailer = () => {
  let [form] = Form.useForm();
  const dispatch = useDispatch();

  const { carriers, customers } = useSelector((s) => s.customer);
  const { trailers } = useSelector((s) => s.trailer);
  const localAdminLoading = useSelector((s) => s.common.localAdminLoading);

  const [filters, setFilters] = useState({});
  // const [loader, setLoader] = useState(false);

  const [open, setOpen] = useState(false);
  const [dataRow, setDataRow] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const selectedCarrier = Form.useWatch("Carrier", form);

  useEffect(() => {
    const loadTrailers = async () => {
      // setLoader(true);
      await dispatch(asyncGetAllTrailers()).unwrap();
      // setLoader(false);
    };

    const loadData = async () => {
      await dispatch(asyncGetAllCarriers()).unwrap();
      await dispatch(asyncGetAllCustomers()).unwrap();
    };

    loadTrailers();
    loadData();
  }, [dispatch]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        Carrier: dataRow.Carrier,
        Customer: dataRow.Customer,
        UnitNo: dataRow.UnitNo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  let tableData =
    trailers?.filter(({ carrier, customer, UnitNo }) => {
      let SF = filters;
      let row = [];

      row.push(checkValue(SF?.carrier, carrier?.name));
      row.push(checkValue(SF?.customer, customer?.name));
      row.push(checkValue(SF?.unitno, UnitNo, true));

      if (row.every(Boolean)) {
        return true;
      } else {
        return false;
      }
    }) || [];

  const defaultColumns = [
    {
      title: "ID #",
      dataIndex: "ID",
      width: 100,
    },
    {
      title: "Carrier",
      dataIndex: "Carrier",
      width: "30%",
      render: (_, { carrier }) => carrier?.name || "N/A",
    },
    {
      title: "Customer",
      dataIndex: "Customer",
      width: "30%",
      render: (_, { customer }) => customer?.name || "N/A",
    },
    {
      title: "Unit No #",
      dataIndex: "UnitNo",
      render: (_, { UnitNo }) => UnitNo || "N/A",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (_, action) => {
        return (
          <Row
            className="users-tbl-action-row"
            align="middle"
            justify="space-evenly"
          >
            <FormOutlined
              className="users-tbl-icons"
              style={{ fontSize: 20, color: "#000" }}
              onClick={() => {
                setDataRow(action);
                setIsEdit(true);
                setOpen(true);
              }}
            />
          </Row>
        );
      },
    },
  ];

  const handleSave = async (values) => {
    if (localAdminLoading) return;
    // setLoader(true);
    let res = await dispatch(
      asyncCreateTrailer({
        ...values,
      })
    ).unwrap();

    if (res.success) {
      form.resetFields();
      resetFormModal();
    }
    // setLoader(false);
  };

  const handleEdit = async (values) => {
    if (localAdminLoading) return;
    // setLoader(true);
    let res = await dispatch(
      asyncUpdateTrailer({
        ...values,
        ID: dataRow?.ID,
        Carrier: values.Carrier,
        Customer: values.Customer,
      })
    ).unwrap();
    if (res.success) {
      resetFormModal();
    }
    // setLoader(false);
  };

  const debounceSearch = useRef(
    debounce(({ key, value }) => {
      setFilters((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    }, 300)
  ).current;

  const handleFilterChange = (props) => {
    debounceSearch(props);
  };

  const resetFormModal = () => {
    setOpen(false);
    setIsEdit(false);
    setDataRow({});
    form.resetFields();
  };

  return (
    <>
      <div className="head">
        <Row justify="space-between" align="bottom" className="my-15">
          <Col span={16}>
            <Row gutter={16}>
              <Col lg={12} xl={10} xxl={6}>
                <SearchInput
                  title="Carrier"
                  dataIndex="carrier"
                  placeholder="Search"
                  onChange={handleFilterChange}
                />
              </Col>
              <Col lg={12} xl={10} xxl={6}>
                <SearchInput
                  title="Customer"
                  dataIndex="customer"
                  placeholder="Search"
                  onChange={handleFilterChange}
                />
              </Col>
              <Col lg={12} xl={10} xxl={6}>
                <SearchInput
                  title="Unit No"
                  dataIndex="unitno"
                  placeholder="Search"
                  onChange={handleFilterChange}
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="end">
              <AppButton
                type="primary"
                size="large"
                width="max-content"
                backgroundColor="var(--color-success-dark)"
                className="mr-10"
                title={"+ New Trailer"}
                onClick={() => setOpen(true)}
              />
            </Row>
          </Col>
        </Row>
      </div>

      <DataTable
        loader={localAdminLoading}
        dataSource={tableData}
        columns={defaultColumns}
      />

      <Form
        form={form}
        name="publish"
        layout="vertical"
        onFinish={isEdit ? handleEdit : handleSave}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Trailer
          open={open}
          carriers={carriers}
          customers={customers?.filter((customer) => {
            return customer?.Carrier && customer?.Carrier === selectedCarrier;
          })}
          onCancel={resetFormModal}
          onClick={() => form.submit()}
          title={isEdit ? "Update Trailer" : "Add Trailer"}
          btnTitle={isEdit ? "Update" : "Save"}
        />
      </Form>
    </>
  );
};

export default AddTrailer;
