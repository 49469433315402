import moment from "moment-timezone";
import { FILE_URL } from "./services/api";

moment.tz.setDefault("US/Central");

export { moment };

export const getUtc = (date) => {
  return moment(date).utc();
};

export const dateFormats = {
  "MM/DD/YY": "MM/DD/YY",
  "MM/DD/YY - HHmm": "MM/DD/YY - HHmm",
  "MMMM DD, YYYY - HHmm": "MMMM DD, YYYY - HHmm",
  "MM/DD - HHmm": "MM/DD - HHmm",
  "MM DD, YYYY": "MM DD, YYYY",
  "MMM DD, YYYY": "MMM DD, YYYY",
};

export const startOfWeek = moment().startOf("week");
export const endOfWeek = moment().endOf("week");
export const startOfLastWeek = moment().subtract(1, "week").startOf("week");
export const endOfLastWeek = moment().subtract(1, "week").endOf("week");
export const startOfMonth = moment().startOf("month");
export const endOfMonth = moment().endOf("month");
export const startOfLastMonth = moment().subtract(1, "month").startOf("month");
export const endOfLastMonth = moment().subtract(1, "month").endOf("month");

// Not Using AnyMore
export const getGmtMinus5 = (date) => {
  if (moment().isDST()) {
    return moment(date)?.utcOffset("-0500");
  }
  return moment(date)?.utcOffset("-0600");
};

export const getGmt0 = (date) => {
  return moment(date)?.utcOffset("-0000");
};
// Not Using AnyMore

export const formatDashboardDate = () => {
  return moment().format(dateFormats["MMMM DD, YYYY - HHmm"]);
};

export const formatDate = (date, format = null) => {
  return moment(date)?.format(format ?? dateFormats["MM/DD - HHmm"]);
};

export const checkValue = (filter, column, equality = false) => {
  if (filter) {
    if (
      !equality &&
      column &&
      column
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(filter?.toLowerCase() || null) !== -1
    ) {
      return true;
    }
    if (equality && column && column?.toString() === filter?.toString()) {
      return true;
    }
    return false;
  } else {
    return true;
  }
};

export const formatTableDuration = (dateTime, short = true) => {
  let res = moment.duration(dateTime);
  let duration = "";

  if (short) {
    if (res.days()) {
      let D = res.days();
      duration += `${D}D `;
    }

    if (res.hours()) {
      let H = res.hours();
      duration += `${H}H `;
    }

    if (res.minutes()) {
      let M = res.minutes();
      duration += `${M}M `;
    }

    if (!duration) {
      duration = (res.seconds() || 0) + "S";
    }
  } else {
    if (res.asYears() >= 1) {
      return `${Math.floor(res.asYears())} year${
        Math.floor(res.asYears()) > 1 ? "s" : ""
      } ago`;
    } else if (res.asMonths() >= 1) {
      return `${Math.floor(res.asMonths())} month${
        Math.floor(res.asMonths()) > 1 ? "s" : ""
      } ago`;
    } else if (res.asWeeks() >= 1) {
      return `${Math.floor(res.asWeeks())} week${
        Math.floor(res.asWeeks()) > 1 ? "s" : ""
      } ago`;
    } else if (res.asDays() >= 1) {
      return `${Math.floor(res.asDays())} day${
        Math.floor(res.asDays()) > 1 ? "s" : ""
      } ago`;
    } else if (res.asHours() >= 1) {
      return `${Math.floor(res.asHours())} hour${
        Math.floor(res.asHours()) > 1 ? "s" : ""
      } ago`;
    } else if (res.asMinutes() >= 1) {
      return `${Math.floor(res.asMinutes())} minute${
        Math.floor(res.asMinutes()) > 1 ? "s" : ""
      } ago`;
    } else {
      return `${Math.floor(res.asSeconds())} second${
        Math.floor(res.asSeconds()) > 1 ? "s" : ""
      } ago`;
    }
  }

  return duration;
};

// function getRelativeTime(date) {
//   const currentDate = new Date();
//   const diff = currentDate.getTime() - date.getTime();

//   const seconds = Math.floor(diff / 1000);
//   const minutes = Math.floor(seconds / 60);
//   const hours = Math.floor(minutes / 60);
//   const days = Math.floor(hours / 24);
//   const weeks = Math.floor(days / 7);
//   const months = Math.floor(days / 30);
//   const years = Math.floor(days / 365);

//   if (years > 0) {
//     return `${years} year${years > 1 ? "s" : ""} ago`;
//   } else if (months > 0) {
//     return `${months} month${months > 1 ? "s" : ""} ago`;
//   } else if (weeks > 0) {
//     return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
//   } else if (days > 0) {
//     return `${days} day${days > 1 ? "s" : ""} ago`;
//   } else if (hours > 0) {
//     return `${hours} hour${hours > 1 ? "s" : ""} ago`;
//   } else if (minutes > 0) {
//     return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
//   } else {
//     return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
//   }
// }

export const phoneValidator = {
  validator: (_, value) => {
    if (value && value.slice(0, 2) !== "+1") {
      return Promise.reject("Phone number should start with +1");
    } else if (/\s/g.test(value)) {
      return Promise.reject("Spaces are not allowed");
    } else if (isNaN(value?.slice(1))) {
      return Promise.reject("Please provide a valid phone number");
    } else if (value?.length !== 12) {
      return Promise.reject("Phone number must be 11 digit");
    } else {
      return Promise.resolve();
    }
  },
};

export const numberValidator = (message = "", success = null, error = null) => {
  return {
    message,
    validator: (_, value) => {
      if (value && (/\s/g.test(value) || isNaN(value))) {
        return Promise.reject(error);
      }
      return Promise.resolve(success);
    },
  };
};

export const numberShouldNotBeZero = (
  message = "",
  success = null,
  error = null
) => {
  return {
    message,
    validator: (_, value) => {
      if (value <= 0 || value?.toString()?.slice(0, 1) <= 0) {
        return Promise.reject(error);
      }
      return Promise.resolve(success);
    },
  };
};

export const roles = [
  { label: "Driver", value: "12" },
  { label: "Warehouse Coordinator", value: "13" },
  { label: "Dispatcher", value: "14" },
];

export const rules = [
  { label: "Driver", value: "1" },
  { label: "Dispatcher", value: "2" },
  { label: "Management Team", value: "3" },
];

export const customerType = [
  { label: "Freight Customer", value: 1 },
  { label: "Washout Customer", value: 19 },
  { label: "YardMove Customer", value: 12 },
  { label: "Shop Customer", value: 13 },
  { label: "Hotel Customer", value: 14 },
];

export const statuses = {
  PREPLAN: "PrePlan",
  REQUESTED: "Requested",
  DISPATCHED: "Dispatched",
  INPROGRESS: "InProgress",
  COMPLETED: "Completed",
};

// export const statuses = [
//   { label: "Requested", value: "Requested" },
//   { label: "Dispatched", value: "Dispatched" },
//   { label: "In Progress", value: "InProgress" },
//   { label: "Completed", value: "Completed" },
// ];

// export const moveTypes = [
//   {
//     key: "3",
//     value: "Fuel",
//   },
//   {
//     key: "5",
//     value: "Washout",
//   },
//   {
//     key: "4",
//     value: "Repair/Shop",
//   },
//   {
//     key: "7",
//     value: "Initial Spot",
//   },
//   {
//     key: "18",
//     value: "Partial Pull",
//   },
//   {
//     key: "35",
//     value: "Partial Spot",
//   },
//   {
//     key: "8",
//     value: "Complete Pull",
//   },
// ];

export const paymentOptions = [
  {
    label: "Cash",
    value: "2",
  },
  {
    label: "Credit Card",
    value: "3",
  },
  {
    label: "Check",
    value: "4",
  },
  {
    label: "Fleet Check",
    value: "5",
  },
];

export const loadLock = [1, 2, 3, 4, 5];

export const fuelLevel = [
  {
    key: 13,
    value: "E",
  },
  {
    key: 14,
    value: "1/4",
  },
  {
    key: 15,
    value: "1/2",
  },
  {
    key: 25,
    value: "3/4",
  },
  {
    key: 16,
    value: "F",
  },
];

export const tableTabs = [
  {
    ind: 0,
    title: "Pre Plan",
    dataIndex: "PrePlan",
  },
  {
    ind: 1,
    title: "Pending",
    dataIndex: "Requested",
  },
  {
    ind: 2,
    title: "Assigned",
    dataIndex: "Dispatched",
  },
  {
    ind: 3,
    title: "In Progress",
    dataIndex: "InProgress",
  },
  {
    ind: 4,
    title: "Completed",
    dataIndex: "Completed",
  },
  {
    ind: 5,
    title: "All",
    dataIndex: "onGoing",
  },
];

export const addAdditionalPropsToCell = (columns, props) => {
  return [...columns(props)].map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (row, rowIndex) => {
        let name = [rowIndex, col.dataIndex];
        let fieldReference = [props.listName ?? "", ...name];

        return {
          ...col,
          name,
          fieldReference,
          editing: true,
          disabled: col.disabled
            ? col.disabled
            : row?.ID && row?.ID !== props.editingKey,
          onChange: (value) => col.onChange?.({ name, value }),
        };
      },
    };
  });
};

export const toCurreny = (amount) => {
  let Currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return Currency.format(+amount);
};

export const getIframeSource = (name) => {
  return FILE_URL + name;
};
