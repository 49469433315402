import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { setChartOfAccounts, setDocumentTypes } from "../reducers/account";

import { asyncAlertError } from "./common";
import { setLocalLoading } from "../reducers/common";

export const asyncCreateOrderLineItem = createAsyncThunk(
  "account/create/lineItem",
  async ({ yardMoveID, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `yardLineItem/${yardMoveID}`,
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncUpdateOrderLineItem = createAsyncThunk(
  "account/update/lineItem",
  async ({ ID, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));
      const token = getState().auth.token;

      const res = await callApi({
        path: `yardLineItem/${ID}`,
        method: "PUT",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncCreateDocument = createAsyncThunk(
  "account/document",
  async ({ yardMoveID, body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `yardInvoice/document/${yardMoveID}`,
        method: "POST",
        body,
        isForm: true,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncCreateInvoice = createAsyncThunk(
  "account/invoice/create",
  async ({ yardMoveID, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `yardInvoice/${yardMoveID}`,
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncCreatePaymentReceipt = createAsyncThunk(
  "account/paymentReceipt/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `paymentReceipt`,
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncGetAllDocumentTypes = createAsyncThunk(
  "account/document/types",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: `yardInvoice/doctypes`,
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setDocumentTypes(res.data));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

export const asyncGetAllChartOfAccounts = createAsyncThunk(
  "account/account/types",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: `yardInvoice/accounts`,
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setChartOfAccounts(res.data));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);

export const asyncSendInvoice = createAsyncThunk(
  "account/invoice/send",
  async ({ invoiceID, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `yardInvoice/email/${invoiceID}`,
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncDeleteLineItem = createAsyncThunk(
  "account/document/delete",
  async (lineID, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `yardLineItem/${lineID}`,
        method: "DELETE",
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);

export const asyncDeleteDocument = createAsyncThunk(
  "account/document/delete",
  async (docId, { dispatch, getState }) => {
    try {
      dispatch(setLocalLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `document/${docId}`,
        method: "DELETE",
        token,
      });

      if (res.success) {
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalLoading(false));
    }
  }
);
