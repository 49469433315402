import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  users: [],
  usersPair: {},
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setUsers: (state, { payload }) => {
      const userKeyPair = payload?.reduce((acc, curr) => {
        return { ...acc, [curr.id]: { name: curr.name, role: curr.role } };
      }, {});

      state.users = payload;
      state.usersPair = userKeyPair;
    },
  },
});

export const { setUser, setUsers } = UserSlice.actions;
export default UserSlice.reducer;
