import { Row, Space } from "antd"
import React, { memo, useEffect, useRef, useState } from "react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import AppButton from "../Button"
import AppModal from "../Modal"
import send from "../../Assets/icons/sendMessage.svg"
import { getIframeSource } from "../../utils"
import { NotFound } from "../NotFound"
import { Loader } from "../Loader"

export const FileViewer = memo(({ open, src, onCancel, onClickSend }) => {
  const iframeRef = useRef(null)
  const [isError, setIsError] = useState(false)
  const [iframeLoader, setIframeLoader] = useState(true)

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.style.height = "80dvh"
    }
  }, [src?.uid, iframeLoader])

  useEffect(() => {
    setTimeout(() => {
      setIframeLoader(false)
    }, 1000)
  }, [])

  return (
    <AppModal
      open={open}
      minWidth="60%"
      minHeight="90%"
      title=" "
      headerIcon={
        <Row justify="end" align="middle">
          <Space className="mr-10">
            {!isError && !iframeLoader && onClickSend && (
              <AppButton
                onClick={onClickSend}
                icon={
                  <img src={send} alt="img" className="mr-5" width="18px" />
                }
                title="Send Invoice"
                className="btn_dark"
              />
            )}
          </Space>
          <AiOutlineCloseCircle size={24} onClick={onCancel} cursor="pointer" />
        </Row>
      }
    >
      {iframeLoader ? (
        <div style={{ height: "80dvh", width: "100%" }}>
          <Loader />
        </div>
      ) : isError ? (
        <NotFound title={"Error"} desc={"Failed to load Document"} />
      ) : (
        <iframe
          onLoad={(e) => {
            if (!e?.target?.contentWindow?.length) {
              setIsError(true)
            }
          }}
          ref={iframeRef}
          loading="lazy"
          src={getIframeSource(src?.name)}
          title="file"
          width="100%"
          height="80dvh"
          style={{ border: "unset" }}
        />
      )}
    </AppModal>
  )
})
