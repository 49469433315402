import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import React, { memo } from "react";
import AppModal from ".";
import AppButton from "../Button";
import AppInput from "../Input";
import AppSelect from "../Select";

export const Trailer = memo(
  ({
    open,
    title,
    btnTitle,
    onCancel,
    onClick,
    customers,
    carriers,
    isCoordinator = false,
  }) => {
    return (
      <AppModal open={open} title={title}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="Carrier"
              rules={[
                {
                  required: true,
                  message: ``,
                },
              ]}
            >
              <AppSelect
                title="Carrier"
                size="large"
                data={carriers.map((carrier) => ({
                  value: carrier.ID,
                  label: carrier.Name,
                }))}
                disabled={!carriers.length}
              />
            </Form.Item>
          </Col>
          {!isCoordinator && (
            <Col span={24}>
              <Form.Item name="Customer">
                <AppSelect
                  title="Customer"
                  size="large"
                  data={customers.map((customer) => ({
                    value: customer.CustomerID,
                    label: customer.Customer_Name,
                  }))}
                  disabled={!customers.length}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              name="UnitNo"
              rules={[
                {
                  required: true,
                  message: ``,
                },
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="Unit No #"
                placeholder="Unit no"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <AppButton
            icon={<MinusOutlined style={{ fontSize: 18 }} />}
            title="Discard"
            width="120px"
            height="max-content"
            fontSize="16px"
            className="btn_red"
            onClick={onCancel}
          />
          <AppButton
            icon={<PlusOutlined style={{ fontSize: 18 }} />}
            title={btnTitle}
            width="120px"
            height="max-content"
            fontSize="16px"
            className="btn_green mr-10"
            onClick={onClick}
          />
        </Row>
      </AppModal>
    );
  }
);
