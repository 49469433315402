import { Col, Divider, Row, Space } from "antd"
import React, { useEffect, useState } from "react"
import AppModal from "."
import logo from "../../Assets/images/logo.png"
import { BsTelephone } from "react-icons/bs"
import { MdOutlineEmail } from "react-icons/md"
import { AiOutlineCloseCircle } from "react-icons/ai"
import styles from "./style.module.scss"
import { useLocation } from "react-router-dom"
import { toCurreny } from "../../utils"
import clsx from "classnames"
import AppButton from "../Button"
// import send from "../../Assets/icons/sendMessage.svg"
import Print from "../../Assets/icons/Print.svg"

const InvoiceTable = ({ invoiceLineItems, TotalAmount }) => {
  // const [lineItemData, setLineItemData] = useState([]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLineItemData(invoiceLineItems);
  //   }, 10000);
  // }, [invoiceLineItems]);

  return (
    <table>
      <colgroup>
        <col span="1" />
        <col span="3" style={{ backgroundColor: "#D1D5DB" }} />
      </colgroup>
      <thead>
        <tr>
          <th>Item description</th>
          <th>Qty</th>
          <th>Rate</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {/* {!lineItemData.length ? (
          <tr className={styles.invoice}>
            <td colSpan={3}>{"Loading"}</td>
          </tr>
        ) : ( */}
        {invoiceLineItems?.map((item) => (
          <tr key={item.ID} className={styles.invoice}>
            <td>{item?.service?.Service}</td>
            <td>{item.Quantity}</td>
            <td>{toCurreny(item.Rate)}</td>
            <td>{toCurreny(item.Quantity * +item.Rate)}</td>
          </tr>
        ))}
        {/* )} */}
        <tr>
          <td></td>
          <td className="text-xs font-600 text-5">Sub Total</td>
          <td></td>
          <td className="font-700">{toCurreny(TotalAmount)}</td>
        </tr>
        <tr className={clsx(styles.trHeighlight, styles.invoice)}>
          <td></td>
          <td className="text-xs">Total</td>
          <td></td>
          <td className="font-800 text-base">{toCurreny(TotalAmount)}</td>
        </tr>
      </tbody>
    </table>
  )
}

const PaymentTable = ({ paymentReceipts, TotalAmount, PaidAmount }) => {
  let newTotal = 0

  return (
    <table>
      <colgroup>
        <col span="2" />
        <col span="3" />
        <col span="2" style={{ backgroundColor: "#D1D5DB" }} />
      </colgroup>
      <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Check Ref</th>
          <th>Deposit To</th>
          <th>Payment Type</th>
          <th>Amount</th>
          <th>Amout Paid</th>
        </tr>
      </thead>
      <tbody>
        {paymentReceipts?.map((item, ind) => {
          let updateVal = newTotal

          newTotal = (newTotal || TotalAmount) - +item?.AmountPaid || 0

          return (
            <tr key={item.ID} className={styles.payment}>
              <td>{item?.ID}</td>
              <td>{item?.PaymentDate}</td>
              <td>{item?.CheckRef}</td>
              <td>{item?.depositTo?.AccountName}</td>
              <td>{item?.paymentType?.Name}</td>
              <td>{toCurreny(ind ? updateVal : TotalAmount)}</td>
              <td>{toCurreny(item.AmountPaid)}</td>
            </tr>
          )
        })}
        <tr className={clsx(styles.trHeighlight, styles.payment)}>
          <td colSpan={4}></td>
          <td className="text-xs">Balance</td>
          <td></td>
          <td className="font-800 text-base">
            {toCurreny(TotalAmount - PaidAmount)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const InvoiceModal = ({
  record,
  documents,
  open,
  onClick,
  // handleSend,
  handlePrint,
}) => {
  const location = useLocation()
  const isInvoice = location.pathname.includes("/invoice")

  const [invDoc, setInvDoc] = useState({})

  const customer = record?.customer
  const invoice = record?.invoice

  useEffect(() => {
    setTimeout(() => {
      let inv = documents?.find((doc) => {
        return isInvoice
          ? doc?.Link?.includes(record?.ID + "" + record?.invoice?.ID)
          : doc?.Link?.includes(`summary_receipt_${record?.invoice?.ID}`)
      })
      setInvDoc((_) => inv)
    }, 1000)
  }, [documents, isInvoice, record?.ID, record?.invoice?.ID])

  return (
    <AppModal
      title={isInvoice ? `Invoice #${invoice.ID}` : `Payment Receipt`}
      open={open}
      headerIcon={
        <Row justify="end" align="middle">
          <Space className="mr-10">
            {/* <AppButton
              onClick={() => handleSend(invDoc?.ID)}
              icon={<img src={send} alt="img" className="mr-5" width="18px" />}
              title="Send Invoice"
              className="btn_dark"
            /> */}
            <AppButton
              onClick={() =>
                invDoc?.ID &&
                handlePrint({ ID: invDoc?.ID, link: invDoc?.link })
              }
              icon={<img src={Print} alt="img" className="mr-5" width="20px" />}
              title="Print Invoice"
              className="btn_dark"
            />
          </Space>
          <AiOutlineCloseCircle size={24} onClick={onClick} cursor="pointer" />
        </Row>
      }
      minWidth={isInvoice ? "40%" : "50%"}
      footer={
        <Row className={styles.modalFooter}>
          <h4 className="text-base font-600 dark">Osceola Truck wash</h4>
          <p className="text-xs font-500">
            805 Warren Avenue, Osceola, IA 50213 +1 641-342-6555
          </p>
        </Row>
      }
    >
      <Row justify="space-between">
        <img src={logo} alt="logo" width="166px" height="70px" />
        <div className="w-40" style={{ textAlign: "right" }}>
          <h6 className="text-sm font-600">Osceola Truck Wash</h6>
          <p className="text-2xs info font-500 mt-5">
            805 Warren Avenue OSCEOLA, IOWA 50213
          </p>
          <Row justify="end" className="mt-5 item-center">
            <p className="text-2xs info font-500 mr-5">(641) 342-6555</p>
            <BsTelephone />
          </Row>
          <Row justify="end" className="mt-5 item-center">
            <p className="text-2xs info font-500 mr-5">
              accounting@osceolatruckwash.com
            </p>
            <MdOutlineEmail size={16} />
          </Row>
        </div>
      </Row>
      <Divider style={{ background: "#BCB0C4" }} />

      {isInvoice && (
        <>
          <h4 className="text-xl primary font-600 mb-5 mt-20">
            Invoice Details
          </h4>
          <Row justify="space-between">
            <Space>
              <div>
                <p className="text-sm text-5">Invoice #</p>
                <h6 className="text-sm font-600">#{invoice?.ID}</h6>
              </div>
              <div className="ml-20">
                <p className="text-sm text-5">Date</p>
                <h6 className="text-sm font-600">{invoice?.InvoiceDate}</h6>
              </div>
            </Space>
            <Space>
              <div>
                <p className="text-sm text-5">Due Date</p>
                <h6 className="text-sm font-600">{invoice?.InvoiceDueDate}</h6>
              </div>
            </Space>
          </Row>
        </>
      )}

      {!isInvoice && (
        <div>
          <h4 className="text-xl primary font-600 mb-5 mt-20">Invoice</h4>
          <Row justify="space-between">
            <Col span={6}>
              <p className="text-sm text-5">Invoice #</p>
              <h6 className="text-base font-600">{invoice?.ID || "N/A"}</h6>
            </Col>
            <Col span={8}>
              <p className="text-sm text-5">Invoice Date</p>
              <h6 className="text-base font-600">
                {invoice?.InvoiceDate || "N/A"}
              </h6>
            </Col>
            <Col span={8}>
              <p className="text-sm text-5">Total Amount</p>
              <h6 className="text-base font-600">
                {toCurreny(invoice?.TotalAmount) || "N/A"}
              </h6>
            </Col>
          </Row>
        </div>
      )}

      {isInvoice && (
        <div className="mt-20">
          <Row justify="space-between">
            <Col span={6}>
              <p className="text-sm text-5">Move ID</p>
              <h6 className="text-base font-600">{record?.ID || "N/A"}</h6>
            </Col>
            <Col span={8}>
              <p className="text-sm text-5">Trip</p>
              <h6 className="text-base font-600">
                {record?.AssignedOrderRef || "N/A"}
              </h6>
            </Col>
            <Col span={8}>
              <p className="text-sm text-5">Trailer</p>
              <h6 className="text-base font-600">
                #{record?.trailer?.UnitNo || "N/A"}
              </h6>
            </Col>
          </Row>
        </div>
      )}

      <div>
        <h4 className="text-xl primary font-600 mb-5 mt-20">Customer</h4>
        <Row justify="space-between">
          <Col span={6}>
            <p className="text-sm text-5">Billed to,</p>
            <h6 className="text-base font-600">{customer?.name || "N/A"}</h6>
          </Col>
          <Col span={8}>
            <p className="text-sm text-5">Email</p>
            <h6 className="text-base font-600">
              {customer?.Billing_Email || "N/A"}
            </h6>
          </Col>
          <Col span={8}>
            <p className="text-sm text-5">Address</p>
            <h6 className="text-base font-600">
              {customer?.Customer_Address || "N/A"}
            </h6>
          </Col>
        </Row>
      </div>
      <h4 className="text-xl primary font-600 mb-5 mt-20">
        {isInvoice ? "Services" : "Receipt"}
      </h4>
      <div className={styles.invoiceTable}>
        {isInvoice && (
          <InvoiceTable
            invoiceLineItems={invoice?.invoiceLineItems}
            TotalAmount={+invoice?.TotalAmount}
          />
        )}
        {!isInvoice && (
          <PaymentTable
            paymentReceipts={invoice?.paymentReceipts}
            TotalAmount={+invoice?.TotalAmount}
            PaidAmount={+invoice?.PaidAmount}
          />
        )}
      </div>

      {!isInvoice && (
        <div className={styles.paymentReceipt}>
          <h2 className="font-600 text-2xl">
            Amount paid {toCurreny(invoice?.PaidAmount)}
          </h2>
        </div>
      )}
    </AppModal>
  )
}

export default InvoiceModal
