import React from "react";
import { Badge, Button } from "antd";
import styles from "./style.module.scss";
import classnames from "classnames";

const AppButton = ({
  title,
  width,
  height,
  color,
  backgroundColor,
  margin,
  borderRadius,
  onClick,
  borderBottom,
  border,
  borderRight,
  fontWeight,
  fontSize,
  padding,
  count,
  badgeBg,
  badgeColor,
  className,
  children,
  ...rest
}) => {
  return (
    <Button
      style={{
        width,
        height,
        color,
        backgroundColor,
        margin,
        borderRadius,
        borderBottom,
        // border,
        borderRight,
        fontWeight,
        fontSize,
        padding,
      }}
      {...rest}
      onClick={onClick}
      className={classnames(styles.btn, className, "app-btn")}
    >
      {title}
      {children}
      {count >= 0 && (
        <Badge
          className="ml-10 shadow-1"
          style={{ backgroundColor: badgeBg, color: badgeColor }}
          showZero
          count={count}
        />
      )}
    </Button>
  );
};

export default AppButton;
