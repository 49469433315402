import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Drawer, Form, Row, Tag } from "antd";
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import addCust from "../../Assets/icons/add-user.png";
import addTrai from "../../Assets/icons/trailerW.png";
import truck from "../../Assets/icons/truck.png";
import AppButton from "../../Components/Button";
import AppDrawer from "../../Components/Drawer";
import { FilterSelect } from "../../Components/Filters/FilterSelect";
import { SearchDate } from "../../Components/Filters/SeachDate";
import { SearchInput } from "../../Components/Filters/SearchInput";
import Messages from "../../Components/Messages";
import { Customer } from "../../Components/Modal/Customer";
import { Trailer } from "../../Components/Modal/Trailer";
import { YardMove } from "../../Components/Modal/YardMove";
import { DataTable } from "../../Components/Table";
import {
  asyncCreateCustomer,
  asyncGetAllCustomers,
} from "../../store/actions/customer";
import {
  asyncGetAllActiveDrivers,
  asyncGetAllDrivers,
} from "../../store/actions/driver";
import {
  asyncCreateRequest,
  asyncGetAllMoveTypes,
  asyncGetAllRequests,
} from "../../store/actions/request";
import {
  asyncCreateTrailer,
  asyncGetAllTrailers,
} from "../../store/actions/trailer";
import { asyncGetAllUsers } from "../../store/actions/user";
import { setSelected } from "../../store/reducers/request";
import { checkValue, formatDate, statuses, tableTabs } from "../../utils";

const YardMoveNew = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filters, setFilters] = useState({});
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [showCustomer, setShowCustomer] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false);
  const [showYardMove, setShowYardMove] = useState(false);
  const [loader, setLoader] = useState(false);
  const [
    resizePageSize,
    // , setPageSize
  ] = useState(5);

  const { user } = useSelector((s) => s.user);
  const { dataSource, moveTypes, selected } = useSelector((s) => s.request);
  const { customers } = useSelector((s) => s.customer);
  const { activeDrivers, drivers } = useSelector((s) => s.driver);
  const { trailers } = useSelector((s) => s.trailer);
  const { notification } = useSelector((s) => s.common);

  let selectedCarrier = Form.useWatch("Carrier", form);

  const loadData = useCallback(async () => {
    setLoader(true);
    await dispatch(asyncGetAllRequests()).unwrap();
    await dispatch(asyncGetAllMoveTypes()).unwrap();
    await dispatch(asyncGetAllCustomers()).unwrap();
    await dispatch(asyncGetAllTrailers()).unwrap();
    await dispatch(asyncGetAllUsers()).unwrap();

    if (+user.role === 14) {
      await dispatch(asyncGetAllActiveDrivers()).unwrap();
      await dispatch(asyncGetAllDrivers()).unwrap();
    }

    setLoader(false);
  }, [dispatch, user?.role]);

  let driverList = useMemo(() => {
    let { YardMoveStatus } = selected;
    return YardMoveStatus === statuses.COMPLETED ? drivers : activeDrivers;
  }, [selected, activeDrivers, drivers]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  let { title: tableName, dataIndex: tableDataIndex } =
    tableTabs.find((tab) => tab.ind === tabIndex) || "";

  useEffect(() => {
    if (notification?.RequestID) {
      if (notification?.Type === statuses.COMPLETED) {
        setFilters((prev) => {
          let prevFilters = { ...prev };
          delete prevFilters[tableName];
          return {
            ...prevFilters,
            [tableName]: {
              ID: notification?.RequestID,
            },
          };
        });
        setTabIndex(0);
      }
    }
  }, [dispatch, tableName, notification]);

  // const handelResizeTable = (size) => {
  //   let newPageSize = 5;
  //   // adjust the newPageSize based on the screenHeight if needed

  //   if (size >= 100) {
  //     newPageSize = 16;
  //   } else if (size >= 72) {
  //     newPageSize = 10;
  //   } else if (size >= 68) {
  //     newPageSize = 9;
  //   } else if (size >= 62) {
  //     newPageSize = 8;
  //   } else if (size >= 58) {
  //     newPageSize = 7;
  //   } else if (size >= 52) {
  //     newPageSize = 6;
  //   } else if (size >= 50) {
  //     newPageSize = 5;
  //   }
  //   setPageSize(newPageSize);
  // };

  let tableData =
    dataSource[tableDataIndex]?.filter(
      ({
        ID,
        AssignedOrderRef,
        trailer,
        Door,
        YardMoveRequestDateTime,
        YardMoveStatus,
        Carrier,
        YardTractorMoveType,
      }) => {
        let SF = filters[tableName];
        let row = [];

        let militaryDate = !YardMoveRequestDateTime
          ? null
          : formatDate(YardMoveRequestDateTime).split(" - ")[0];

        let militaryDate1 = !SF?.YardMoveRequestDateTime
          ? null
          : formatDate(SF?.YardMoveRequestDateTime).split(" - ")[0];

        row.push(checkValue(SF?.ID, ID));
        row.push(checkValue(SF?.AssignedOrderRef, AssignedOrderRef));
        row.push(checkValue(SF?.Trailer, trailer?.UnitNo));
        row.push(checkValue(SF?.Door, Door));
        row.push(checkValue(SF?.YardMoveStatus, YardMoveStatus, true));
        row.push(checkValue(SF?.Carrier, Carrier, true));
        row.push(
          checkValue(SF?.YardTractorMoveType, YardTractorMoveType, true)
        );
        row.push(checkValue(militaryDate1, militaryDate, true));

        if (row.every(Boolean)) {
          return true;
        } else {
          return false;
        }
      }
    ) || [];

  let filteredTrailers =
    trailers?.filter((trailer) => trailer.Customer === selectedCarrier) || [];

  const defaultColumns = [
    {
      title: "Move ID",
      dataIndex: "ID",
      width: 90,
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "YardMoveRequestDateTime",
      width: 100,
      render: (
        _,
        {
          YardMoveRequestDateTime,
          YardMoveDispatchDateTime,
          YardMoveCompleteDateTime,
          YardMoveStartDateTime,
          YardMoveStatus,
          // ...record
        }
      ) => {
        let date = "";
        if (YardMoveStatus === statuses.REQUESTED) {
          date = YardMoveRequestDateTime;
        } else if (YardMoveStatus === statuses.DISPATCHED) {
          date = YardMoveDispatchDateTime;
        } else if (YardMoveStatus === statuses.INPROGRESS) {
          date = YardMoveStartDateTime;
        } else if (YardMoveStatus === statuses.COMPLETED) {
          date = YardMoveCompleteDateTime;
        }

        // if ("key" in record) {
        //   return formatDate(date);
        // }

        return formatDate(date);
        // return formatDate(getGmtMinus5(date));
      },
    },
    {
      title: "Status",
      align: "center",
      width: 100,
      dataIndex: "YardMoveStatus",
      render: (status) => {
        let color = "";
        switch (status) {
          case statuses.REQUESTED:
            color = "#D9D9D9";
            break;
          case statuses.DISPATCHED:
            color = "#0055CC";
            break;
          case statuses.INPROGRESS:
            color = "#9B57F2";
            break;
          case statuses.COMPLETED:
            color = "#047857";
            break;
          default:
            color = "#D9D9D9";
        }

        return (
          <Tag
            color={color}
            style={{
              fontSize: 11,
              width: 100,
              margin: "auto",
              padding: "3px 8px",
              borderRadius: 4,
            }}
          >
            {status === statuses.DISPATCHED
              ? "ASSIGNED"
              : status?.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "Carrier",
      width: 140,
      render: (_, { carrier }) => carrier?.name?.toUpperCase() || "N/A",
    },
    {
      title: "Move Type",
      dataIndex: "YardTractorMoveType",
      width: 110,
      render: (_, { YardMoveType }) => {
        return YardMoveType?.Service?.toUpperCase() || "N/A";
      },
    },
    {
      title: "Trip",
      dataIndex: "AssignedOrderRef",
      width: 80,
      render: (AssignedOrderRef) => {
        return AssignedOrderRef || "N/A";
      },
    },
    {
      title: "Trailer",
      dataIndex: "Trailer",
      render: (_, row) => {
        return row.trailer?.UnitNo || "N/A";
      },
      width: 80,
    },
    {
      title: "Door",
      dataIndex: "Door",
      width: 80,
      render: (Door) => {
        return Door || "N/A";
      },
    },
    {
      title: "Temp",
      dataIndex: "SetPointTemp",
      width: 80,
      render: (SetPointTemp) => {
        return SetPointTemp || "N/A";
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
      dataIndex: "action",
      render: (_, action) => {
        return (
          <Row align="middle" justify="end">
            <EllipsisOutlined
              style={{
                fontSize: 30,
                color: "#000",
                marginLeft: 10,
                marginRight: 10,
              }}
              onClick={() => {
                dispatch(setSelected(action));
                setOpen(true);
              }}
            />
          </Row>
        );
      },
    },
  ];

  // Chat Map Drawer
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };
  // YardMove Create
  const handleSave = async () => {
    setLoader(true);
    try {
      const row = await form.validateFields();

      const res = await dispatch(asyncCreateRequest(row)).unwrap();
      if (res.success) {
        resetFormModal();
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  // Customer & Trailer Create
  const handleSubmit = async (values) => {
    let res = {};

    if (showCustomer) {
      res = await dispatch(
        asyncCreateCustomer({
          ...values,
          Customer_Type: 12,
          Table: "customer",
        })
      ).unwrap();
    } else {
      res = await dispatch(
        asyncCreateTrailer({
          ...values,
        })
      ).unwrap();
    }

    if (res.success) {
      resetFormModal();
      setShowYardMove(true);
    }
  };

  const defaultStyle = {
    color: "var(--color-dark)",
    backgroundColor: "transparent",
    fontWeight: "600",
    fontSize: "14px",
    borderBottom: "2px solid transparent",
    badgeBg: "var(--color-slate-3)",
    badgeColor: "var(--color-dark)",
  };

  const activeStyle = {
    color: "var(--color-primary)",
    backgroundColor: "transparent",
    fontWeight: "600",
    fontSize: "14px",
    borderBottom: "2px solid var(--color-primary)",
    badgeBg: "var(--color-primary-light)",
    badgeColor: "var(--color-white)",
  };

  const debounceSearch = useRef(
    debounce(({ tableName, key, value }) => {
      setFilters((prev) => {
        return {
          ...prev,
          [tableName]: {
            ...prev[tableName],
            [key]: value,
          },
        };
      });
    }, 300)
  ).current;

  const handleFilterChange = (props) => {
    debounceSearch(props);
  };

  const handleClearFilters = () => {
    const existingFilters = { ...filters };
    if (filters[tableName]) {
      delete existingFilters[tableName];
      setFilters(existingFilters);
    }
  };

  const resetFormModal = () => {
    setShowTrailer(false);
    setShowCustomer(false);
    setShowYardMove(false);
    form.resetFields();
    modalForm.resetFields();
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <div className="head">
          <Row justify="end">
            <Col>
              <AppButton
                onClick={handleClearFilters}
                title="Clear All "
                backgroundColor="transparent"
                className="text-sm font-600 primary"
                padding="0"
              />
            </Col>
          </Row>
          <Row style={{ alignItems: "end", margin: 0 }} gutter={5}>
            <Col span={3}>
              <SearchInput
                tabIndex={tabIndex}
                tableName={tableName}
                title="Move ID"
                dataIndex="ID"
                value={filters[tableName]?.ID}
                onChange={handleFilterChange}
              />
            </Col>
            <Col span={3}>
              <SearchDate
                tabIndex={tabIndex}
                tableName={tableName}
                title="Date"
                dataIndex="YardMoveRequestDateTime"
                value={filters[tableName]?.YardMoveRequestDateTime}
                onChange={handleFilterChange}
              />
            </Col>
            <Col span={5}>
              <FilterSelect
                icon={<UserOutlined style={{ fontSize: 16 }} />}
                tabIndex={tabIndex}
                tableName={tableName}
                placeholder="Search"
                title="Customer"
                dataIndex="Carrier"
                value={filters[tableName]?.Carrier}
                onChange={handleFilterChange}
                options={customers.map((customer) => ({
                  value: customer.CustomerID,
                  label: customer.Customer_Name,
                }))}
              />
            </Col>
            <Col span={4}>
              <FilterSelect
                img={truck}
                tabIndex={tabIndex}
                tableName={tableName}
                placeholder="Search"
                title="Move Type"
                dataIndex="YardTractorMoveType"
                value={filters[tableName]?.YardTractorMoveType}
                onChange={handleFilterChange}
                options={moveTypes.map((type) => ({
                  value: type.ID,
                  label: type.Name,
                }))}
              />
            </Col>
            <Col span={3}>
              <SearchInput
                tabIndex={tabIndex}
                tableName={tableName}
                title="Trip"
                dataIndex="AssignedOrderRef"
                value={filters[tableName]?.AssignedOrderRef}
                onChange={handleFilterChange}
              />
            </Col>
            <Col span={3}>
              <SearchInput
                tabIndex={tabIndex}
                tableName={tableName}
                title="Trailer"
                dataIndex="Trailer"
                value={filters[tableName]?.Trailer}
                onChange={handleFilterChange}
              />
            </Col>
            <Col span={3}>
              <SearchInput
                tabIndex={tabIndex}
                tableName={tableName}
                title="Door"
                dataIndex="Door"
                value={filters[tableName]?.Door}
                onChange={handleFilterChange}
              />
            </Col>
          </Row>
          <Row className="yard-move-clone-row">
            <Col span={17}>
              <Row>
                {tableTabs.map((item, ind) => (
                  <AppButton
                    key={item.ID}
                    type="primary"
                    size="large"
                    title={item.title}
                    height="45px"
                    padding=""
                    width="max-content"
                    borderRadius="0px"
                    count={dataSource[item.dataIndex]?.length || 0}
                    onClick={() => setTabIndex(ind)}
                    {...(tabIndex === ind ? activeStyle : defaultStyle)}
                  />
                ))}
              </Row>
            </Col>
            <Col span={7}>
              <Row justify="end" className="my-15">
                {tabIndex === 0 && (
                  <div style={{ display: "flex" }}>
                    <AppButton
                      type="primary"
                      size="large"
                      width="max-content"
                      height="33px"
                      fontSize="14px"
                      className="ml-10"
                      backgroundColor="var(--color-success-dark)"
                      title="+ Request Move"
                      onClick={() => setShowYardMove(true)}
                    />
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </div>

        {tableTabs.map((_, ind) => {
          return tabIndex === ind ? (
            <DataTable
              key={tabIndex + ind}
              form={form}
              dataSource={tableData}
              columns={defaultColumns}
              loader={loader}
              pageSizeCount={resizePageSize}
            />
          ) : null;
        })}
        <AppButton
          title="Open Darwer"
          onClick={showDrawer}
          width="150px"
          color="white"
          margin="10px 0"
        />
      </div>
      <Drawer
        title="Messages"
        placement="right"
        onClose={onClose}
        open={openDrawer}
        width="90%"
      >
        <Messages />
      </Drawer>
      <AppDrawer
        open={open}
        onClose={() => setOpen(false)}
        setLoader={setLoader}
        loader={loader}
        user={user}
        drivers={driverList}
        customers={customers}
        trailers={trailers}
        moveTypes={moveTypes}
      />

      <Form
        form={form}
        name="publish"
        layout="vertical"
        onFinish={handleSave}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <YardMove
          open={showYardMove}
          customers={customers}
          moveTypes={moveTypes}
          filteredTrailers={filteredTrailers}
          selectedCarrier={selectedCarrier}
          form={form}
          onCancel={resetFormModal}
          onClick={() => form.submit()}
          title="New Yard Move"
          btnTitle="Save"
          headerIcon={
            <div style={{ display: " flex" }}>
              <AppButton
                type="primary"
                size="large"
                width="max-content"
                height="33px"
                fontSize="14px"
                backgroundColor="var(--color-primary-dark)"
                onClick={() => {
                  resetFormModal();
                  setShowCustomer(true);
                }}
                icon={<img src={addCust} alt="" style={{ width: 20 }} />}
              />
              <AppButton
                type="primary"
                size="large"
                width="max-content"
                height="33px"
                fontSize="14px"
                backgroundColor="var(--color-dark-80)"
                className="ml-10"
                onClick={() => {
                  resetFormModal();
                  setShowTrailer(true);
                }}
                icon={<img src={addTrai} alt="" style={{ width: 20 }} />}
              />
            </div>
          }
        />
      </Form>

      <Form
        form={modalForm}
        name="publish"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        {showCustomer && (
          <Customer
            open={showCustomer}
            onCancel={resetFormModal}
            onClick={() => modalForm.submit()}
            title="Add Customer"
            btnTitle="Save"
          />
        )}
        {showTrailer && (
          <Trailer
            open={showTrailer}
            customers={customers}
            onCancel={resetFormModal}
            onClick={() => modalForm.submit()}
            title="Add Trailer"
            btnTitle="Save"
          />
        )}
      </Form>
    </>
  );
};

export default YardMoveNew;
