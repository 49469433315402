import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { setTrailers } from "../reducers/trailer";
import { asyncAlertError } from "./common";
import { setLocalAdminLoading } from "../reducers/common";

export const asyncCreateTrailer = createAsyncThunk(
  "trailer/create",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "trailer",
        method: "POST",
        body,
        token,
      });

      if (res.success) {
        // dispatch(asyncAlertSuccess("Trailer Successfully Created!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncUpdateTrailer = createAsyncThunk(
  "trailer/update",
  async ({ ID, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const { token } = getState().auth;

      const res = await callApi({
        path: `admin/trailer/update/${ID}`,
        method: "PUT",
        body,
        token,
      });

      if (res.success) {
        // dispatch(asyncAlertSuccess("Trailer Successfully updated!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);

export const asyncGetAllTrailers = createAsyncThunk(
  "trailer/list",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLocalAdminLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `trailer/list`,
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setTrailers(res.data));
        // dispatch(asyncAlertSuccess("Trailers fetched successfully"));
        return { success: true };
      }

      // dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      // dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLocalAdminLoading(false));
    }
  }
);
