import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import React, { memo } from "react";
import AppModal from ".";
import { customerType, phoneValidator } from "../../utils";
import AppButton from "../Button";
import AppInput from "../Input";
import AppSelect from "../Select";

export const Customer = memo(
  ({
    open,
    title,
    isEdit,
    isAdmin = false,
    isCustomer = true,
    btnTitle,
    carriers,
    onCancel,
    onClick,
  }) => {
    return (
      <AppModal open={open} minWidth="20%" title={title}>
        <Row gutter={24}>
          {isCustomer && (
            <Col span={24}>
              <Form.Item
                name="Carrier"
                rules={[
                  {
                    required: true,
                    message: ``,
                  },
                ]}
              >
                <AppSelect
                  title="Carrier"
                  size="large"
                  data={carriers.map((carrier) => ({
                    value: carrier.ID,
                    label: carrier.Name,
                  }))}
                  disabled={!carriers.length}
                />
              </Form.Item>
            </Col>
          )}
          {isAdmin && (
            <Col span={24} className="">
              <Form.Item
                name="Customer_Type"
                rules={[
                  {
                    required: true,
                    message: ``,
                  },
                ]}
              >
                <AppSelect
                  title="Customer Type"
                  size="large"
                  data={customerType.map((type) => ({
                    value: type.value,
                    label: type.label,
                  }))}
                  disabled={!customerType.length}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={24} className="">
            <Form.Item
              name="Customer_Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: ``,
                },
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="Name"
                placeholder="John"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24} className="">
            <Form.Item
              name="Customer_Address"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: ``,
                },
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="Address"
                placeholder="Address"
                size="large"
              />
            </Form.Item>
          </Col>
          {isCustomer && (
            <Col span={8} className="">
              <Form.Item
                name="Customer_City"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: ``,
                  },
                ]}
              >
                <AppInput
                  className="user-modal-input"
                  title="City"
                  placeholder="City"
                  size="large"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={isCustomer ? 8 : 12} className="">
            <Form.Item
              name="Customer_State"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: ``,
                },
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="State"
                placeholder="State"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={isCustomer ? 8 : 12} className="">
            <Form.Item
              name="Customer_Zip"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: ``,
                },
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="Zip Code"
                placeholder="Zip Code"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24} className="">
            <Form.Item
              name="Ops_Phone1"
              rules={[
                {
                  required: true,
                  message: ``,
                },
                phoneValidator,
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="Phone #"
                placeholder="Phone #"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24} className="">
            <Form.Item
              name="Billing_Email"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: ``,
                },
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="Email"
                placeholder="johndoe@gmail.com"
                size="large"
              />
            </Form.Item>
          </Col>
          {isCustomer && (
            <Col span={24} className="">
              <Form.Item
                name="Password"
                rules={[
                  {
                    required: isEdit ? false : true,
                    message: `Password is required!`,
                  },
                ]}
              >
                <AppInput
                  autoComplete="new-password"
                  type="password"
                  className="user-modal-input"
                  title={`Password ${isEdit ? "(optional)" : ""}`}
                  placeholder="password"
                  size="large"
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row justify="space-between">
          <AppButton
            icon={<MinusOutlined style={{ fontSize: 18 }} />}
            title="Discard"
            width="120px"
            height="max-content"
            fontSize="16px"
            className="btn_red"
            onClick={onCancel}
          />
          <AppButton
            icon={<PlusOutlined style={{ fontSize: 18 }} />}
            title={btnTitle}
            width="120px"
            height="max-content"
            fontSize="16px"
            className="btn_green mr-10"
            onClick={onClick}
          />
        </Row>
      </AppModal>
    );
  }
);
