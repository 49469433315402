import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Form, Row, Tag } from "antd";
import classNames from "classnames";
import { debounce } from "lodash";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useNavigate } from "react-router-dom";
import addCust from "../../Assets/icons/add-user.png";
import addCarr from "../../Assets/icons/supplyChainManagementW.png";
import addTrai from "../../Assets/icons/trailerW.png";
import truck from "../../Assets/icons/truck.png";
import AppButton from "../../Components/Button";
// import AppDrawer from "../../Components/Drawer";
import { FilterSelect } from "../../Components/Filters/FilterSelect";
import { SearchDate } from "../../Components/Filters/SeachDate";
import { SearchInput } from "../../Components/Filters/SearchInput";
import Messages from "../../Components/Messages";
import { Customer } from "../../Components/Modal/Customer";
import { Trailer } from "../../Components/Modal/Trailer";
import { YardMove } from "../../Components/Modal/YardMove";
import { DataTable } from "../../Components/Table";
import {
  asyncCreateCustomer,
  asyncGetAllCarriers,
  asyncGetAllCustomers,
} from "../../store/actions/customer";
import {
  asyncGetAllActiveDrivers,
  asyncGetAllDrivers,
} from "../../store/actions/driver";
import {
  asyncCreateRequest,
  asyncGetAllMoveTypes,
  asyncGetAllRequests,
} from "../../store/actions/request";
import {
  asyncCreateTrailer,
  asyncGetAllTrailers,
} from "../../store/actions/trailer";
import { asyncGetAllUsers } from "../../store/actions/user";
import { asyncGetAllVehicles } from "../../store/actions/vehicle";
// import { setSelected } from "../../store/reducers/request";
import {
  checkValue,
  dateFormats,
  formatDate,
  statuses,
  tableTabs,
} from "../../utils";
import "./style.scss";

const YardMoveRequest = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({});
  const [isFieldsClear, setIsFieldsClear] = useState(false);

  // const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [showCarrier, setShowCarrier] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false);
  const [showYardMove, setShowYardMove] = useState(false);
  const [loader, setLoader] = useState(false);

  // States and Refs for panels
  const [expandTopIcon, setExpandTopIcon] = useState(true);
  const [expandBottomIcon, setExpandBottomIcon] = useState(true);
  const [
    topSize,
    // setTopSize
  ] = useState(34);
  const [bottomSize, setBottomSize] = useState();
  const topPanel = useRef();
  const bottomPanel = useRef();
  const tbleRef = useRef();
  const panelHeight = useRef();
  // States and Refs for panels

  const { user } = useSelector((s) => s.user);
  const {
    dataSource,
    moveTypes,
    // selected
  } = useSelector((s) => s.request);
  const { customers, carriers } = useSelector((s) => s.customer);
  // const { activeDrivers, drivers } = useSelector((s) => s.driver);
  const { trailers } = useSelector((s) => s.trailer);
  // const { vehicles } = useSelector((s) => s.vehicle);
  // const { notification } = useSelector((s) => s.common);

  let selectedCarrier = Form.useWatch("Carrier", form);
  let selectedCarrierTrailer = Form.useWatch("Carrier", modalForm);

  // const setHeight = () => {
  //   // const tableHeight = tbleRef.current;
  //   // const totalHeight = panelHeight.current;

  //   // let calculatetableHeight =
  //   //   (tableHeight.offsetHeight / totalHeight.offsetHeight) * 100;
  //   // const resizeBarHeight = (33 / totalHeight.offsetHeight) * 100;
  //   // console.log(calculatetableHeight, resizeBarHeight);
  //   // calculatetableHeight = calculatetableHeight + resizeBarHeight;
  //   // console.log(calculatetableHeight);
  //   // calculatetableHeight = calculatetableHeight - 33;
  //   setTopSize(34);
  //   setBottomSize(100 - 34);
  // };

  // let driverList = useMemo(() => {
  //   let { YardMoveStatus } = selected;
  //   return YardMoveStatus === statuses.COMPLETED ? drivers : activeDrivers;
  // }, [selected, activeDrivers, drivers]);

  useLayoutEffect(() => {
    // setTopSize(34);
    setBottomSize(100 - topSize); //34
  }, [topSize]);

  useEffect(() => {
    const loadRequests = async () => {
      setLoader(true);
      await dispatch(asyncGetAllRequests()).unwrap();
      setLoader(false);

      // setTimeout(setHeight, 1000);
    };

    const loadData = async () => {
      await dispatch(asyncGetAllUsers()).unwrap();
      await dispatch(asyncGetAllVehicles()).unwrap();

      await dispatch(asyncGetAllCarriers()).unwrap();
      await dispatch(asyncGetAllTrailers()).unwrap();
      await dispatch(asyncGetAllMoveTypes()).unwrap();

      if (+user.role === 14) {
        await dispatch(asyncGetAllCustomers()).unwrap();
        await dispatch(asyncGetAllActiveDrivers()).unwrap();
        await dispatch(asyncGetAllDrivers()).unwrap();
      }
    };

    loadRequests();
    loadData();
  }, [dispatch, user?.role]);

  let { title: tableName, dataIndex: tableDataIndex } =
    tableTabs.find((tab) => tab.ind === tabIndex) || "";

  // useEffect(() => {
  //   if (notification?.RequestID) {
  //     if (notification?.Type === statuses.COMPLETED) {
  //       setFilters((prev) => {
  //         let prevFilters = { ...prev };
  //         delete prevFilters[tableName];
  //         return {
  //           ...prevFilters,
  //           [tableName]: {
  //             ID: notification?.RequestID,
  //           },
  //         };
  //       });
  //       setTabIndex(0);
  //     }
  //   }
  // }, [dispatch, tableName, notification]);

  let tableData =
    dataSource[tableDataIndex]?.filter(
      ({
        ID,
        AssignedOrderRef,
        trailer,
        Door,
        YardMoveRequestDateTime,
        YardMoveStatus,
        Carrier,
        YardTractorMoveType,
      }) => {
        let SF = filters[tableName];
        let row = [];

        let militaryDate = !YardMoveRequestDateTime
          ? null
          : formatDate(YardMoveRequestDateTime, dateFormats["MM/DD/YY"]);

        let militaryDate1 = !SF?.YardMoveRequestDateTime
          ? null
          : formatDate(SF?.YardMoveRequestDateTime, dateFormats["MM/DD/YY"]);

        row.push(checkValue(SF?.ID, ID));
        row.push(checkValue(SF?.AssignedOrderRef, AssignedOrderRef));
        row.push(checkValue(SF?.Trailer, trailer?.UnitNo));
        row.push(checkValue(SF?.Door, Door));
        row.push(checkValue(SF?.YardMoveStatus, YardMoveStatus, true));
        row.push(checkValue(SF?.Carrier, Carrier, true));
        row.push(
          checkValue(SF?.YardTractorMoveType, YardTractorMoveType, true)
        );
        row.push(checkValue(militaryDate1, militaryDate, true));

        if (row.every(Boolean)) {
          return true;
        } else {
          return false;
        }
      }
    ) || [];

  let filteredTrailers =
    trailers?.filter((trailer) => trailer.Carrier === selectedCarrier) || [];

  const defaultColumns = [
    {
      title: "Move ID",
      dataIndex: "ID",
      width: 90,
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "YardMoveRequestDateTime",
      width: 100,
      render: (
        _,
        {
          YardMovePrePlanDateTime,
          YardMoveRequestDateTime,
          YardMoveDispatchDateTime,
          YardMoveCompleteDateTime,
          YardMoveStartDateTime,
          YardMoveStatus,
          // ...record
        }
      ) => {
        let date = "";
        if (YardMoveStatus === statuses.PREPLAN) {
          date = YardMovePrePlanDateTime;
        } else if (YardMoveStatus === statuses.REQUESTED) {
          date = YardMoveRequestDateTime;
        } else if (YardMoveStatus === statuses.DISPATCHED) {
          date = YardMoveDispatchDateTime;
        } else if (YardMoveStatus === statuses.INPROGRESS) {
          date = YardMoveStartDateTime;
        } else if (YardMoveStatus === statuses.COMPLETED) {
          date = YardMoveCompleteDateTime;
        }

        // if ("key" in record) {
        //   return formatDate(date);
        // }

        return formatDate(date);
        // return formatDate(getGmtMinus5(date));
      },
    },
    {
      title: "Status",
      align: "center",
      width: 100,
      dataIndex: "YardMoveStatus",
      render: (status) => {
        let color = "";
        switch (status) {
          case statuses.PREPLAN:
            color = "#222222";
            break;
          case statuses.REQUESTED:
            color = "#D9D9D9";
            break;
          case statuses.DISPATCHED:
            color = "#0055CC";
            break;
          case statuses.INPROGRESS:
            color = "#9B57F2";
            break;
          case statuses.COMPLETED:
            color = "#047857";
            break;
          default:
            color = "#D9D9D9";
        }

        return (
          <Tag
            color={color}
            style={{
              fontSize: 11,
              width: 100,
              margin: "auto",
              padding: "3px 8px",
              borderRadius: 4,
              color: status === "Requested" ? "#000" : "#fff",
            }}
          >
            {status === statuses.DISPATCHED
              ? "ASSIGNED"
              : status?.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Carrier",
      dataIndex: "Carrier",
      width: 140,
      render: (_, { carrier }) => carrier?.name?.toUpperCase() || "N/A",
    },
    {
      title: "Move Type",
      dataIndex: "YardTractorMoveType",
      width: 110,
      render: (_, { YardMoveType }) => {
        return YardMoveType?.Service?.toUpperCase() || "N/A";
      },
    },
    {
      title: "Trip",
      dataIndex: "AssignedOrderRef",
      width: 80,
      render: (AssignedOrderRef) => {
        return AssignedOrderRef || "N/A";
      },
    },
    {
      title: "Trailer",
      dataIndex: "Trailer",
      render: (_, row) => {
        return row.trailer?.UnitNo || "N/A";
      },
      width: 80,
    },
    {
      title: "Door",
      dataIndex: "Door",
      width: 80,
      render: (Door) => {
        return Door || "N/A";
      },
    },
    {
      title: "Temp",
      dataIndex: "SetPointTemp",
      width: 80,
      render: (SetPointTemp) => {
        return SetPointTemp || "N/A";
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
      dataIndex: "action",
      render: (_, action) => {
        return (
          <Row align="middle" justify="end">
            <EllipsisOutlined
              style={{
                fontSize: 30,
                color: "#000",
                marginLeft: 10,
                marginRight: 10,
              }}
              onClick={() => {
                navigate(`${action.ID}`, {
                  state: { yardMoveId: action.ID, record: action },
                });
              }}
            />
          </Row>
        );
      },
    },
  ];

  if (+user?.role !== 13) {
    defaultColumns.splice(4, 0, {
      title: "Customer",
      dataIndex: "Customer",
      width: 140,
      render: (_, { trailer, customer }) =>
        customer?.name?.toUpperCase() || "N/A",
    });
  }

  // YardMove Create
  const handleSave = async (values) => {
    setLoader(true);

    let IsPrePlan = form.getFieldValue("IsPrePlan");

    const res = await dispatch(
      asyncCreateRequest({ ...values, IsPrePlan })
    ).unwrap();
    if (res.success) {
      resetFormModal();
    }
    setLoader(false);
  };

  // Carrier & Customer & Trailer Create
  const handleSubmit = async (values) => {
    let res = {};

    if (showCustomer) {
      res = await dispatch(
        asyncCreateCustomer({
          ...values,
          Customer_Type: 12,
          Table: "customer",
        })
      ).unwrap();
    } else if (showCarrier) {
      res = await dispatch(
        asyncCreateCustomer({
          ...values,
          Table: "carrier",
        })
      ).unwrap();
    } else {
      res = await dispatch(
        asyncCreateTrailer({
          ...values,
        })
      ).unwrap();
    }

    if (res.success) {
      resetFormModal();
      setShowYardMove(true);
    }
  };

  const defaultStyle = {
    color: "var(--color-text-3)",
    backgroundColor: "transparent",
    fontWeight: "600",
    fontSize: "14px",
    borderBottom: "2px solid transparent",
    badgeBg: "var(--color-slate-1)",
    badgeColor: "var(--color-black-2)",
    // "&:hover": {
    //   color: "red",
    // },
  };

  const activeStyle = {
    color: "var(--color-black-2)",
    backgroundColor: "transparent",
    fontWeight: "600",
    fontSize: "14px",
    borderBottom: "2px solid var(--color-black-2)",
    badgeBg: "var(--color-black-2)",
    badgeColor: "var(--color-white)",
    ":hover": {
      color: "red",
      backgroundColor: "#000",
    },
  };

  const debounceSearch = useRef(
    debounce(({ tableName, key, value }) => {
      setFilters((prev) => {
        return {
          ...prev,
          [tableName]: {
            ...prev[tableName],
            [key]: value,
          },
        };
      });
    }, 300)
  ).current;

  const handleFilterChange = (props) => {
    debounceSearch(props);
  };

  const handleClearFilters = () => {
    const existingFilters = { ...filters };
    if (existingFilters[tableName]) {
      // existingFilters[tableName] = {};
      delete existingFilters[tableName];
      setFilters(existingFilters);
      setIsFieldsClear((prev) => !prev);
    }
  };

  const resetFormModal = () => {
    setShowTrailer(false);
    setShowCarrier(false);
    setShowCustomer(false);
    setShowYardMove(false);
    form.resetFields();
    modalForm.resetFields();
  };

  const handleClickExpand = (whichPanel) => {
    const top = topPanel.current;
    const bottom = bottomPanel.current;

    if (whichPanel === "topPanel") {
      if (top.getCollapsed()) {
        top.expand();
        bottom.expand();
        setExpandTopIcon(true);
        setExpandBottomIcon(true);
      } else if (
        top.getCollapsed() === false &&
        bottom.getCollapsed() === true
      ) {
        top.expand();
        bottom.expand();
        setExpandTopIcon(true);
      } else {
        top.expand();
        bottom.collapse();
        setExpandTopIcon(false);
        setExpandBottomIcon(true);
      }
    }
    // else {
    //   if (bottom.getCollapsed()) {
    //     bottom.expand();
    //     top.expand();
    //     setExpandBottomIcon(true);
    //     setExpandTopIcon(true);
    //   } else if (
    //     bottom.getCollapsed() === false &&
    //     top.getCollapsed() === true
    //   ) {
    //     bottom.expand();
    //     top.expand();
    //     setExpandBottomIcon(true);
    //   } else {
    //     bottom.expand();
    //     top.collapse();
    //     setExpandBottomIcon(false);
    //     setExpandTopIcon(true);
    //   }
    // }
  };

  // const [resizePageSize, setPageSize] = useState(4);
  // const [PageH, setPageH] = useState();
  // const handelResizeTable = (size) => {
  //   console.log(size);
  //   setPageH(size)
  //   let newPageSize = 4;
  //   // adjust the newPageSize based on the screenHeight if needed

  //   if (size > 99.9) {
  //     newPageSize = 12;
  //   }
  //    else if (size > 78.7) {
  //     newPageSize = 9;
  //   } else if (size > 73.7) {
  //     newPageSize = 8;
  //   } else if (size > 68.7) {
  //     newPageSize = 7;
  //   } else if (size > 63.7) {
  //     newPageSize = 6;
  //   } else if (size > 57.7) {
  //     newPageSize = 5;
  //   } else if (size >= 52) {
  //     newPageSize = 4;
  //   }
  //   setPageSize(newPageSize);
  // };

  return (
    <div ref={panelHeight} style={{ height: "100%" }}>
      <PanelGroup collapsible direction="vertical">
        <Panel
          minSize={topSize}
          defaultSize={topSize}
          maxSize={100}
          collapsible={true}
          ref={topPanel}
          className="PanelHeighetCheck"
          style={{ overflow: expandBottomIcon ? "unset" : "hidden" }}
        >
          <div ref={tbleRef} id="ya">
            <div key={isFieldsClear} className="head">
              <Row className="clearFilterRow">
                <Col>
                  <AppButton
                    onClick={handleClearFilters}
                    title="Clear All"
                    color="#222"
                    backgroundColor="transparent"
                    className="text-sm font-600 primary"
                    padding="0"
                  />
                </Col>
              </Row>
              <Row style={{ alignItems: "end", margin: 0 }} gutter={5}>
                <Col span={3}>
                  <SearchInput
                    tabIndex={tabIndex}
                    tableName={tableName}
                    title="Move ID"
                    dataIndex="ID"
                    value={filters[tableName]?.ID}
                    onChange={handleFilterChange}
                  />
                </Col>
                <Col span={3}>
                  <SearchDate
                    tabIndex={tabIndex}
                    tableName={tableName}
                    title="Date"
                    dataIndex="YardMoveRequestDateTime"
                    value={filters[tableName]?.YardMoveRequestDateTime}
                    onChange={handleFilterChange}
                  />
                </Col>
                <Col span={4}>
                  <FilterSelect
                    icon={<UserOutlined style={{ fontSize: 16 }} />}
                    tabIndex={tabIndex}
                    tableName={tableName}
                    placeholder="Search"
                    title="Carrier"
                    dataIndex="Carrier"
                    value={filters[tableName]?.Carrier}
                    onChange={handleFilterChange}
                    options={carriers.map((carrier) => ({
                      value: carrier.ID,
                      label: carrier.Name,
                    }))}
                  />
                </Col>
                <Col span={3}>
                  <FilterSelect
                    img={truck}
                    tabIndex={tabIndex}
                    tableName={tableName}
                    placeholder="Search"
                    title="Move Type"
                    dataIndex="YardTractorMoveType"
                    value={filters[tableName]?.YardTractorMoveType}
                    onChange={handleFilterChange}
                    options={moveTypes.map((type) => ({
                      value: type.ID,
                      label: type.Service,
                    }))}
                  />
                </Col>
                <Col span={3}>
                  <SearchInput
                    tabIndex={tabIndex}
                    tableName={tableName}
                    title="Trip"
                    dataIndex="AssignedOrderRef"
                    value={filters[tableName]?.AssignedOrderRef}
                    onChange={handleFilterChange}
                  />
                </Col>
                <Col span={3}>
                  <SearchInput
                    tabIndex={tabIndex}
                    tableName={tableName}
                    title="Trailer"
                    dataIndex="Trailer"
                    value={filters[tableName]?.Trailer}
                    onChange={handleFilterChange}
                  />
                </Col>
                <Col span={3}>
                  <SearchInput
                    tabIndex={tabIndex}
                    tableName={tableName}
                    title="Door"
                    dataIndex="Door"
                    value={filters[tableName]?.Door}
                    onChange={handleFilterChange}
                  />
                </Col>
              </Row>
              <Row className="yard-move-clone-row">
                <Col span={20} className="yard-move-tabs-col">
                  <div className="yard-move-tabs-box">
                    {tableTabs.map((item, ind) => (
                      <AppButton
                        key={item.ind}
                        type="primary"
                        size="large"
                        title={item.title}
                        height="45px"
                        padding=""
                        width="max-content"
                        borderRadius="0px"
                        count={dataSource?.[item?.dataIndex]?.length || 0}
                        onClick={() => setTabIndex(ind)}
                        {...(tabIndex === ind ? activeStyle : defaultStyle)}
                      />
                    ))}

                    <div
                      className={classNames(
                        "expandButton ",
                        expandTopIcon ? "black" : "white"
                      )}
                    >
                      <span onClick={() => handleClickExpand("topPanel")}>
                        {expandTopIcon ? <BiExpand /> : <BiCollapse />}
                      </span>
                    </div>
                  </div>
                </Col>
                {/* <Col span={7} style={{ display: "none" }}>
                  <Row justify="end" className="my-15">
                    {tabIndex === 0 && (
                      <div style={{ display: "flex" }}>
                        <AppButton
                          type="primary"
                          size="large"
                          width="max-content"
                          height="33px"
                          fontSize="14px"
                          className="ml-10"
                          backgroundColor="var(--color-success-dark)"
                          title="+ Request Move"
                          onClick={() => setShowYardMove(true)}
                        />
                      </div>
                    )}
                  </Row>
                </Col> */}
              </Row>
            </div>

            {tableTabs.map((_, ind) => {
              return tabIndex === ind ? (
                <DataTable
                  key={tabIndex + ind}
                  form={form}
                  dataSource={tableData}
                  columns={defaultColumns}
                  loader={loader}
                  pageSizeCount={expandTopIcon ? 5 : 15}
                  sizeChanger={false}
                  paginationPosition={["topRight"]}
                  showQuickJumper={true}
                  customClassName={
                    !tableData?.length
                      ? "YardMoveTable NoData "
                      : "YardMoveTable"
                  }
                  paginationSize="small"
                  paginationClass="reduceSize"
                />
              ) : null;
            })}
          </div>
        </Panel>
        <div className="resizehandleWithExpand">
          {/* <span onClick={()=>handleClickExpand('topPanel')} > {expandTopIcon ?  <BiExpand/> :  <BiCollapse/> }  </span> */}
          {/* <ResizeHandle /> */}
          {/* <span onClick={()=>handleClickExpand('bottomPanel')} > {expandBottomIcon ?  <BiExpand/> :  <BiCollapse/> }  </span> */}
        </div>

        <Panel
          maxSize={100}
          defaultSize={bottomSize}
          minSize={bottomSize}
          collapsible={true}
          ref={bottomPanel}
        >
          <Messages onRequestMove={() => setShowYardMove(true)} />{" "}
          {/*  callClickExpand={handleClickExpand}  showIcon={expandBottomIcon ?  <BiExpand/> :  <BiCollapse/> }*/}
        </Panel>
      </PanelGroup>

      {/* <AppDrawer
        open={open}
        onClose={() => setOpen(false)}
        setLoader={setLoader}
        loader={loader}
        user={user}
        drivers={driverList}
        // customers={customers}
        carriers={carriers}
        trailers={trailers}
        moveTypes={moveTypes}
      /> */}

      <Form
        form={form}
        name="publish"
        layout="vertical"
        onFinish={handleSave}
        initialValues={{
          IsPrePlan: false,
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <YardMove
          open={showYardMove}
          carriers={carriers}
          moveTypes={moveTypes}
          filteredTrailers={filteredTrailers}
          selectedCarrier={selectedCarrier}
          form={form}
          onCancel={resetFormModal}
          onClick={() => {
            form.setFieldValue("IsPrePlan", false);
            form.submit();
          }}
          onPrePlanClick={() => {
            form.setFieldValue("IsPrePlan", true);
            form.submit();
          }}
          title="New Yard Move"
          btnTitle="Save"
          headerIcon={
            <div style={{ display: " flex" }}>
              {+user?.role !== 13 && (
                <>
                  <AppButton
                    type="primary"
                    size="large"
                    width="max-content"
                    height="33px"
                    fontSize="14px"
                    backgroundColor="var(--color-dark-80)"
                    onClick={() => {
                      resetFormModal();
                      setShowCarrier(true);
                    }}
                    icon={<img src={addCarr} alt="" style={{ width: 20 }} />}
                  />
                  <AppButton
                    type="primary"
                    size="large"
                    width="max-content"
                    height="33px"
                    fontSize="14px"
                    backgroundColor="var(--color-primary-dark)"
                    className="ml-10"
                    onClick={() => {
                      resetFormModal();
                      setShowCustomer(true);
                    }}
                    icon={<img src={addCust} alt="" style={{ width: 20 }} />}
                  />
                </>
              )}
              <AppButton
                type="primary"
                size="large"
                width="max-content"
                height="33px"
                fontSize="14px"
                backgroundColor="var(--color-dark-80)"
                className="ml-10"
                onClick={() => {
                  resetFormModal();
                  setShowTrailer(true);
                }}
                icon={<img src={addTrai} alt="" style={{ width: 20 }} />}
              />
            </div>
          }
        />
      </Form>

      <Form
        form={modalForm}
        name="publish"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        {showCarrier && (
          <Customer
            open={showCarrier}
            isCustomer={false}
            onCancel={resetFormModal}
            onClick={() => modalForm.submit()}
            title="Add Carrier"
            btnTitle="Save"
          />
        )}
        {showCustomer && (
          <Customer
            open={showCustomer}
            carriers={carriers}
            onCancel={resetFormModal}
            onClick={() => modalForm.submit()}
            title="Add Customer"
            btnTitle="Save"
          />
        )}
        {showTrailer && (
          <Trailer
            isCoordinator={+user?.role === 13}
            open={showTrailer}
            customers={customers?.filter((customer) => {
              return (
                customer?.Carrier &&
                customer?.Carrier === selectedCarrierTrailer
              );
            })}
            carriers={carriers}
            onCancel={resetFormModal}
            onClick={() => modalForm.submit()}
            title="Add Trailer"
            btnTitle="Save"
          />
        )}
      </Form>
    </div>
  );
};

export default YardMoveRequest;
