import { createSlice } from "@reduxjs/toolkit";
import { Error, Success } from "../../Components/Alerts";

const initialState = {
  loading: false,
  localLoading: false,
  localAdminLoading: false,
  error: null,
  success: null,
  notification: null,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
      if (payload) {
        Error(payload);
      }
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
      if (payload) {
        Success(payload);
      }
    },
    setNotification: (state, { payload }) => {
      state.notification = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setLocalLoading: (state, { payload }) => {
      state.localLoading = payload;
    },
    setLocalAdminLoading: (state, { payload }) => {
      state.localAdminLoading = payload;
    },
  },
});

export const {
  setError,
  setSuccess,
  setNotification,
  setLoading,
  setLocalLoading,
  setLocalAdminLoading,
} = commonSlice.actions;
export default commonSlice.reducer;
