import { Checkbox, Col, Form, Row, Space, Tag } from "antd"
import TextArea from "antd/lib/input/TextArea"
import React, {
  memo,
  useEffect,
  // useMemo,
  // useRef,
  useState,
} from "react"
import { AiOutlinePlus } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux"
// import Print from "../../Assets/icons/Print.svg";
import send from "../../Assets/icons/sendMessage.svg"
import AppButton from "../../Components/Button"
import { SearchDate } from "../../Components/Filters/SeachDate"
import AppInput from "../../Components/Input"
import AppSelect from "../../Components/Select"
import { DataTable } from "../../Components/Table"
import {
  asyncCreateDocument,
  asyncCreatePaymentReceipt,
  asyncDeleteDocument,
  // asyncGetAllChartOfAccounts,
  asyncSendInvoice,
} from "../../store/actions/account"
import {
  addAdditionalPropsToCell,
  formatDate,
  formatTableDuration,
  moment,
  numberValidator,
  paymentOptions,
  toCurreny,
} from "../../utils"
import { FileUploader } from "../Dragger"
import { FileViewer } from "../FileViewer"
import InvoiceModal from "../Modal/Invoice"
import {
  NotFound,
  // OverLay
} from "../NotFound"
import styles from "./style.module.scss"
import { Actions } from "../RowActions"
import { isEqual } from "lodash"
import { DocSelection } from "../DocSelection"

function PaymentReceipt({ record, loader, localLoading }) {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const [documentForm] = Form.useForm()

  // const documentAddRef = useRef({ current: null });

  // const { selected: record } = useSelector((s) => s.request);
  const documentTypes = useSelector((s) => s.account.documentTypes, isEqual)
  const chartOfAccounts = useSelector((s) => s.account.chartOfAccounts, isEqual)

  // const [paymentLoader, setPaymentLoader] = useState(false);
  // const [documentLoader, setDocumentLoader] = useState(false);

  const [open, setOpen] = useState(false)
  const [viewerSource, setViewerSource] = useState({ ID: null, link: "" })

  const [showFileUploader, setShowFileUploader] = useState({
    show: false,
    file: {},
  })

  const [editingKey, setEditingKey] = useState("")
  const [selectedDocIDs, setSelectedDocIDs] = useState([])

  const [paymentDocs, setPaymentDocs] = useState([])
  const [paymentReceiptsData, setPaymentReceiptsData] = useState([])

  const [paymentDocsLoader, setPaymentDocsLoader] = useState([])
  const [paymenReceiptsLoader, setPaymenReceiptsLoader] = useState([])

  const [selectedAttachmentIDs, setSelectedAttachmentIDs] = useState([])
  const [showAttachment, setShowAttachment] = useState()

  // let paymentDocuments = useMemo(
  //   () =>
  //     record?.documents?.reduce((acc, curr, ind) => {
  //       // 3 means payment Tab
  //       if (curr.docCategory === 3) {
  //         return [
  //           ...acc,
  //           { ...curr, link: { uid: curr.Link + ind, name: curr.Link } },
  //         ];
  //       }

  //       return acc;
  //     }, []),
  //   [record?.documents]
  // );

  useEffect(() => {
    if (record?.ID) {
      form.setFieldValue("Customer_Name", record?.customer?.name)
    }
  }, [form, record?.ID, record?.customer?.name])

  useEffect(() => {
    setTimeout(() => {
      setPaymentDocsLoader(true)
      let paymentDocuments = record?.documents?.reduce((acc, curr, ind) => {
        // 3 means payment Tab
        if (curr.docCategory === 3) {
          return [
            ...acc,
            { ...curr, link: { uid: curr.Link + ind, name: curr.Link } },
          ]
        }

        return acc
      }, [])

      let documetsToSpread = !record?.documents?.summaryDocuments?.length
        ? [{}]
        : [{}, ...record?.documents?.summaryDocuments]

      documentForm.setFieldsValue({
        documents: paymentDocuments?.length
          ? [...documetsToSpread, ...paymentDocuments]
          : [{}],
        // documents: paymentDocuments?.length ? [...paymentDocuments, {}] : [{}],
      })

      setPaymentDocs((_) => paymentDocuments)
      setPaymentDocsLoader(false)
    }, 1000)
  }, [documentForm, record?.documents])

  useEffect(() => {
    setTimeout(() => {
      setPaymenReceiptsLoader(true)
      setPaymentReceiptsData((_) => record?.invoice?.paymentReceipts)
      setPaymenReceiptsLoader(false)
    }, 1000)
  }, [record?.invoice?.paymentReceipts])

  // let paymentReceiptsData = record?.invoice?.paymentReceipts;

  const paymentListColumns = [
    {
      title: "ID",
      dataIndex: "ID",
      width: "3%",
    },
    {
      title: "Date",
      dataIndex: "PaymentDate",
      width: "15%",
      render: (PaymentDate) => {
        return formatDate(PaymentDate)
      },
    },
    {
      title: "Customer",
      dataIndex: "CustomerID",
      width: "25%",
      render: (_, { customer }) => {
        return customer?.Customer_Name || "N/A"
      },
    },
    {
      title: "Amount",
      dataIndex: "AmountPaid",
      width: "15%",
      render: (AmountPaid) => toCurreny(AmountPaid),
    },
    {
      title: "Description",
      dataIndex: "Description",
      width: "30%",
      render: (Description) => Description || "N/A",
    },
    // {
    //   title: "Balance",
    //   dataIndex: "Balance",
    //   render: (_, { AmountPaid }, ind) => {
    //     let updatedVal = newTotal;
    //     // newTotal =
    //     //   (newTotal || +record?.invoice?.TotalAmount) - +AmountPaid || 0;
    //     return updatedVal;
    //   }, //toCurreny(Balance),
    // },
  ]

  const handleFileSelection = async () => {
    let documentsData = [...documentForm.getFieldValue("documents")]

    let fileRecord = { ...documentsData?.shift(), link: showFileUploader.file }

    documentsData.push(fileRecord)

    documentForm.setFieldsValue({
      documents: documentsData,
    })

    setShowFileUploader({
      show: false,
      file: {},
    })
  }

  const handleDocumentSave = async (key) => {
    if (localLoading || loader) return

    try {
      let { errorFields } = await documentForm.validateFields([
        ["documents", key, "link"],
        ["documents", key, "DocumentType"],
      ])

      if (errorFields) return

      const row = documentForm.getFieldValue(["documents", key])

      let documentFormData = new FormData()

      documentFormData.append("link", row.link.originFileObj)
      documentFormData.append("DocumentType", row.DocumentType)
      documentFormData.append("docCategory", 3)

      // setDocumentLoader(true);
      if (row?.ID) {
        // await dispatch(asyncUpdateDocument(row)).unwrap();
      } else {
        await dispatch(
          asyncCreateDocument({
            yardMoveID: record?.ID,
            body: documentFormData,
          })
        ).unwrap()
      }

      setEditingKey("")
      // setDocumentLoader(false);
    } catch (error) {
      // setDocumentLoader(false);
      console.log(error)
    }
  }

  const handleDocumentResetting = (key, record) => {
    if (localLoading || loader) return

    documentForm.setFields([
      { name: ["documents", key, "link"], value: record.link },
    ])
  }

  const handleDocumentDelete = async (remove, record) => {
    if (localLoading || loader) return
    // setDocumentLoader(true);
    let res = await dispatch(asyncDeleteDocument(record?.ID)).unwrap()
    if (res.success) {
      remove()
    }
    // setDocumentLoader(false);
  }

  const documentColumns = ({ remove }) => [
    {
      title: "",
      dataIndex: "ID",
      editable: true,
      isRequired: false,
      hidden: true,
      width: 0,
      // className: "documentID",
      render: () => null,
    },
    {
      title: "Attachment",
      dataIndex: "link",
      type: "file",
      isRequired: true,
      editable: true,
      width: "40%",
    },
    {
      title: "Type",
      type: "DropDown",
      keySet: ["ID", "DocumentType"],
      dropDownData: documentTypes,
      isRequired: true,
      editable: true,
      dataIndex: "DocumentType",
      width: "40%",
    },
    {
      dataIndex: "action",
      align: "center",
      width: "20%",
      render: (_, documentRecord, ind) => {
        let documentsLength = documentForm.getFieldValue("documents")?.length

        return (
          <Actions
            ind={ind}
            record={documentRecord}
            remove={(ind) =>
              handleDocumentDelete(() => remove(ind), documentRecord)
            }
            dataLength={documentsLength}
            handleSave={handleDocumentSave}
            handleRowResetting={handleDocumentResetting}
            editingKey={editingKey}
            setEditingKey={setEditingKey}
          />
          // <Space align="flex-end" style={{ justifyContent: "space-between" }}>
          //   <RiSaveLine
          //     style={{ cursor: "pointer", fontSize: 18 }}
          //     onClick={() => {
          //       handleDocumentSave(ind);
          //     }}
          //   />
          //   {documentsLength > 2 && ind !== documentsLength - 1 && (
          //     <RiDeleteBin6Line
          //       style={{ cursor: "pointer", fontSize: 18 }}
          //       onClick={() => {
          //         remove(ind);
          //       }}
          //     />
          //   )}
          // </Space>
        )
      },
    },
  ]

  const handleSend = async (ID = null) => {
    if (localLoading || loader) return

    // setDocumentLoader(true);

    let res = await dispatch(
      asyncSendInvoice({
        invoiceID: record?.invoice?.ID,
        docIds: ID
          ? [ID, ...selectedAttachmentIDs]
          : viewerSource.ID
          ? [viewerSource.ID]
          : selectedDocIDs,
      })
    ).unwrap()

    if (res.success) {
      // setOpen(false)
      setSelectedAttachmentIDs([])
      setSelectedDocIDs([])
      setShowAttachment(false)
    }

    // setDocumentLoader(false);
  }

  const handlePrint = async (source) => {
    if (localLoading || loader) return
    setOpen(false)
    setViewerSource((v) => ({ ...v, ...source }))
  }

  const handleViewCashReceipt = () => {
    if (loader || localLoading) return
    setOpen((open) => !open)
  }

  const onFinish = async (values) => {
    // setPaymentLoader(true);
    if (localLoading || loader) return

    let res = await dispatch(
      asyncCreatePaymentReceipt({
        InvoiceID: record?.invoice?.ID,
        CustomerID: record?.invoice?.CustomerID,
        ...values,
      })
    ).unwrap()

    if (res.success) {
      form.resetFields()
      form.setFieldValue("Customer_Name", record?.customer?.name)
    }

    // setPaymentLoader(false);
  }

  const onFinishFailed = (values) => {
    console.log(values)
  }

  let outstandingAmount = parseFloat(
    +record?.invoice?.TotalAmount - +record?.invoice?.PaidAmount
  )

  if (!record.invoice && !loader) {
    return (
      <NotFound
        title="Oh, Invoice Not Found"
        desc="It seems like invoice is not created yet"
      />
    )
  }

  return (
    <div
      className={styles.account}
      onDragOver={(e) => {
        e.preventDefault()
        setShowFileUploader((s) => ({ ...s, show: true }))
      }}
      onDragExit={(e) => {
        e.preventDefault()
      }}
      onDragLeave={(e) => {
        e.preventDefault()
      }}
      onDrop={(e) => {
        e.preventDefault()
        setShowFileUploader((s) => ({ show: false, file: {} }))
      }}
    >
      <div>
        <Row justify="space-between" className="mb-15">
          <div>
            <p className="font-600 info">Outstanding Amount</p>
            <h3 className="text-4xl font-600">${outstandingAmount}</h3>
          </div>
          <Space direction="vertical" align="end">
            <Tag color="red" className="text-2xl">
              {record?.invoice?.PaymentStatus?.toUpperCase()}
            </Tag>
            <p className="text-xl danger font-500">
              {formatTableDuration(
                moment().diff(moment(record?.invoice?.InvoiceDate)),
                false
              )}
            </p>
          </Space>
        </Row>
        <h6 className="text-xs info font-500 mb-5">
          Create Cash Receipt record below
        </h6>
        <Form
          form={form}
          name="publish"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            PaymentType: "2",
          }}
        >
          <Row gutter={8}>
            <Col xl={5}>
              <Form.Item
                name="PaymentDate"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SearchDate
                  title="Select Date"
                  dataIndex="PaymentDate"
                  onChange={({ value }) => {
                    form.setFieldValue("PaymentDate", value)
                  }}
                />
              </Form.Item>
            </Col>
            <Col xl={5}>
              <Form.Item name="Customer_Name">
                <AppInput
                  disabled={true}
                  title="Customer Name"
                  placeholder="Name"
                />
              </Form.Item>
            </Col>
            <Col xl={5}>
              <Form.Item
                name="CheckRef"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <AppInput title="Check Ref" placeholder="Check reference" />
              </Form.Item>
            </Col>
            <Col xl={5}>
              <Form.Item
                name="DepositTo"
                rules={[
                  {
                    required: true,
                    message: ``,
                  },
                ]}
              >
                <AppSelect
                  title="Deposit To"
                  placeholder="Sales Account"
                  disabled={!chartOfAccounts?.length}
                  data={(chartOfAccounts || []).map((account) => ({
                    value: account.AccountID,
                    label: account.AccountName,
                  }))}
                  onChange={(value) => {
                    form.setFieldsValue({
                      Carrier: value,
                    })

                    form.setFieldsValue({
                      Trailer: null,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col xl={4}>
              <Form.Item
                name="AmountPaid"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  numberValidator(),
                ]}
              >
                <AppInput title="Amount Receive" placeholder="Amount" />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={8}
            align="bottom"
            justify="space-between"
            className="my-15 mx-0"
          >
            <Col lg={6} xl={7} xxl={10}>
              <Form.Item name="Description">
                <TextArea
                  title="Notes"
                  placeholder="Description"
                  autoSize={{
                    minRows: 2,
                    maxRows: 3,
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12} xl={12} xxl={10}>
              <Space direction="vertical">
                <p className="text-sm font-500 info">Payment Type</p>
                <Form.Item
                  name="PaymentType"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please check the boxex!",
                    },
                  ]}
                >
                  <Checkbox.Group
                    onChange={(e) =>
                      form.setFieldsValue({ PaymentType: e.pop() })
                    }
                  >
                    {paymentOptions.map((option) => (
                      <Checkbox key={option.value} value={option.value}>
                        {option.label}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Space>
            </Col>
            <Col>
              <AppButton
                htmlType="submit"
                icon={<AiOutlinePlus color="#fff" className="mr-5" />}
                title="Record Payment"
                className="btn_dark"
              />
            </Col>
          </Row>
        </Form>

        <h3 className="text-2xl font-600 mb-5">Payments Recorded</h3>
        <DataTable
          columns={paymentListColumns}
          dataSource={paymentReceiptsData}
          pagination={true}
          loader={loader || localLoading || paymenReceiptsLoader}
          // loader={!paymentReceiptsData?.length ? true : false}
          // loader={paymentLoader}
        />
      </div>
      <div>
        <Form
          form={documentForm}
          name="publish"
          layout="vertical"
          autoComplete="off"
        >
          <Form.List name="documents" initialValue={[{}]}>
            {(_, { remove }) => {
              // documentAddRef.current = add;
              return (
                <>
                  <Row justify="space-between" className="mb-5 mt-15">
                    <h3 className="text-2xl font-600">Remittance Document</h3>
                    {/* <AppButton
                      icon={<AiOutlinePlus color="#fff" className="mr-5" />}
                      title="Document"
                      onClick={async () => {
                        try {
                          await documentForm.validateFields();
                          add();
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      className="btn_dark"
                    /> */}
                    {selectedDocIDs?.length > 0 && (
                      <AppButton
                        icon={
                          <img
                            src={send}
                            alt="img"
                            className="mr-5"
                            width="16px"
                          />
                        }
                        title="Send Email"
                        onClick={() => handleSend()}
                        className="btn_dark"
                      />
                    )}
                  </Row>
                  <DataTable
                    form={documentForm}
                    columns={addAdditionalPropsToCell(documentColumns, {
                      remove,
                      listName: "documents",
                      editingKey,
                    })}
                    dataSource={documentForm.getFieldValue("documents")}
                    onRow={({ ID, link } = {}) => ({
                      onDoubleClick: () => {
                        if (ID) return setViewerSource({ ID, link })
                      },
                    })}
                    rowSelection={{
                      type: "checkbox",
                      getCheckboxProps: (record) => {
                        return {
                          disabled: !record?.ID,
                          style: record?.ID ? null : { display: "none" },
                        }
                      },
                      onSelectAll: (_, selectedRows) => {
                        setSelectedDocIDs((_) => {
                          return !selectedRows?.length
                            ? []
                            : selectedRows?.map((doc) => doc.ID)
                        })
                      },
                      onChange: (_, selectedRows) => {
                        setSelectedDocIDs((_) => {
                          return !selectedRows?.length
                            ? []
                            : selectedRows?.map((doc) => doc.ID)
                        })
                      },
                    }}
                    loader={loader || localLoading || paymentDocsLoader}
                    // loader={!paymentDocs?.length ? true : false}
                    // loader={documentLoader}
                    pagination={true}
                    isEditable={true}
                  />
                </>
              )
            }}
          </Form.List>
        </Form>
      </div>
      {/* <Row justify="space-between" className="mt-25">
        <AppButton
          icon={<HiMinusSm className="mr-5" />}
          title="Discard"
          className="btn_outlined"
        />
        <Space>
          <AppButton
            icon={<img src={Print} alt="img" className="mr-5" width="20px" />}
            title="Print Cash Receipt"
            className="btn_dark"
            onClick={() => setOpen(true)}
          />
          <AppButton
            icon={<img src={send} alt="img" className="mr-5" width="16px" />}
            title="Send Cash Receipt"
            className="btn_dark"
          />
        </Space>
      </Row> */}
      <Space className={styles.invoiceTopBtns}>
        <AppButton
          disabled={!record?.invoice?.ID}
          // icon={<img src={Print} alt="img" className="mr-5" width="20px" />}
          title="View Cash Receipt"
          className="btn_dark"
          onClick={handleViewCashReceipt}
        />
        {/* <AppButton
          icon={<img src={send} alt="img" className="mr-5" width="16px" />}
          title="Send Cash Receipt"
          className="btn_dark"
        /> */}
      </Space>

      {/* {localLoading && <OverLay />} */}

      {showAttachment && (
        <DocSelection
          localLoading={localLoading}
          documents={paymentDocs}
          open={showAttachment}
          handleChecked={(checkedValues) => {
            setSelectedAttachmentIDs(checkedValues)
          }}
          onDiscard={() => {
            setSelectedAttachmentIDs([])
            setShowAttachment(false)
          }}
          onDone={() => {
            handleSend(viewerSource.ID)
          }}
        />
      )}

      {!!viewerSource.link && (
        <FileViewer
          src={viewerSource.link}
          open={!!viewerSource.link}
          onClickSend={() => {
            setShowAttachment(true)
          }}
          // onClickSend={() => handleSend(viewerSource.ID)}
          onCancel={() => setViewerSource({ ID: null, link: "" })}
        />
      )}

      {showFileUploader.show && (
        <FileUploader
          handleFileSelection={handleFileSelection}
          showFileUploader={showFileUploader}
          setShowFileUploader={setShowFileUploader}
        />
      )}

      {!!record?.invoice?.paymentReceipts?.length && open && (
        <InvoiceModal
          record={record}
          documents={paymentDocs}
          open={open}
          onClick={handleViewCashReceipt}
          // handleSend={handleSend}
          handlePrint={handlePrint}
        />
      )}
    </div>
  )
}

export default memo(PaymentReceipt)
