import axios from "axios"
import { store } from "../store"
import { resetAuth } from "../store/reducers/auth"
// import { setLoading } from "../store/reducers/common";

export const BASE_URL = "https://api.sihft.com/" // phase2
// export const BASE_URL = "https://osceola.thecodingbuzz.com/";
// export const BASE_URL = "http://localhost:5000/";
// export const BASE_URL = "http://192.168.100.6:5000/";
// export const BASE_URL = "http://172.19.0.1:5000/";

export const FILE_URL = BASE_URL + "uploads/"
export const callApi = ({
  path = "",
  method = "GET",
  url = null,
  body = null,
  isForm = false,
  token = null,
  // loading = true,
}) => {
  // process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;

  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  }
  if (isForm) {
    headers = {}
  }
  let options = {
    method,
  }

  if (token) headers["authorization"] = "Bearer " + token

  options.headers = headers

  if (body) options.data = body

  let urlString = BASE_URL + path
  if (url) {
    urlString = url
  }
  options.url = urlString

  // if (loading) store.dispatch(setLoading(true));
  return axios(options)
    .then((res) => {
      // if (loading) store.dispatch(setLoading(false));
      if (res.status === 500) {
        store.dispatch(resetAuth())
        return { success: false, status: 500, message: res.statusText }
      }
      if (res.status === 401) {
        store.dispatch(resetAuth())
        return { success: false, status: 401, message: res.statusText }
      }

      if (res?.data?.error?.expire) {
        return store.dispatch(resetAuth())
      }

      return res.data
    })
    .catch((err) => {
      // if (loading) store.dispatch(setLoading(false));
      return { success: false, status: 500, message: err.message }
    })
}
