import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MdCenterFocusStrong } from "react-icons/md";
import { MapContainer, Marker, useMap } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useSelector } from "react-redux";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const center = [41.021337, -93.78564];
var southWest = L.latLng(41.022241, -93.783856),
  northEast = L.latLng(41.020677, -93.787037),
  bounds = L.latLngBounds(southWest, northEast);

const RenderTile = () => {
  const map = useMap();

  const handleMapCenter = () => {
    map.setView(center, map.getZoom(), {
      animate: true,
    });
  };

  return (
    <>
      <ReactLeafletGoogleLayer
        apiKey="AIzaSyCU9w7H_2SfbjFa8za5T2W3VgIyasFbh9o"
        type={"satellite"}
        updateWhenZooming={true}
      />
      <div
        style={{
          cursor: "pointer",
          position: "absolute",
          right: 10,
          top: 6,
          fontSize: 30,
          zIndex: 1000,
          color: "#fff",
        }}
        onClick={handleMapCenter}
      >
        <MdCenterFocusStrong />
      </div>
    </>
  );
};

const GoogleMap = () => {
  const { vehicles } = useSelector((state) => state.vehicle);

  const CustomIcon = function (number) {
    return L.divIcon({
      html: `${number}`,
      className: "customMarker",
    });
  };

  // Vehicle Movement Testing Code

  // useEffect(() => {
  // let newRecords = [
  //   { lat: 41.02609, lon: -93.787697 },
  //   { lat: 41.026056, lon: -93.787671 },
  //   { lat: 41.026018, lon: -93.787654 },
  //   { lat: 41.025974, lon: -93.787654 },
  //   { lat: 41.025928, lon: -93.787642 },
  //   { lat: 41.025887, lon: -93.787637 },
  //   { lat: 41.02609, lon: -93.787697 },
  //   { lat: 41.026056, lon: -93.787671 },
  //   { lat: 41.026018, lon: -93.787654 },
  //   { lat: 41.025974, lon: -93.787654 },
  //   { lat: 41.025928, lon: -93.787642 },
  //   { lat: 41.025887, lon: -93.787637 },
  // ];
  //   let i = 0;
  //   let data = setInterval(() => {
  //     let prevVehicles = [...vehicles];

  //     let index = prevVehicles.findIndex(({ vehicle }) => {
  //       return vehicle.id === 427662;
  //     });

  //     let updatedVehicle = {
  //       vehicle: {
  //         ...prevVehicles?.[index]?.vehicle,
  //         current_location: {
  //           ...prevVehicles?.[index]?.vehicle?.current_location,
  //           ...newRecords[i],
  //         },
  //       },
  //     };

  //     if (i > newRecords.length) {
  //       i = 0;
  //     } else {
  //       i++;
  //     }

  //     prevVehicles.splice(index, 1, updatedVehicle);

  //     dispatch(setVehicles(prevVehicles));
  //   }, 3000);

  //   return () => {
  //     clearInterval(data);
  //   };
  // }, [dispatch]);

  let markers = vehicles.map(({ vehicle },ind) => {
    if (!vehicle?.current_location?.lat || !vehicle?.current_location?.lon)
      return false;

    return (
      <Marker
        key={ind+"markers"}
        icon={CustomIcon(vehicle?.number)}
        position={[
          vehicle?.current_location?.lat,
          vehicle?.current_location?.lon,
        ]}
      ></Marker>
    );
  });

  useEffect(() => {
    const handleWindowResize = () => {
      mapRef?.current?.leafletElement?.invalidateSize();
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const mapRef = React.createRef();
  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <MapContainer
        zoom={17.9}
        zoomControl={true}
        zoomSnap={0}
        zoomDelta={0.1}
        wheelPxPerZoomLevel={100}
        resetBoundsOnResize={true}
        style={{ height: "100%", width: "100%", zIndex: 1 }}
        center={center}
        fitBounds={bounds}
      >
        <RenderTile />
        {markers}
      </MapContainer>
    </div>
  );
};

export default GoogleMap;
