import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { Col, Form, Row, Switch } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../../Components/Button";
import { SearchInput } from "../../../Components/Filters/SearchInput";
import { Delete } from "../../../Components/Modal/Delete";
import { SmsUser } from "../../../Components/Modal/SmsUser";
import AppSelect from "../../../Components/Select";
import { DataTable } from "../../../Components/Table";
import {
  asyncBanSmsUser,
  asyncCreateSmsUser,
  asyncDeleteSmsUser,
  asyncGetAllSmsUser,
  asyncUpdateSmsUser,
} from "../../../store/actions/sms";
import { asyncGetAllUsers } from "../../../store/actions/user";
import { checkValue, roles, rules } from "../../../utils";

const SmsUsers = () => {
  let [form] = Form.useForm();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [dataRow, setDataRow] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  // const [loader, setLoader] = useState(false);

  const { users } = useSelector((s) => s.user);
  const { users: smsUsers } = useSelector((s) => s.smsUser);
  const localAdminLoading = useSelector((s) => s.common.localAdminLoading);

  const userId = Form.useWatch("UserId", form);

  useEffect(() => {
    const loadSmsUsers = async () => {
      // setLoader(true);
      await dispatch(asyncGetAllSmsUser()).unwrap();
      // setLoader(false);
    };

    let loadData = async () => {
      await dispatch(asyncGetAllUsers()).unwrap();
    };

    loadSmsUsers();
    loadData();
  }, [dispatch]);

  useEffect(() => {
    if (userId && !isEdit) {
      let isExists = smsUsers.find((user) => {
        return user?.UserId === userId;
      });

      if (isExists) {
        form.setFields([
          {
            name: "UserId",
            value: null,
          },
          {
            name: "Phone",
            value: null,
          },
        ]);
        return;
      }
    }

    let user = users.find((user) => {
      return user.id === userId;
    });

    if (user?.id) {
      form.setFieldsValue({
        Phone: dataRow.Phone ?? user?.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        Rule: dataRow?.Rule?.toString()?.split(","),
        UserId: dataRow.UserId,
        Phone: dataRow.Phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  let tableData =
    smsUsers?.filter(({ user, Rule }) => {
      let SF = filters;
      let row = [];

      row.push(checkValue(SF?.name, user?.name));
      row.push(checkValue(SF?.role, user?.role, true));

      row.push(checkValue(SF?.rule, Rule?.toString()?.split(",")));

      if (row.every(Boolean)) {
        return true;
      } else {
        return false;
      }
    }) || [];

  const defaultColumns = [
    {
      title: "ID #",
      dataIndex: "id",
      width: 90,
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "UserName",
      render: (_, { user }) => user?.name || "N/A",
      fixed: "left",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      render: (_, { Phone }) => Phone || "N/A",
      fixed: "left",
    },
    {
      title: "Rule",
      dataIndex: "Rule",
      render: (Rule) => {
        let userRules = Rule?.toString().split(",");

        let colors = {
          1: { color: "#0162EA" },
          2: { color: "#EE7A27" },
          3: { color: "#D9D9D9" },
        };

        return userRules?.map((rule, ind) => {
          return (
            <>
              {ind !== 0 && <span className="ml-3 mr-3">|</span>}
              <span className="font-600" style={colors[+rule]}>
                {rules.find((r) => r.value === rule)?.label || "N/A"}
              </span>
            </>
          );
        });
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 200,
      render: (_, action) => {
        return (
          <Row
            className="users-tbl-action-row"
            align="middle"
            justify="space-evenly"
          >
            <Switch
              defaultChecked={action.Active}
              size="small"
              onChange={() => handleBan(action.id)}
              className="users-tbl-switch"
            />
            <FormOutlined
              className="users-tbl-icons"
              style={{ fontSize: 20, color: "#000" }}
              onClick={() => {
                setDataRow(action);
                setIsEdit(true);
                setOpen(true);
              }}
            />
            {!action.key && (
              <DeleteOutlined
                className="users-tbl-icons"
                style={{ fontSize: 20, color: "red" }}
                onClick={() => {
                  setDataRow(action);
                  setConfirmModal(true);
                }}
              />
            )}
          </Row>
        );
      },
    },
  ];

  const handleSave = async ({ Rule, ...values }) => {
    if (localAdminLoading) return;
    // setLoader(true);

    let res = await dispatch(
      asyncCreateSmsUser({ Rule: Rule.toString(), ...values })
    ).unwrap();
    if (res.success) {
      resetFormModal();
    }
    // setLoader(false);
  };

  const handleEdit = async ({ Rule, ...values }) => {
    if (localAdminLoading) return;
    // setLoader(true);
    let res = await dispatch(
      asyncUpdateSmsUser({
        ...values,
        id: dataRow?.id,
        Rule: Rule.toString(),
      })
    ).unwrap();
    if (res.success) {
      resetFormModal();
    }
    // setLoader(false);
  };

  const handleDelete = async () => {
    if (localAdminLoading) return;
    // setLoader(true);
    let res = await dispatch(asyncDeleteSmsUser({ id: dataRow?.id })).unwrap();
    if (res.success) {
      resetDeleteModal();
    }
    // setLoader(false);
  };

  const handleBan = async (id) => {
    if (localAdminLoading) return;
    // setLoader(true);
    await dispatch(asyncBanSmsUser({ id })).unwrap();
    // setLoader(false);
  };

  const debounceSearch = useRef(
    debounce(({ key, value }) => {
      setFilters((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    }, 300)
  ).current;

  const handleFilterChange = (props) => {
    debounceSearch(props);
  };

  const resetFormModal = () => {
    setOpen(false);
    setIsEdit(false);
    setDataRow({});
    form.resetFields();
  };

  const resetDeleteModal = () => {
    setConfirmModal(false);
    setDataRow({});
  };

  return (
    <>
      <div className="head">
        <Row justify="space-between" align="bottom" className="my-15">
          <Col span={16}>
            <Row gutter={16}>
              <Col lg={12} xl={8} xxl={6}>
                <SearchInput
                  title="Search"
                  dataIndex="name"
                  placeholder="Search by name"
                  onChange={handleFilterChange}
                />
              </Col>
              <Col lg={12} xl={7} xxl={5}>
                <AppSelect
                  title="Role"
                  size="large"
                  className="users-filter-select"
                  placeholder="Select role"
                  onChange={(value) =>
                    handleFilterChange({
                      key: "role",
                      value,
                    })
                  }
                  data={roles.map((role) => ({
                    value: role.value,
                    label: role.label,
                  }))}
                />
              </Col>
              <Col lg={12} xl={7} xxl={6}>
                <AppSelect
                  title="SMS Rule"
                  size="large"
                  className="users-filter-select"
                  placeholder="Select rule"
                  onChange={(value) =>
                    handleFilterChange({
                      key: "rule",
                      value,
                    })
                  }
                  data={rules.map((rule) => ({
                    value: rule.value,
                    label: rule.label,
                  }))}
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="end">
              <AppButton
                type="primary"
                size="large"
                width="max-content"
                backgroundColor="var(--color-success-dark)"
                className="mr-10"
                title={"+ New User"}
                onClick={() => setOpen(true)}
              />
            </Row>
          </Col>
        </Row>
      </div>

      <DataTable
        loader={localAdminLoading}
        dataSource={tableData}
        columns={defaultColumns}
      />

      <Form
        form={form}
        name="publish"
        layout="vertical"
        onFinish={isEdit ? handleEdit : handleSave}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <SmsUser
          open={open}
          rules={rules}
          users={users}
          isEdit={isEdit}
          onCancel={resetFormModal}
          onClick={() => form.submit()}
          title={isEdit ? "Update User" : "Add User"}
          btnTitle={isEdit ? "Update" : "Save"}
        />
      </Form>

      <Delete
        open={confirmModal}
        onClick={handleDelete}
        onCancel={resetDeleteModal}
      />
    </>
  );
};

export default SmsUsers;
