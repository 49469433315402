import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vehicles: [],
};

const VehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    setVehicles: (state, { payload }) => {
      state.vehicles = payload;
    },
  },
});

export const { setVehicles } = VehicleSlice.actions;
export default VehicleSlice.reducer;
