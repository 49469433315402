import { Select } from "antd";
import React from "react";
// const { Option } = Select;
import { CloseOutlined } from "@ant-design/icons";

const AppSelect = ({
  width,
  maxWidth,
  data,
  title,
  placeholder = "Please Select",
  className,
  ...props
}) => (
  <>
    {title && <p className="text-xs dark-80 font-500">{title}</p>}
    <Select
      {...props}
      className={className}
      showSearch={true}
      size="large"
      listItemHeight={1}
      dropdownStyle={{ minWidth: "max-content" }}
      placeholder={placeholder}
      style={{ width, maxWidth }}
      allowClear={true}
      filterOption={(input, option) =>
        (option?.label?.toString()?.toLowerCase() ?? "").includes(
          input?.toString()?.toLowerCase()
        )
      }
      clearIcon={<CloseOutlined />}
      options={data}
    />
  </>
  //   {data.map((item) => (
  //     <Option key={item.key} value={item.key}>
  //       {item.value}
  //     </Option>
  //   ))}
  // </Select>
);

export default AppSelect;
