import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { FILE_URL } from "../../services/api";
import { DocumentFile } from "../Card";
import styles from "./style.module.scss";
import { isEqual } from "lodash";
import { NotFound } from "../NotFound";

function Documents({ loader }) {
  const record = useSelector((s) => s.request.selected, isEqual);

  if (!record?.document?.length && !loader) {
    return (
      <NotFound
        title="Oh, Documents Not Found"
        desc="It seems like documents are not created yet"
      />
    );
  }

  return (
    <div className={styles.account}>
      <Row gutter={12}>
        {record?.documents?.map((item, ind) => (
          <Col lg={4} xl={4} xxl={3} className="mb-10">
            <DocumentFile
              key={ind}
              title={item.Link.split(".")[0]}
              desc={item.Description}
              src={FILE_URL + item.Link}
              style={{ cursor: "pointer" }}
              onClick={async () => {
                window.open(FILE_URL + item.Link, "_newtab");
              }}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Documents;
