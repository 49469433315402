import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import React, { memo } from "react";
import AppModal from ".";
import { numberValidator } from "../../utils";
import AppButton from "../Button";
import AppInput from "../Input";
import AppSelect from "../Select";

export const YardMove = memo(
  ({
    form,
    open,
    title,
    // btnTitle,
    carriers,
    moveTypes,
    filteredTrailers,
    selectedCarrier,
    headerIcon,
    onCancel,
    onClick,
    onPrePlanClick,
  }) => {
    return (
      <AppModal
        open={open}
        minWidth="20%"
        title={title}
        headerIcon={headerIcon}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="Carrier"
              rules={[
                {
                  required: true,
                  message: ``,
                },
              ]}
            >
              <AppSelect
                title="Carrier"
                size="large"
                data={carriers.map((carrier) => ({
                  value: carrier.ID,
                  label: carrier.Name,
                }))}
                onChange={() => {
                  form.setFieldsValue({
                    Trailer: null,
                  });
                }}
                disabled={!carriers.length}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="YardTractorMoveType"
              rules={[
                {
                  required: true,
                  message: ``,
                },
              ]}
            >
              <AppSelect
                title="Move Type"
                size="large"
                data={moveTypes.map((type) => ({
                  value: type.ID,
                  label: type.Service,
                }))}
                disabled={!moveTypes.length}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item rules={[numberValidator()]} name="AssignedOrderRef">
              <AppInput
                className="user-modal-input"
                title="Trip"
                placeholder=""
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="Trailer">
              <AppSelect
                title="Trailer"
                size="large"
                disabled={!selectedCarrier || !filteredTrailers.length}
                data={filteredTrailers.map((trailer) => ({
                  value: trailer.ID,
                  label: trailer.UnitNo,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="Door"
              validateFirst={true}
              rules={[
                {
                  required: true,
                  message: ``,
                },
                numberValidator(),
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="Door"
                placeholder=""
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="SetPointTemp"
              validateFirst={true}
              rules={[
                {
                  required: true,
                  message: ``,
                },
                numberValidator(),
              ]}
            >
              <AppInput
                className="user-modal-input"
                title="Temp"
                placeholder=""
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <AppButton
            icon={<MinusOutlined style={{ fontSize: 18 }} />}
            title="Discard"
            width="120px"
            height="max-content"
            fontSize="16px"
            className="btn_red"
            onClick={onCancel}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <AppButton
              icon={<PlusOutlined style={{ fontSize: 18 }} />}
              title="Request"
              width="120px"
              height="max-content"
              fontSize="16px"
              className="btn_green mr-10"
              onClick={onClick}
            />
            <AppButton
              icon={<PlusOutlined style={{ fontSize: 18 }} />}
              title="PrePlan"
              width="120px"
              height="max-content"
              fontSize="16px"
              className="btn_green"
              onClick={onPrePlanClick}
            />
          </div>
        </Row>
      </AppModal>
    );
  }
);
