import {
  MinusOutlined,
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Col, Form, Modal, Row, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteRequest,
  asyncPrePlanToRequest,
  asyncUpdateCoordRequest,
  asyncUpdateRequest,
} from "../../store/actions/request";
import {
  formatDate,
  fuelLevel,
  loadLock,
  numberValidator,
  statuses,
} from "../../utils";
import AppButton from "../Button";
import { AppTag } from "../Common";
import AppInput from "../Input";
import AppSelect from "../Select";
import styles from "./style.module.scss";

const AppDrawer = ({
  open,
  onClose,
  user,
  drivers,
  carriers,
  // customers,
  trailers,
  moveTypes,
  loader,
  setLoader,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { selected: record } = useSelector((s) => s.request);
  const [isEdit, setIsEdit] = useState(false);

  const selectedCarrier = Form.useWatch("Carrier", form);

  const onFinish = async (values) => {
    let res = {};
    if (+user.role === 14) {
      res = await dispatch(
        asyncUpdateRequest({ ID: record?.ID, ...values })
      ).unwrap();
    }

    if (+user.role === 13) {
      res = await dispatch(
        asyncUpdateCoordRequest({ ID: record?.ID, ...values })
      ).unwrap();
    }

    if (res.success) {
      onClose();
      if (isEdit) {
        setIsEdit(false);
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        Carrier: record?.Carrier,
        AssignedOrderRef: record?.AssignedOrderRef,
        YardTractorMoveType: record?.YardTractorMoveType,
        Trailer: record?.Trailer,
        Door: record?.Door,
        SetPointTemp: record?.SetPointTemp,
        LoadLock: record?.LoadLock,
        YardMoveFuelLevel: record?.YardMoveFuelLevel,
        BoxTemp: record?.BoxTemp,
        Description: record?.Description,
      });
    }
  }, [form, isEdit, record]);

  const onFinishFailed = (values) => {
    console.log(values);
  };

  const filteredTrailers = trailers?.filter(
    (trailer) => trailer.Carrier === (selectedCarrier ?? record?.Carrier)
  );

  const formatTableDuration = (dateTime) => {
    let res = moment.duration(dateTime);
    let duration = "";

    if (res.days()) {
      let D = res.days();
      duration += `${D}D `;
    }

    if (res.hours()) {
      let H = res.hours();
      duration += `${H}H `;
    }

    if (res.minutes()) {
      let M = res.minutes();
      duration += `${M}M `;
    }

    if (!duration) {
      duration = (res.seconds() || 0) + "S";
    }

    return duration;
  };

  const moveType =
    moveTypes.find((move) => +move.ID === record?.YardTractorMoveType) || {};

  let isPrePlan = record?.YardMoveStatus === statuses.PREPLAN;
  let isJobInProgress = record?.YardMoveStatus === statuses.INPROGRESS;
  // ||
  // record?.YardMoveStatus === "Completed";

  let tableData = [
    {
      id: 0,
      event: "Request",
      name: !isPrePlan
        ? record?.Coordinator?.name?.toUpperCase() || "N/A"
        : "N/A",
      dateTime: record?.YardMoveRequestDateTime
        ? formatDate(record?.YardMoveRequestDateTime)
        : "N/A",
      timeLapsed: "N/A",
    },
    {
      id: 1,
      event: "Assign",
      name: record?.driver?.name?.toUpperCase() || "N/A",
      dateTime: record?.YardMoveDispatchDateTime
        ? formatDate(record?.YardMoveDispatchDateTime)
        : "N/A",
      timeLapsed: record?.YardMoveDispatchDateTime
        ? formatTableDuration(
            moment(record?.YardMoveDispatchDateTime).diff(
              moment(record?.YardMoveRequestDateTime)
            )
          )
        : "N/A",
    },
    {
      id: 2,
      event: "Start",
      name: record?.YardMoveStartDateTime
        ? record?.driver?.name?.toUpperCase()
        : "N/A",
      dateTime: record?.YardMoveStartDateTime
        ? formatDate(record?.YardMoveStartDateTime)
        : "N/A",
      timeLapsed: record?.YardMoveStartDateTime
        ? formatTableDuration(
            moment(record?.YardMoveStartDateTime).diff(
              moment(record?.YardMoveDispatchDateTime)
            )
          )
        : "N/A",
    },
    {
      id: 3,
      event: "Finish",
      name: record?.YardMoveCompleteDateTime
        ? record?.driver?.name?.toUpperCase()
        : "N/A",
      dateTime: record?.YardMoveCompleteDateTime
        ? formatDate(record?.YardMoveCompleteDateTime)
        : "N/A",
      timeLapsed: record?.YardMoveCompleteDateTime
        ? formatTableDuration(
            moment(record?.YardMoveCompleteDateTime).diff(
              moment(record?.YardMoveStartDateTime)
            )
          )
        : "N/A",
    },
  ];

  return (
    <Modal
      closable={false}
      width="600px"
      centered
      footer={null}
      open={open}
      title={
        <Row align="middle" justify="space-between">
          <Space>
            <h1 className="text-xl font-600">
              Yard Move
              <span className="primary"> #{record?.ID}</span>
            </h1>
            <AppTag title={record?.YardMoveStatus} margin="0 0 0 1.125rem" />
          </Space>
          <Space>
            <Space>
              {(record?.YardMoveStatus === statuses.REQUESTED ||
                record?.YardMoveStatus === statuses.PREPLAN) &&
                (loader ? (
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#ef4444" }}
                    spin
                  />
                ) : (
                  <DeleteOutlined
                    style={{ fontSize: 18, color: "#ef4444" }}
                    onClick={async () => {
                      setLoader(true);
                      let res = await dispatch(
                        asyncDeleteRequest(record.ID)
                      ).unwrap();
                      if (res.success) {
                        onClose();
                        setIsEdit(false);
                      }
                      setLoader(false);
                    }}
                  />
                ))}
            </Space>
            {record?.YardMoveStatus === statuses.PREPLAN && (
              <Space>
                <AppButton
                  title="Move To Requsted"
                  type="primary"
                  fontSize="16px"
                  className="btn_green"
                  onClick={async () => {
                    let res = await dispatch(
                      asyncPrePlanToRequest(record.ID)
                    ).unwrap();
                    if (res.success) {
                      onClose();
                      setIsEdit(false);
                    }
                  }}
                />
              </Space>
            )}
          </Space>
        </Row>
      }
    >
      <Form
        form={form}
        name="publish"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className={styles.content}>
          <Space direction="vertical" className={styles.user}>
            <Row gutter={12}>
              {/* MoveType */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Move Type</p>
                {!isJobInProgress && isEdit ? (
                  <Form.Item
                    name="YardTractorMoveType"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `Move Type is required!`,
                      },
                    ]}
                  >
                    <AppSelect
                      overFlow="hidden"
                      maxWidth="100%"
                      data={(moveTypes || []).map((type) => ({
                        value: +type.ID,
                        label: type.Name,
                      }))}
                      onChange={(value) => {
                        form.setFieldsValue({
                          YardTractorMoveType: value,
                        });
                      }}
                    />
                  </Form.Item>
                ) : (
                  <p className="font-500">{moveType.Name || "N/A"}</p>
                )}
              </Col>

              {/* Carrier */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Carrier</p>
                {!isJobInProgress && isEdit ? (
                  <Form.Item
                    name="Carrier"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `Carrier is required!`,
                      },
                    ]}
                  >
                    <AppSelect
                      data={(carriers || []).map((carrier) => ({
                        value: carrier.ID,
                        label: carrier.Name,
                      }))}
                      onChange={(value) => {
                        form.setFieldsValue({
                          Carrier: value,
                        });

                        form.setFieldsValue({
                          Trailer: null,
                        });
                      }}
                    />
                  </Form.Item>
                ) : (
                  <p className="font-500">
                    {record?.carrier?.name?.toUpperCase() || "N/A"}
                  </p>
                )}
              </Col>

              {/* Fuel Level */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Fuel Level</p>
                {/* {!isJobInProgress && isEdit ? ( */}
                {!isJobInProgress && isEdit && +user.role === 14 ? (
                  <Form.Item name="YardMoveFuelLevel">
                    <AppSelect
                      overFlow="hidden"
                      maxWidth="100%"
                      data={(fuelLevel || []).map((level) => {
                        return {
                          value: level.key,
                          label: level.value,
                        };
                      })}
                      onChange={(value) => {
                        form.setFieldsValue({
                          YardMoveFuelLevel: value,
                        });
                      }}
                    />
                  </Form.Item>
                ) : (
                  <p className="font-500">
                    {fuelLevel.find(
                      (level) => level.key === record?.YardMoveFuelLevel
                    )?.value || "N/A"}
                  </p>
                )}
              </Col>

              {/* AssignedOrderRef(Trip) */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Trip</p>
                {!isJobInProgress && isEdit ? (
                  <Form.Item
                    name="AssignedOrderRef"
                    rules={[numberValidator("Not a valid number!")]}
                  >
                    <AppInput className="user-modal-input" size="large" />
                  </Form.Item>
                ) : (
                  <p className="font-500">
                    {record?.AssignedOrderRef || "#N/A"}
                  </p>
                )}
              </Col>

              {/* Trailer */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Trailer</p>
                {!isJobInProgress && isEdit ? (
                  <Form.Item name="Trailer">
                    <AppSelect
                      overFlow="hidden"
                      maxWidth="100%"
                      disabled={!filteredTrailers.length ? true : false}
                      data={(filteredTrailers || []).map((trailer) => {
                        return {
                          value: trailer.ID,
                          label: `#${trailer.UnitNo}`,
                        };
                      })}
                      onChange={(value) => {
                        form.setFieldsValue({
                          Trailer: value,
                        });
                      }}
                    />
                  </Form.Item>
                ) : (
                  <p className="font-500">
                    #{record?.trailer?.UnitNo || "N/A"}
                  </p>
                )}
              </Col>

              {/* Set Point Temp */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Set Temp</p>
                {!isJobInProgress && isEdit ? (
                  <Form.Item
                    name="SetPointTemp"
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `Point Temp is required!`,
                      },
                      numberValidator("Not a valid number!"),
                    ]}
                  >
                    <AppInput className="user-modal-input" size="large" />
                  </Form.Item>
                ) : (
                  <p className="font-500">{record?.SetPointTemp || "N/A"}</p>
                )}
              </Col>

              {/* Door */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Dock</p>
                {!isJobInProgress && isEdit ? (
                  <Form.Item
                    name="Door"
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `Dock Door is required!`,
                      },
                      numberValidator("Not a valid number!"),
                    ]}
                  >
                    <AppInput className="user-modal-input" size="large" />
                  </Form.Item>
                ) : (
                  <p className="font-500">{record?.Door || "#N/A"}</p>
                )}
              </Col>

              {/* Load Locks */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Load Locks</p>
                {!isJobInProgress && isEdit && +user.role === 14 ? (
                  <Form.Item name="LoadLock">
                    <AppSelect
                      overFlow="hidden"
                      maxWidth="100%"
                      data={(loadLock || []).map((loadlock) => {
                        return {
                          value: loadlock,
                          label: loadlock,
                        };
                      })}
                      onChange={(value) => {
                        form.setFieldsValue({
                          LoadLock: value,
                        });
                      }}
                    />
                  </Form.Item>
                ) : (
                  <p className="font-500">{record?.LoadLock || "N/A"}</p>
                )}
              </Col>

              {/* Box Temp */}
              <Col span={8} style={{ marginTop: 10 }}>
                <p className="text-sm font-600 info">Box Temp</p>
                {!isJobInProgress && isEdit && +user.role === 14 ? (
                  <Form.Item
                    name="BoxTemp"
                    rules={[numberValidator("Not a valid number!")]}
                  >
                    <AppInput className="user-modal-input" size="large" />
                  </Form.Item>
                ) : (
                  <p className="font-500">{record?.BoxTemp || "N/A"}</p>
                )}
              </Col>

              {/* Driver */}
              {!isPrePlan && (
                <Col span={8} style={{ marginTop: 10 }}>
                  <p className="text-sm font-600 info">Assign Driver</p>
                  {!isJobInProgress && isEdit && +user?.role === 14 ? (
                    <Form.Item name="Driver">
                      <AppSelect
                        data={(drivers || []).map((driver) => ({
                          value: driver.DriverID,
                          label: `${driver.DriverName} (#${driver.DriverID})`,
                        }))}
                        onChange={(value) => {
                          form.setFieldsValue({
                            Driver: value,
                          });
                        }}
                        margin="0 20px 0 0"
                      />

                      {record?.Driver && (
                        <p className="text-xs ml-5 mt-5 font-500">
                          {`${record?.driver?.name?.toUpperCase()} #${
                            record?.Driver
                          }`}
                        </p>
                      )}
                    </Form.Item>
                  ) : (
                    <p className="text-xs font-500">
                      {record?.Driver
                        ? `${record?.driver?.name?.toUpperCase()} #${
                            record?.Driver
                          }`
                        : "#N/A"}
                    </p>
                  )}
                </Col>
              )}
            </Row>

            {/* Description(note) */}
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <p className="text-sm font-600 info">Notes:</p>
                {!isJobInProgress && isEdit ? (
                  <Form.Item name="Description">
                    <TextArea
                      style={{ borderRadius: 8 }}
                      placeholder="Add description"
                      autoSize={{
                        minRows: 5,
                        maxRows: 8,
                      }}
                    />
                  </Form.Item>
                ) : (
                  <p className="font-500">{record?.Description || "N/A"}</p>
                )}
              </Col>
            </Row>
          </Space>

          <Space direction="vertical" className={styles.user}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>
                    <p className="text-sm font-600 info">Event</p>
                  </th>
                  <th>
                    <p className="text-sm font-600 info">Date & Time</p>
                  </th>
                  <th>
                    <p className="text-sm font-600 info">Duration</p>
                  </th>
                  <th>
                    <p className="text-sm font-600 info">Name</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <p className="text-sm font-500">{item.event}</p>
                    </td>
                    <td>
                      <p className="text-sm font-500">{item.dateTime}</p>
                    </td>
                    <td>
                      <p className="text-sm font-500">{item.timeLapsed}</p>
                    </td>
                    <td>
                      <p className="text-sm font-500">{item.name}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Space>

          <Row justify="space-between">
            <AppButton
              icon={<MinusOutlined style={{ fontSize: 18 }} />}
              title={isEdit ? "Cancel" : "Discard"}
              width="120px"
              height="max-content"
              fontSize="16px"
              className="btn_red"
              onClick={() => {
                onClose();
                setIsEdit(false);
              }}
            />

            {!isJobInProgress && (
              <AppButton
                icon={<PlusOutlined style={{ fontSize: 18 }} />}
                title={isEdit ? "Update" : "Edit"}
                type="primary"
                fontSize="16px"
                width="120px"
                className="btn_green"
                onClick={() => (isEdit ? form.submit() : setIsEdit(true))}
              />
            )}
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default AppDrawer;
