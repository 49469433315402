import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drivers: [],
  activeDrivers: [],
};

const DriverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    setDrivers: (state, { payload }) => {
      state.drivers = payload;
    },
    setActiveDrivers: (state, { payload }) => {
      state.activeDrivers = payload;
    },
  },
});

export const { setDrivers, setActiveDrivers } = DriverSlice.actions;
export default DriverSlice.reducer;
