import { Col, Form, Row, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, {
  memo,
  useEffect,
  useMemo,
  // useRef,
  useState,
} from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { HiMinusSm } from "react-icons/hi";
import {
  // RiCloseLine,
  RiDeleteBin6Line,
  // RiEdit2Line,
  // RiSaveLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../Components/Button";
import AppInput from "../../Components/Input";
import AppSelect from "../../Components/Select";
import { DataTable } from "../../Components/Table";
import {
  asyncCreateDocument,
  asyncDeleteDocument,
} from "../../store/actions/account";
import {
  asyncDeleteRequest,
  asyncPrePlanToRequest,
  asyncUpdateCoordRequest,
  asyncUpdateRequest,
} from "../../store/actions/request";
import {
  addAdditionalPropsToCell,
  formatDate,
  formatTableDuration,
  fuelLevel,
  loadLock,
  moment,
  numberValidator,
  statuses,
} from "../../utils";
import { FormLayout } from "../Card";
import { AppTag } from "../Common";
import { FileUploader } from "../Dragger";
import { FileViewer } from "../FileViewer";
import styles from "./style.module.scss";
import { Actions } from "../RowActions";
import { isEqual } from "lodash";
// import { OverLay } from "../NotFound";

function OrderDetails({ record, loader, localLoading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [documentForm] = Form.useForm();

  // const documentAddRef = useRef({ current: null });

  const user = useSelector((s) => s.user.user, isEqual);
  const moveTypes = useSelector((s) => s.request.moveTypes, isEqual);
  const carriers = useSelector((s) => s.customer.carriers, isEqual);
  const drivers = useSelector((s) => s.driver.drivers, isEqual);
  const activeDrivers = useSelector((s) => s.driver.activeDrivers, isEqual);
  const trailers = useSelector((s) => s.trailer.trailers, isEqual);
  const documentTypes = useSelector((s) => s.account.documentTypes, isEqual);

  const [isEdit, setIsEdit] = useState(false);
  const [viewerSource, setViewerSource] = useState({ ID: null, link: "" });

  // const [documentLoader, setDocumentLoader] = useState(false);
  // const [activityLoader, setActivityLoader] = useState(false);
  // const [deleteLoader, setDeleteLoader] = useState(false);

  const [showFileUploader, setShowFileUploader] = useState({
    show: false,
    file: {},
  });

  const selectedCarrier = Form.useWatch("Carrier", form);

  const [editingKey, setEditingKey] = useState("");

  let driverList = useMemo(() => {
    let { YardMoveStatus = null } = record;
    if (!YardMoveStatus) return [];

    return YardMoveStatus === statuses.COMPLETED ? drivers : activeDrivers;
  }, [record, activeDrivers, drivers]);

  useEffect(() => {
    if (record?.ID && !isEdit) {
      form.setFieldsValue({
        Carrier: record?.Carrier,
        CustomerName: record?.customer?.name,
        YardTractorMoveType: record?.YardTractorMoveType,
        AssignedOrderRef: record?.AssignedOrderRef,
        Trailer: record?.Trailer,
        Door: record?.Door,
        SetPointTemp: record?.SetPointTemp,
        LoadLock: record?.LoadLock,
        YardMoveFuelLevel: record?.YardMoveFuelLevel,
        BoxTemp: record?.BoxTemp,
        Description: record?.Description,
      });
    }
  }, [form, record, isEdit]);

  useEffect(() => {
    let orderDocuments = record?.documents?.reduce((acc, curr, ind) => {
      // 1 means orderDetail Tab
      if (curr.docCategory === 1) {
        return [
          ...acc,
          { ...curr, link: { uid: curr.Link + ind, name: curr.Link } },
        ];
      }

      return acc;
    }, []);

    documentForm.setFieldsValue({
      documents: orderDocuments?.length ? [{}, ...orderDocuments] : [{}],
      // documents: orderDocuments?.length ? [...orderDocuments, {}] : [{}],
    });
  }, [documentForm, record?.documents]);

  const handleFileSelection = async () => {
    let documentsData = [...documentForm.getFieldValue("documents")];

    let fileRecord = { ...documentsData?.shift(), link: showFileUploader.file };

    documentsData.push(fileRecord);

    documentForm.setFieldsValue({
      documents: documentsData,
    });

    setShowFileUploader({
      show: false,
      file: {},
    });
  };

  const handleDocumentSave = async (key) => {
    try {
      if (localLoading || loader) return;

      let { errorFields } = await documentForm.validateFields([
        ["documents", key, "link"],
        ["documents", key, "DocumentType"],
      ]);

      if (errorFields) return;

      const row = documentForm.getFieldValue(["documents", key]);

      let documentFormData = new FormData();

      documentFormData.append("link", row.link.originFileObj);
      documentFormData.append("DocumentType", row.DocumentType);
      documentFormData.append("docCategory", 1);

      // setDocumentLoader(true);
      if (row?.ID) {
        // await dispatch(asyncUpdateDocument(row)).unwrap();
      } else {
        await dispatch(
          asyncCreateDocument({
            yardMoveID: record?.ID,
            body: documentFormData,
          })
        ).unwrap();
      }

      setEditingKey("");
      // setDocumentLoader(false);
    } catch (error) {
      // setDocumentLoader(false);
      console.log(error);
    }
  };

  const handleDocumentResetting = (key, record) => {
    if (localLoading || loader) return;

    documentForm.setFields([
      { name: ["documents", key, "link"], value: record.link },
    ]);
  };

  const handleDocumentDelete = async (remove, record) => {
    if (localLoading || loader) return;

    // setDocumentLoader(true);
    let res = await dispatch(asyncDeleteDocument(record?.ID)).unwrap();
    if (res.success) {
      remove();
    }
    // setDocumentLoader(false);
  };

  const onFinish = async (values) => {
    if (localLoading || loader) return;

    values.Driver = values.Driver ?? record?.Driver;

    let res = {};
    if (+user.role === 14) {
      res = await dispatch(
        asyncUpdateRequest({ ID: record?.ID, ...values })
      ).unwrap();
    }

    if (+user.role === 13) {
      res = await dispatch(
        asyncUpdateCoordRequest({ ID: record?.ID, ...values })
      ).unwrap();
    }

    if (res.success) {
      setIsEdit(false);
      form.resetFields(["Driver"]);
    }
  };

  const onFinishFailed = (values) => {
    console.log(values);
  };

  const onDeleteRequest = async () => {
    if (localLoading || loader) return;

    // setDocumentLoader(true);
    // setActivityLoader(true);
    // setDeleteLoader(true);

    let res = await dispatch(asyncDeleteRequest(record.ID)).unwrap();
    if (res.success) {
      navigate("/yard-move");
      setIsEdit(false);
    }

    // setDeleteLoader(false);
    // setActivityLoader(false);
    // setDocumentLoader(false);
  };

  let filteredTrailers = useMemo(() => {
    return (
      trailers?.filter((trailer) => trailer.Carrier === selectedCarrier) || []
    );
  }, [selectedCarrier, trailers]);

  let isPrePlan = record?.YardMoveStatus === statuses.PREPLAN;
  let isJobInProgress = record?.YardMoveStatus === statuses.INPROGRESS;
  // ||
  // record?.YardMoveStatus === "Completed";

  let activityData = [
    {
      id: 0,
      event: "Request",
      name: !isPrePlan
        ? record?.Coordinator?.name?.toUpperCase() ??
          record?.Dispatcher?.name?.toUpperCase() ??
          "N/A"
        : "N/A",
      dateTime: record?.YardMoveRequestDateTime
        ? formatDate(record?.YardMoveRequestDateTime)
        : "N/A",
      timeLapsed: "N/A",
    },
    {
      id: 1,
      event: "Assign",
      name: record?.driver?.name?.toUpperCase() || "N/A",
      dateTime: record?.YardMoveDispatchDateTime
        ? formatDate(record?.YardMoveDispatchDateTime)
        : "N/A",
      timeLapsed: record?.YardMoveDispatchDateTime
        ? formatTableDuration(
            moment(record?.YardMoveDispatchDateTime).diff(
              moment(record?.YardMoveRequestDateTime)
            )
          )
        : "N/A",
    },
    {
      id: 2,
      event: "Start",
      name: record?.YardMoveStartDateTime
        ? record?.driver?.name?.toUpperCase()
        : "N/A",
      dateTime: record?.YardMoveStartDateTime
        ? formatDate(record?.YardMoveStartDateTime)
        : "N/A",
      timeLapsed: record?.YardMoveStartDateTime
        ? formatTableDuration(
            moment(record?.YardMoveStartDateTime).diff(
              moment(record?.YardMoveDispatchDateTime)
            )
          )
        : "N/A",
    },
    {
      id: 3,
      event: "Finish",
      name: record?.YardMoveCompleteDateTime
        ? record?.driver?.name?.toUpperCase()
        : "N/A",
      dateTime: record?.YardMoveCompleteDateTime
        ? formatDate(record?.YardMoveCompleteDateTime)
        : "N/A",
      timeLapsed: record?.YardMoveCompleteDateTime
        ? formatTableDuration(
            moment(record?.YardMoveCompleteDateTime).diff(
              moment(record?.YardMoveStartDateTime)
            )
          )
        : "N/A",
    },
  ];

  const defaultColumns = [
    {
      title: "Event",
      dataIndex: "event",
      width: "15%",
    },
    {
      title: "Date & Time",
      dataIndex: "dateTime",
      width: "18%",
    },
    {
      title: "Duration",
      dataIndex: "timeLapsed",
      width: "20%",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
    },
  ];

  const documentColumns = ({ remove }) => [
    {
      dataIndex: "ID",
      editable: true,
      isRequired: false,
      hidden: true,
      width: 0,
      // className: "documentID",
      render: () => null,
    },
    {
      title: "Attachment",
      dataIndex: "link",
      type: "file",
      isRequired: true,
      editable: true,
      width: "40%",
    },
    {
      title: "Type",
      type: "DropDown",
      keySet: ["ID", "DocumentType"],
      dropDownData: documentTypes,
      isRequired: true,
      editable: true,
      dataIndex: "DocumentType",
      width: "40%",
    },
    {
      dataIndex: "action",
      align: "center",
      width: "20%",
      render: (_, record, ind) => {
        let documentsLength = documentForm.getFieldValue("documents").length;

        return (
          <Actions
            ind={ind}
            record={record}
            remove={(ind) => handleDocumentDelete(() => remove(ind), record)}
            dataLength={documentsLength}
            handleSave={handleDocumentSave}
            handleRowResetting={handleDocumentResetting}
            editingKey={editingKey}
            setEditingKey={setEditingKey}
          />
          // <Space align="flex-end" style={{ justifyContent: "space-between" }}>
          //   {record?.ID && editingKey !== record?.ID ? (
          //     <RiEdit2Line
          //       style={{ cursor: "pointer", fontSize: 18 }}
          //       onClick={() => {
          //         setEditingKey(record?.ID);
          //       }}
          //     />
          //   ) : (
          //     <RiSaveLine
          //       style={{ cursor: "pointer", fontSize: 18 }}
          //       onClick={() => {
          //         handleDocumentSave(ind);
          //       }}
          //     />
          //   )}

          //   {editingKey !== record?.ID &&
          //     documentsLength > 2 &&
          //     ind !== documentsLength - 1 && (
          //       <RiDeleteBin6Line
          //         style={{ cursor: "pointer", fontSize: 18 }}
          //         onClick={() => {
          //           remove(ind);
          //         }}
          //       />
          //     )}

          //   {editingKey === record?.ID && (
          //     <RiCloseLine
          //       style={{ cursor: "pointer", fontSize: 18 }}
          //       onClick={() => {
          //         setEditingKey("");
          //       }}
          //     />
          //   )}
          // </Space>
        );
      },
    },
  ];

  return (
    <div
      className={styles.account}
      onDragOver={(e) => {
        e.preventDefault();
        setShowFileUploader((s) => ({ ...s, show: true }));
      }}
      onDragExit={(e) => {
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        setShowFileUploader((_) => ({ show: false, file: {} }));
      }}
    >
      <div>
        <Row justify="space-between" className="mb-25">
          <Space>
            <h3 className="text-2xl font-600">Order Details</h3>
            <AppTag className={styles.status} title={record?.YardMoveStatus} />
          </Space>
          <Space>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {isEdit && (
                <AppButton
                  icon={<HiMinusSm className="mr-5" />}
                  title="Discard"
                  className="btn_outlined mr-10"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                />
              )}
              {!isJobInProgress && (
                <AppButton
                  icon={<AiOutlinePlus color="#fff" className="mr-5" />}
                  title={isEdit ? "Save" : "Edit"}
                  className="btn_dark"
                  onClick={isEdit ? () => form.submit() : () => setIsEdit(true)}
                />
              )}
            </div>
            {record?.YardMoveStatus === statuses.PREPLAN && (
              <AppButton
                title="Move To Requested"
                type="primary"
                fontSize="16px"
                className="btn_green"
                onClick={async () => {
                  let res = await dispatch(
                    asyncPrePlanToRequest(record.ID)
                  ).unwrap();
                  if (res.success) {
                    setIsEdit(false);
                  }
                }}
              />
            )}
            {(record?.YardMoveStatus === statuses.REQUESTED ||
              record?.YardMoveStatus === statuses.PREPLAN) && (
              <AppButton
                // disabled={deleteLoader}
                icon={
                  <RiDeleteBin6Line size={20} color="#fff" className="mr-5" />
                }
                title="Delete Request"
                className="btn_error"
                onClick={onDeleteRequest}
                // onClick={!deleteLoader && onDeleteRequest}
              />
            )}
          </Space>
        </Row>
        <Form
          form={form}
          name="publish"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={8}>
            <Col span={12}>
              <FormLayout label="Coordinator / Dispatcher">
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name="Carrier"
                      rules={[
                        {
                          required: true,
                          message: `Carrier is required!`,
                        },
                      ]}
                    >
                      <AppSelect
                        title="Carrier"
                        disabled={
                          !carriers.length || isJobInProgress || !isEdit
                        }
                        data={(carriers || []).map((carrier) => ({
                          value: carrier.ID,
                          label: carrier.Name,
                        }))}
                        onChange={(value) => {
                          form.setFieldsValue({
                            Carrier: value,
                          });

                          form.setFieldsValue({
                            Trailer: null,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="CustomerName">
                      <AppInput
                        disabled={true}
                        title="Customer"
                        placeholder="Enter"
                        className="user-modal-input"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="YardTractorMoveType"
                      rules={[
                        {
                          required: true,
                          message: `Move Type is required!`,
                        },
                      ]}
                    >
                      <AppSelect
                        title="Move Type"
                        overflow="hidden"
                        maxWidth="100%"
                        disabled={
                          !moveTypes.length || isJobInProgress || !isEdit
                        }
                        data={(moveTypes || []).map((type) => ({
                          value: +type.ID,
                          label: type.Service,
                        }))}
                        onChange={(value) => {
                          form.setFieldsValue({
                            YardTractorMoveType: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="AssignedOrderRef"
                      rules={[numberValidator("Not a valid number!")]}
                    >
                      <AppInput
                        disabled={isJobInProgress || !isEdit}
                        title="Trip"
                        placeholder="Enter"
                        className="user-modal-input"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="Door"
                      validateFirst={true}
                      rules={[
                        {
                          required: true,
                          message: `Dock Door is required!`,
                        },
                        numberValidator("Not a valid number!"),
                      ]}
                    >
                      <AppInput
                        disabled={isJobInProgress || !isEdit}
                        title="Door"
                        placeholder="Enter"
                        className="user-modal-input"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="Trailer">
                      <AppSelect
                        title="Trailer"
                        overflow="hidden"
                        maxWidth="100%"
                        disabled={
                          !filteredTrailers.length || isJobInProgress || !isEdit
                        }
                        data={(filteredTrailers || []).map((trailer) => {
                          return {
                            value: trailer.ID,
                            label: `#${trailer.UnitNo}`,
                          };
                        })}
                        onChange={(value) => {
                          form.setFieldsValue({
                            Trailer: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </FormLayout>
            </Col>
            <Col span={12}>
              <FormLayout label="Driver">
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item name="Driver">
                      <>
                        <AppSelect
                          disabled={
                            !driverList ||
                            isPrePlan ||
                            isJobInProgress ||
                            !isEdit ||
                            +user?.role !== 14
                          }
                          title="Driver"
                          data={(driverList || []).map((driver) => ({
                            value: driver.DriverID,
                            label: `${driver.DriverName} (#${
                              driver.DriverID
                            }) - ${driver.On_Off_Duty ? "onDuty" : "offDuty"}`,
                          }))}
                          onChange={(value) => {
                            form.setFieldsValue({
                              Driver: value,
                            });
                          }}
                        />
                        {record?.Driver && (
                          <p className="text-xs ml-5 mt-5 font-500">
                            {`${record?.driver?.name?.toUpperCase()} #${
                              record?.Driver
                            }`}
                          </p>
                        )}
                      </>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="YardMoveFuelLevel">
                      <AppSelect
                        title="Fuel Level"
                        overflow="hidden"
                        maxWidth="100%"
                        disabled={
                          !fuelLevel.length ||
                          isJobInProgress ||
                          !isEdit ||
                          +user.role !== 14
                        }
                        data={(fuelLevel || []).map((level) => {
                          return {
                            value: level.key,
                            label: level.value,
                          };
                        })}
                        onChange={(value) => {
                          form.setFieldsValue({
                            YardMoveFuelLevel: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="SetPointTemp"
                      validateFirst={true}
                      rules={[
                        {
                          required: true,
                          message: `Point Temp is required!`,
                        },
                        numberValidator("Not a valid number!"),
                      ]}
                    >
                      <AppInput
                        disabled={isJobInProgress || !isEdit}
                        title="Set Temp"
                        placeholder="Enter"
                        className="user-modal-input"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="BoxTemp"
                      rules={[numberValidator("Not a valid number!")]}
                    >
                      <AppInput
                        disabled={
                          isJobInProgress || !isEdit || +user.role !== 14
                        }
                        title="Box Temp"
                        placeholder="Enter"
                        className="user-modal-input"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="LoadLock">
                      <AppSelect
                        title="Load Lock"
                        overflow="hidden"
                        maxWidth="100%"
                        disabled={
                          !loadLock.length ||
                          isJobInProgress ||
                          !isEdit ||
                          +user.role !== 14
                        }
                        data={(loadLock || []).map((loadlock) => {
                          return {
                            value: loadlock,
                            label: loadlock,
                          };
                        })}
                        onChange={(value) => {
                          form.setFieldsValue({
                            LoadLock: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </FormLayout>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <h3 className="text-2xl font-600 mb-5">Activity Log</h3>
              <DataTable
                columns={defaultColumns}
                dataSource={activityData}
                loader={localLoading}
                // loader={activityLoader}
                pagination={false}
              />
            </Col>
            <Col span={12}>
              <h3 className="text-2xl font-600 mb-5">Note</h3>
              {/* <p className="text-xs dark-80 font-500">Note</p> */}
              <Form.Item name="Description">
                <TextArea
                  disabled={isJobInProgress || !isEdit}
                  style={{ borderRadius: 8 }}
                  placeholder="Add description"
                  autoSize={{
                    minRows: 8,
                    maxRows: 8,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div>
          <Form
            form={documentForm}
            name="publish"
            layout="vertical"
            autoComplete="off"
          >
            <Form.List name="documents" initialValue={[{}]}>
              {(_, { remove }) => {
                // documentAddRef.current = add;
                return (
                  <>
                    <Row justify="space-between" className="mb-5 mt-15">
                      <h3 className="text-2xl font-600">Order Documents</h3>
                      {/* <AppButton
                        icon={<AiOutlinePlus color="#fff" className="mr-5" />}
                        title="Document"
                        onClick={async () => {
                          try {
                            await documentForm.validateFields();
                            add();
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                        className="btn_dark"
                      /> */}
                    </Row>
                    <DataTable
                      form={documentForm}
                      columns={addAdditionalPropsToCell(documentColumns, {
                        remove,
                        listName: "documents",
                        editingKey,
                      })}
                      dataSource={documentForm.getFieldValue("documents")}
                      onRow={({ ID, link } = {}) => ({
                        onDoubleClick: () => {
                          if (ID) return setViewerSource({ ID, link });
                        },
                      })}
                      loader={localLoading}
                      // loader={documentLoader}
                      pagination={true}
                      isEditable={true}
                    />
                  </>
                );
              }}
            </Form.List>
          </Form>
        </div>
      </div>
      {/* <AppButton
        icon={<HiMinusSm className="mr-5" />}
        title="Discard"
        className="btn_outlined mt-25"
        onClick={() => navigate("/yard-move")}
      /> */}

      {/* {localLoading && <OverLay />} */}

      {showFileUploader.show && (
        <FileUploader
          handleFileSelection={handleFileSelection}
          showFileUploader={showFileUploader}
          setShowFileUploader={setShowFileUploader}
        />
      )}

      <FileViewer
        src={viewerSource.link}
        open={!!viewerSource.link}
        onCancel={() => setViewerSource({ ID: null, link: "" })}
      />
    </div>
  );
}

export default memo(OrderDetails);
