import { useCallback, useEffect, useState } from "react";
// import ReactLoading from "react-loading";
import { Triangle } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
// import { Notification } from "./Components/Alerts";
import AppRouting from "./Components/Navigation";
// import { asyncAlertNotification } from "./store/actions/common";
import { setCarriers, setCustomers } from "./store/reducers/customer";
import { setActiveDrivers, setDrivers } from "./store/reducers/driver";
// import {
//   setNotifications,
//   setNotificationsCount,
// } from "./store/reducers/notification";
import { BASE_URL } from "./services/api";
import { setTimeSheet } from "./store/reducers/employee";
import { setDataSource, setSelected } from "./store/reducers/request";
import { setTrailers } from "./store/reducers/trailer";
import { setUsers } from "./store/reducers/user";
import { setUsers as setSmsUsers } from "./store/reducers/sms";
import { setVehicles } from "./store/reducers/vehicle";
import { statuses } from "./utils";
import { asyncLoginUserProfile } from "./store/actions/auth";
import { resetAuth } from "./store/reducers/auth";
import { isEqual } from "lodash";
import { Loader } from "./Components/Loader";

const socket = io(BASE_URL);

function App() {
  const dispatch = useDispatch();

  const token = useSelector((s) => s.auth.token, isEqual);
  const user = useSelector((s) => s.user.user, isEqual);
  const users = useSelector((s) => s.user.users, isEqual);
  const smsUsers = useSelector((s) => s.smsUser.users, isEqual);
  const dataSource = useSelector((s) => s.request.dataSource, isEqual);
  const selected = useSelector((s) => s.request.selected, isEqual);
  const customers = useSelector((s) => s.customer.customers, isEqual);
  const carriers = useSelector((s) => s.customer.carriers, isEqual);
  const trailers = useSelector((s) => s.trailer.trailers, isEqual);
  const drivers = useSelector((s) => s.driver.drivers, isEqual);
  const activeDrivers = useSelector((s) => s.driver.activeDrivers, isEqual);
  // const { notifications, notificationsCount } = useSelector(
  //   (s) => s.notification
  // );
  const timeSheet = useSelector((s) => s.employee.timeSheet, isEqual);
  const vehicles = useSelector((s) => s.vehicle.vehicles, isEqual);

  const [loader, setloader] = useState(true);

  useEffect(() => {
    if (token && !user?.id) {
      (async () => {
        let res = await dispatch(asyncLoginUserProfile()).unwrap();
        if (!res.success) {
          dispatch(resetAuth());
        }
        setloader(false);
      })();
    } else {
      setloader(false);
    }
  }, [dispatch, token, user?.id]);

  // const onTrack = useCallback(
  //   (record) => {
  //     dispatch(asyncAlertNotification(record));
  //   },
  //   [dispatch]
  // );

  let binaryASC = useCallback((data, val, low, high, key) => {
    let mid = Math.floor((low + high) / 2);
    let midData = data[mid]?.[key];
    let first = 0;
    let last = data.length - 1;

    if (val === midData) return mid;

    if (val <= data[first]?.[key]) return first;
    if (val >= data[last]?.[key]) return last + 1;

    if (val < data[mid + 1]?.[key] && val > midData) {
      return mid + 1;
    }

    if (low !== high) {
      if (val < midData) {
        return binaryASC(data, val, low, mid, key);
      }
      if (val > midData) {
        return binaryASC(data, val, mid, high, key);
      }
    }
  }, []);

  let binaryDesc = useCallback((data, val, low, high, key) => {
    let mid = Math.floor((low + high) / 2);
    let midData = data[mid]?.[key];
    let first = 0;
    let last = data.length - 1;

    if (val === midData) return mid;

    if (val >= data[first]?.[key]) return first;
    if (val <= data[last]?.[key]) return last;

    if (val < data[mid - 1]?.[key] && val > midData) {
      return mid;
    }

    if (low !== high) {
      if (val < midData) {
        return binaryDesc(data, val, mid, high, key);
      }

      if (val > midData) {
        return binaryDesc(data, val, low, mid, key);
      }
    }
  }, []);

  const placeRowIndexWise = useCallback(
    (dataSource, record, add = true, remove = false, key = "ID") => {
      // let dummyRow = [];
      let sort =
        (dataSource[0]?.[key] || 0) -
        (dataSource[dataSource.length - 1]?.[key] || 0);

      // if ("key" in (dataSource[0] ?? {})) {
      //   dummyRow = dataSource.splice(0, 1);
      // }

      let index = null;
      if (!!dataSource.length) {
        if (sort < 0) {
          index = binaryASC(
            dataSource,
            record?.[key],
            0,
            dataSource.length - 1,
            key
          );
        } else {
          index = binaryDesc(
            dataSource,
            record?.[key],
            0,
            dataSource.length - 1,
            key
          );
        }
      } else {
        index = 0;
      }

      if (index >= 0) {
        if (remove) {
          dataSource.splice(index, 1);
        }
        if (add) {
          dataSource.splice(index, 0, record);
        }
      }

      // if ("ID" in (dummyRow[0] ?? {})) {
      //   dataSource.splice(0, 0, dummyRow[0]);
      // }

      return index;
    },
    [binaryASC, binaryDesc]
  );

  const handleSocketCreate = useCallback(
    (record) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevPrePlan = [...dataSource["PrePlan"]];
      let prevRequested = [...dataSource["Requested"]];

      if (record?.YardMoveStatus === statuses.PREPLAN) {
        placeRowIndexWise(prevPrePlan, record);
      } else {
        placeRowIndexWise(prevOnGoing, record);
        placeRowIndexWise(prevRequested, record);
      }

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          PrePlan: prevPrePlan,
          Requested: prevRequested,
        })
      );
    },
    [dispatch, dataSource, placeRowIndexWise]
  );

  const handleSocketUpdate = useCallback(
    (record) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevPrePlan = [...dataSource["PrePlan"]];
      let prevRequested = [...dataSource["Requested"]];
      let prevDispatched = [...dataSource["Dispatched"]];
      let prevInProgress = [...dataSource["InProgress"]];
      let prevCompleted = [...dataSource["Completed"]];

      if (record.ID === selected?.ID) {
        dispatch(setSelected(record));
      }

      if (record?.YardMoveStatus !== statuses.PREPLAN) {
        placeRowIndexWise(prevOnGoing, record, true, true);
      }

      if (record.YardMoveStatus === statuses.PREPLAN) {
        placeRowIndexWise(prevPrePlan, record, true, true);
      }

      if (record.YardMoveStatus === statuses.REQUESTED) {
        placeRowIndexWise(prevRequested, record, true, true);
      }

      if (record.YardMoveStatus === statuses.DISPATCHED) {
        placeRowIndexWise(prevDispatched, record, true, true);
      }

      if (record.YardMoveStatus === statuses.INPROGRESS) {
        placeRowIndexWise(prevInProgress, record, true, true);
      }

      if (record.YardMoveStatus === statuses.COMPLETED) {
        placeRowIndexWise(prevCompleted, record, true, true);
      }

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          PrePlan: prevPrePlan,
          Requested: prevRequested,
          Dispatched: prevDispatched,
          InProgress: prevInProgress,
          Completed: prevCompleted,
        })
      );
    },
    [dispatch, dataSource, selected?.ID, placeRowIndexWise]
  );

  const handleSocketAssign = useCallback(
    (record) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevRequested = [...dataSource["Requested"]];
      let prevDispatched = [...dataSource["Dispatched"]];

      if (record.ID === selected?.ID) {
        dispatch(setSelected(record));
      }

      placeRowIndexWise(prevOnGoing, record, false, true);
      placeRowIndexWise(prevRequested, record, false, true);
      placeRowIndexWise(prevDispatched, record);

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          Requested: prevRequested,
          Dispatched: prevDispatched,
        })
      );
    },
    [dispatch, dataSource, selected?.ID, placeRowIndexWise]
  );

  const handleSocketYardMovePlanToRequest = useCallback(
    (record) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevPrePlan = [...dataSource["PrePlan"]];
      let prevRequested = [...dataSource["Requested"]];

      if (record.ID === selected?.ID) {
        dispatch(setSelected(record));
      }

      placeRowIndexWise(prevOnGoing, record);
      placeRowIndexWise(prevPrePlan, record, false, true);
      placeRowIndexWise(prevRequested, record);

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          PrePlan: prevPrePlan,
          Requested: prevRequested,
        })
      );
    },
    [dispatch, dataSource, selected?.ID, placeRowIndexWise]
  );

  const handleSocketDispatch = useCallback(
    (record) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevRequested = [...dataSource["Requested"]];
      let prevDispatched = [...dataSource["Dispatched"]];
      let prevInProgress = [...dataSource["InProgress"]];
      let prevCompleted = [...dataSource["Completed"]];

      if (record.ID === selected?.ID) {
        dispatch(setSelected(record));
      }

      placeRowIndexWise(prevOnGoing, record, true, true);
      placeRowIndexWise(prevRequested, record, false, true);
      placeRowIndexWise(prevDispatched, record);

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          Requested: prevRequested,
          Dispatched: prevDispatched,
          InProgress: prevInProgress,
          Completed: prevCompleted,
        })
      );
    },
    [dispatch, dataSource, selected?.ID, placeRowIndexWise]
  );

  const handleSocketStart = useCallback(
    (record) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevRequested = [...dataSource["Requested"]];
      let prevDispatched = [...dataSource["Dispatched"]];
      let prevInProgress = [...dataSource["InProgress"]];
      let prevCompleted = [...dataSource["Completed"]];

      if (record.ID === selected?.ID) {
        dispatch(setSelected(record));
      }

      placeRowIndexWise(prevOnGoing, record, true, true);
      placeRowIndexWise(prevDispatched, record, false, true);
      placeRowIndexWise(prevInProgress, record);

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          Requested: prevRequested,
          Dispatched: prevDispatched,
          InProgress: prevInProgress,
          Completed: prevCompleted,
        })
      );
    },
    [dispatch, dataSource, selected?.ID, placeRowIndexWise]
  );

  const handleSocketComplete = useCallback(
    (record) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevRequested = [...dataSource["Requested"]];
      let prevDispatched = [...dataSource["Dispatched"]];
      let prevInProgress = [...dataSource["InProgress"]];
      let prevCompleted = [...dataSource["Completed"]];

      if (record.ID === selected?.ID) {
        dispatch(setSelected(record));
      }

      placeRowIndexWise(prevOnGoing, record, true, true);
      placeRowIndexWise(prevInProgress, record, false, true);
      placeRowIndexWise(prevCompleted, record);

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          Requested: prevRequested,
          Dispatched: prevDispatched,
          InProgress: prevInProgress,
          Completed: prevCompleted,
        })
      );
    },
    [dispatch, dataSource, selected?.ID, placeRowIndexWise]
  );

  const handleSocketDelete = useCallback(
    (record) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevRequested = [...dataSource["Requested"]];
      let prevPrePlan = [...dataSource["PrePlan"]];

      if (record?.YardMoveStatus === statuses.REQUESTED) {
        placeRowIndexWise(prevRequested, record, false, true);
        placeRowIndexWise(prevOnGoing, record, false, true);
      } else {
        placeRowIndexWise(prevPrePlan, record, false, true);
      }

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          PrePlan: prevPrePlan,
          Requested: prevRequested,
        })
      );
    },
    [dispatch, dataSource, placeRowIndexWise]
  );

  const handleDriverDutyUpdate = useCallback(
    (record) => {
      let prevActiveDrivers = [...activeDrivers];
      let prevDrivers = [...drivers];

      if (+user?.role === 14) {
        let add = record.On_Off_Duty ? true : false;

        placeRowIndexWise(prevActiveDrivers, record, add, !add, "DriverID");
        placeRowIndexWise(prevDrivers, record, add, !add, "DriverID");

        dispatch(setActiveDrivers(prevActiveDrivers));
        dispatch(setDrivers(prevDrivers));
      }
    },
    [dispatch, user?.role, activeDrivers, drivers, placeRowIndexWise]
  );

  // const handleNotification = useCallback(
  //   (record) => {
  //     const prevNotifications = [...notifications];

  //     Notification({
  //       msg: "Notification",
  //       record,
  //       onTrack,
  //     });

  //     if (location.pathname !== PATHS.NOTIFICATION) {
  //       dispatch(setNotificationsCount(notificationsCount + 1));
  //     }

  //     prevNotifications.unshift(record);
  //     dispatch(setNotifications(prevNotifications));
  //   },
  //   [dispatch, onTrack, location.pathname, notifications, notificationsCount]
  // );

  const handleCreateCustomer = useCallback(
    (record) => {
      let prevCustomers = [...customers];
      prevCustomers.unshift(record);

      dispatch(setCustomers(prevCustomers));
    },
    [dispatch, customers]
  );

  const handleUpdateCustomer = useCallback(
    (record) => {
      if (record?.CustomerID === selected.BillTo) {
        let prevSelected = { ...selected };
        dispatch(
          setSelected({
            ...prevSelected,
            customer: {
              name: record.Customer_Name,
              Billing_Address: record?.Billing_Address,
              Billing_Email: record?.Billing_Email,
              Customer_Address: record?.Customer_Address,
            },
          })
        );
      }

      let prevCustomers = [...customers];
      placeRowIndexWise(prevCustomers, record, true, true, "CustomerID");
      dispatch(setCustomers(prevCustomers));
    },
    [dispatch, placeRowIndexWise, customers, selected]
  );

  const handleCreateTrailer = useCallback(
    (record) => {
      let prevTrailers = [...trailers];
      prevTrailers.unshift(record);

      dispatch(setTrailers(prevTrailers));
    },
    [dispatch, trailers]
  );

  const handleUpdateTrailer = useCallback(
    (record) => {
      let prevTrailers = [...trailers];

      placeRowIndexWise(prevTrailers, record, true, true);
      dispatch(setTrailers(prevTrailers));
    },
    [dispatch, placeRowIndexWise, trailers]
  );

  const handleCreateCarrier = useCallback(
    (record) => {
      let prevCarriers = [...carriers];
      prevCarriers.unshift(record);

      dispatch(setCarriers(prevCarriers));
    },
    [dispatch, carriers]
  );

  const handleUpdateCarrier = useCallback(
    (record) => {
      let prevCarriers = [...carriers];

      placeRowIndexWise(prevCarriers, record, true, true);
      dispatch(setCarriers(prevCarriers));
    },
    [dispatch, placeRowIndexWise, carriers]
  );

  const handleCreateUser = useCallback(
    (record) => {
      let prevUsers = [...users];
      prevUsers.unshift(record);

      dispatch(setUsers(prevUsers));
    },
    [dispatch, users]
  );

  const handleUpdateUser = useCallback(
    (record) => {
      let prevUsers = [...users];
      placeRowIndexWise(prevUsers, record, true, true, "id");

      dispatch(setUsers(prevUsers));
    },
    [dispatch, placeRowIndexWise, users]
  );

  const handleDeleteUser = useCallback(
    (record) => {
      let prevUsers = [...users];

      placeRowIndexWise(prevUsers, record, false, true, "id");
      dispatch(setUsers(prevUsers));
    },
    [dispatch, placeRowIndexWise, users]
  );

  const handleCreateSmsUser = useCallback(
    (record) => {
      let prevSmsUsers = [...smsUsers];
      prevSmsUsers.unshift(record);

      dispatch(setSmsUsers(prevSmsUsers));
    },
    [dispatch, smsUsers]
  );

  const handleUpdateSmsUser = useCallback(
    (record) => {
      let prevSmsUsers = [...smsUsers];
      placeRowIndexWise(prevSmsUsers, record, true, true, "id");
      dispatch(setSmsUsers(prevSmsUsers));
    },
    [dispatch, placeRowIndexWise, smsUsers]
  );

  const handleDeleteSmsUser = useCallback(
    (record) => {
      let prevSmsUsers = [...smsUsers];

      placeRowIndexWise(prevSmsUsers, record, false, true, "id");
      dispatch(setSmsUsers(prevSmsUsers));
    },
    [dispatch, placeRowIndexWise, smsUsers]
  );

  const handleTimeSheetEvent = useCallback(
    (record) => {
      let prevTimeSheet = [...timeSheet];
      let index = prevTimeSheet.findIndex(
        (prevTimeS) => prevTimeS.id === record.id
      );

      prevTimeSheet.splice(index, 1, record);
      dispatch(setTimeSheet(prevTimeSheet));
    },
    [dispatch, timeSheet]
  );

  const handleVehicleLocationUpdate = useCallback(
    (record) => {
      let prevVehicles = [...vehicles];
      let index = prevVehicles.findIndex(
        ({ vehicle }) => vehicle.id === record.vehicle_id
      );

      let { trigger, action, ...restRecord } = record;

      prevVehicles.splice(index, 1, {
        vehicle: {
          ...prevVehicles?.[index]?.vehicle,
          current_location: {
            ...prevVehicles?.[index]?.vehicle?.current_location,
            ...restRecord,
          },
        },
      });
      dispatch(setVehicles(prevVehicles));
    },
    [dispatch, vehicles]
  );

  const handleTrailerCustomerUpdate = useCallback(
    (records) => {
      let prevOnGoing = [...dataSource["onGoing"]];
      let prevPrePlan = [...dataSource["PrePlan"]];
      let prevRequested = [...dataSource["Requested"]];
      let prevDispatched = [...dataSource["Dispatched"]];
      let prevInProgress = [...dataSource["InProgress"]];
      let prevCompleted = [...dataSource["Completed"]];

      records.forEach((record) => {
        if (record.ID === selected?.ID) {
          dispatch(setSelected(record));
        }

        if (record?.YardMoveStatus !== statuses.PREPLAN) {
          placeRowIndexWise(prevOnGoing, record, true, true);
        }

        if (record.YardMoveStatus === statuses.PREPLAN) {
          placeRowIndexWise(prevPrePlan, record, true, true);
        }

        if (record.YardMoveStatus === statuses.REQUESTED) {
          placeRowIndexWise(prevRequested, record, true, true);
        }

        if (record.YardMoveStatus === statuses.DISPATCHED) {
          placeRowIndexWise(prevDispatched, record, true, true);
        }

        if (record.YardMoveStatus === statuses.INPROGRESS) {
          placeRowIndexWise(prevInProgress, record, true, true);
        }

        if (record.YardMoveStatus === statuses.COMPLETED) {
          placeRowIndexWise(prevCompleted, record, true, true);
        }
      });

      dispatch(
        setDataSource({
          ...dataSource,
          onGoing: prevOnGoing,
          PrePlan: prevPrePlan,
          Requested: prevRequested,
          Dispatched: prevDispatched,
          InProgress: prevInProgress,
          Completed: prevCompleted,
        })
      );
    },
    [dispatch, dataSource, selected?.ID, placeRowIndexWise]
  );

  useEffect(() => {
    if (user?.id) {
      socket.on(`customer/create`, handleCreateCustomer);
      socket.on(`customer/update`, handleUpdateCustomer);

      socket.on(`trailer/create`, handleCreateTrailer);
      socket.on(`trailer/update`, handleUpdateTrailer);

      socket.on(`carrier/create`, handleCreateCarrier);
      socket.on(`carrier/update`, handleUpdateCarrier);

      socket.on(`user/create`, handleCreateUser);
      socket.on(`user/update`, handleUpdateUser);
      socket.on(`user/delete`, handleDeleteUser);
      socket.on(`user/ban`, handleUpdateUser);

      socket.on(`userSms/create`, handleCreateSmsUser);
      socket.on(`userSms/update`, handleUpdateSmsUser);
      socket.on(`userSms/delete`, handleDeleteSmsUser);
      socket.on(`userSms/ban`, handleUpdateSmsUser);

      // if (user?.isNotified) {
      //   socket.on(`notification/Completed`, handleNotification);
      // }

      if (+user?.role === 15) {
        socket.on("timeSheetEvent", handleTimeSheetEvent);
      } else {
        socket.on("requestYardMove", handleSocketCreate);
        socket.on("requestYardMove/delete", handleSocketDelete);
        socket.on(`driverDuty/Dispatcher`, handleDriverDutyUpdate);
        socket.on(`vehicle/location`, handleVehicleLocationUpdate);
        socket.on(`trailer/customer/update`, handleTrailerCustomerUpdate);
      }

      if (+user?.role === 13) {
        socket.on(`yardMoveUpdate/Coordinator`, handleSocketUpdate);
        socket.on(`yardMoveDispatch/Coordinator`, handleSocketDispatch);
        socket.on(`yardMoveAssign/Coordinator`, handleSocketAssign);
        socket.on(`yardMoveStart/Coordinator`, handleSocketStart);
        socket.on(`yardMoveComplete/Coordinator`, handleSocketComplete);
        socket.on(
          `yardMovePlanToRequest/Coordinator`,
          handleSocketYardMovePlanToRequest
        );
      }

      if (+user?.role === 14) {
        socket.on("yardMoveUpdate/Dispatcher", handleSocketUpdate);
        socket.on(`yardMoveAssign/Dispatcher`, handleSocketAssign);
        socket.on(`yardMoveDispatch/Dispatcher`, handleSocketDispatch);
        socket.on(`yardMoveStart/Dispatcher`, handleSocketStart);
        socket.on(`yardMoveComplete/Dispatcher`, handleSocketComplete);
        socket.on(
          `yardMovePlanToRequest/Dispatcher`,
          handleSocketYardMovePlanToRequest
        );
      }
    }

    return () => {
      // socket.off(`notification/Completed`, handleNotification);

      socket.off(`trailer/create`, handleCreateTrailer);
      socket.off(`trailer/update`, handleUpdateTrailer);

      socket.off(`carrier/create`, handleCreateCarrier);
      socket.off(`carrier/update`, handleUpdateCarrier);

      socket.off(`user/create`, handleCreateUser);
      socket.off(`user/update`, handleUpdateUser);
      socket.off(`user/ban`, handleUpdateUser);
      socket.off(`user/delete`, handleDeleteUser);

      socket.off(`userSms/create`, handleCreateSmsUser);
      socket.off(`userSms/update`, handleUpdateSmsUser);
      socket.off(`userSms/delete`, handleDeleteSmsUser);
      socket.off(`userSms/ban`, handleUpdateSmsUser);

      if (+user?.role === 15) {
        socket.off("timeSheetEvent", handleTimeSheetEvent);
      } else {
        socket.off("requestYardMove", handleSocketCreate);
        socket.off("requestYardMove/delete", handleSocketDelete);
        socket.off(`driverDuty/Dispatcher`, handleDriverDutyUpdate);
        socket.off(`vehicle/location`, handleVehicleLocationUpdate);
        socket.off(`trailer/customer/update`, handleTrailerCustomerUpdate);
      }

      if (+user?.role === 13) {
        socket.off(`yardMoveUpdate/Coordinator`, handleSocketUpdate);
        socket.off(`yardMoveAssign/Coordinator`, handleSocketAssign);
        socket.off(`yardMoveDispatch/Coordinator`, handleSocketDispatch);
        socket.off(`yardMoveStart/Coordinator`, handleSocketStart);
        socket.off(`yardMoveComplete/Coordinator`, handleSocketComplete);
        socket.off(
          `yardMovePlanToRequest/Coordinator`,
          handleSocketYardMovePlanToRequest
        );
      }

      if (+user?.role === 14) {
        socket.off("yardMoveUpdate/Dispatcher", handleSocketUpdate);
        socket.off(`yardMoveAssign/Dispatcher`, handleSocketAssign);
        socket.off(`yardMoveDispatch/Dispatcher`, handleSocketDispatch);
        socket.off(`yardMoveStart/Dispatcher`, handleSocketStart);
        socket.off(`yardMoveComplete/Dispatcher`, handleSocketComplete);
        socket.off(
          `yardMovePlanToRequest/Dispatcher`,
          handleSocketYardMovePlanToRequest
        );
      }
    };
  }, [
    handleSocketCreate,
    handleSocketDelete,
    handleSocketUpdate,
    handleSocketAssign,
    handleSocketDispatch,
    handleSocketStart,
    handleSocketComplete,
    handleDriverDutyUpdate,
    handleCreateCarrier,
    handleUpdateCarrier,
    handleCreateCustomer,
    handleUpdateCustomer,
    handleCreateTrailer,
    handleUpdateTrailer,
    handleCreateUser,
    handleUpdateUser,
    handleDeleteUser,
    handleCreateSmsUser,
    handleUpdateSmsUser,
    handleDeleteSmsUser,
    // handleNotification,
    handleTimeSheetEvent,
    handleVehicleLocationUpdate,
    handleSocketYardMovePlanToRequest,
    handleTrailerCustomerUpdate,
    user?.id,
    user?.role,
    user?.isNotified,
  ]);

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        // <div
        //   style={{
        //     position: "fixed",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //   }}
        // >
        //   <Triangle
        //     height="120"
        //     width="120"
        //     color="#005c42"
        //     ariaLabel="triangle-loading"
        //     wrapperStyle={{}}
        //     wrapperClassName=""
        //     visible={true}
        //   />
        // </div>
        <AppRouting />
      )}
    </div>
  );
}

export default App;
