import React from "react";
import styles from "./styles.module.scss";

export const NotFound = ({ title, desc }) => {
  return (
    <div className={styles.page_404}>
      <div className={styles.page_404_section}>
        <h1 className={styles.heading}>:(</h1>
        <p className={styles.pg1}>{title}</p>
        <p className={styles.pg2}>{desc}</p>
      </div>
    </div>
  );
};

export const OverLay = () => {
  return (
    <div className={styles.page_overlay} style={{}}>
      <p className={styles.pg1}>Please Wait</p>
      <p className={styles.pg2}>Data is being fetched</p>
    </div>
  );
};
