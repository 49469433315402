import { Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export const FilterSelect = ({
  img,
  icon,
  title = "",
  options,
  tableName = "",
  dataIndex = "",
  onChange = () => {},
  placeholder = "Search",
  value = "",
}) => {
  // let key = title.replace(" ", "").toLowerCase();
  return (
    <div className="filter-select">
      {/* {img && <img src={img} alt="" />} */}
      {/* {icon && <>{icon}</>} */}
      {title && <p className="text-xs dark-80 font-500">{title}</p>}
      <Select
        size="large"
        listItemHeight={1}
        showSearch={true}
        dropdownStyle={{ minWidth: "max-content" }}
        defaultValue={value || null}
        key={tableName + "_" + dataIndex} // + "_" + value}
        onChange={(value,...rest) => {
          onChange({
            tableName,
            key: dataIndex,
            value: value || "",
          });
        }}
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
        }
        placeholder={placeholder}
        showArrow={true}
        clearIcon={<CloseOutlined />}
        allowClear={true}
        options={options}
      />
    </div>
  );
};
